import styled from 'styled-components'
import CountUp from 'react-countup'
import { useProjectContext } from '../../hooks/useProject'

import useFormatText from '../../hooks/useFormatText'
import useBreakpoint from '../../hooks/useBreakpoint'

const ProgressBar = () => {
  const { project } = useProjectContext()
  const percent = project?.workProgress || 0

  const { isBreakpoint } = useBreakpoint()

  return (
    <>
      {!isBreakpoint('medium') && (
        <Wrapper>
          <Title>{useFormatText('PROGRESS')}</Title>
          <Progress>
            <div style={{ width: `${percent}%` }} />
          </Progress>
          <ProgressCount>
            <CountUp end={percent} duration={1} />
            {useFormatText('PROGRESS_UNIT')}
          </ProgressCount>
        </Wrapper>
      )}
      {isBreakpoint('medium') && (
        <GrayChip>
          <p>{useFormatText('PROGRESS')}</p>
          <strong>{percent}%</strong>
        </GrayChip>
      )}
    </>
  )
}

export default ProgressBar

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

const Title = styled.span`
  color: ${({ theme }) => theme.colors.gray[600]};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 16.8px */
`

const ProgressCount = styled(Title)`
  color: ${({ theme }) => theme.colors.gray[800]};
`

const Progress = styled.div`
  position: relative;
  width: 188px;
  height: 6px;
  background-color: ${({ theme }) => theme.colors.gray[100]};
  border-radius: 6px;

  & > div {
    width: 0;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(109deg, #ff5a5a 27.02%, #cd1616 98.28%);
    border-radius: 6px;
    transition: width 0.2s ease-in-out;
  }
`

const GrayChip = styled.div`
  display: flex;
  padding: 9px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 24px;
  background-color: ${({ theme }) => theme.colors.gray[10]};
  color: ${({ theme }) => theme.colors.gray[600]};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 16.8px */

  p {
    white-space: nowrap;
  }
  strong {
    color: ${({ theme }) => theme.colors.gray[800]};
  }
`

import styled, { css } from 'styled-components'
import { SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { ModalProps } from '../../components/Modal'

import { IconSceneVideo } from '../../assets/icons'
import CropVideo from '../CropFile/CropVideo'
import useBreakpoint from '../../hooks/useBreakpoint'
import FileLoading from '../CropFile/FileLoading'

import { Wrapper, videoModalWrapperStyle } from './_stylesModal'
import { message } from 'antd'
import { convertURLtoFile } from './constants'
import { useIntl } from 'react-intl'
import VideoModalTabs from './VideoModalTabs'
import useLoading from '../../hooks/useLoading'
import useFormatText from '../../hooks/useFormatText'
import DesktopFilePreview from './DesktopFilePreview'
import EditModalLayout from './EditModalLayout'

//TYPE //////////////////////////////////////////////

export type CropData = {
  x: number
  y: number
  width: number
  height: number
  startTime: number
}

type VideoModalProps = ModalProps & {
  value?: string
  onClose: () => void
  onComplete?(file: File | undefined, cropData: CropData): void
  duration?: number
  minDuration?: number
  videoSize: VideoSizeType
}

interface VideoSizeType {
  width: number
  height: number
}

//TYPE FIN //////////////////////////////////////////////

const VideoModal = (props: VideoModalProps) => {
  const {
    minDuration,
    onClose,
    visible,
    onComplete,
    duration = 30,
    videoSize
  } = props

  const intl = useIntl()

  const [currentStep, setCurrentStep] = useState(0)

  const [selectedFile, setSelectedFile] = useState<File | null>(null)

  const [selectedImageUrl, setSelectedImageUrl] = useState('')
  const [isPreviewError, setIsPreviewError] = useState(false)
  const [cropData, setCropData] = useState<CropData | null>(null)
  const [selectedAssetInfo, setSelectedAssetInfo] = useState<any>(null)
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)

  const closable = currentStep !== 2

  const { isBreakpoint } = useBreakpoint()

  const {
    isLoading: previewLoading,
    renderLoading: previewLoad,
    startLoading: previewLoadStart,
    endLoading: previewLoadEnd
  } = useLoading()

  const handleLoadedData = useCallback(
    (event: SyntheticEvent<HTMLVideoElement, Event>) => {
      const videoElement = event.target as HTMLVideoElement
      previewLoadEnd()

      if (!minDuration) return
      checkFileDuration(videoElement.duration)
    },
    [minDuration]
  )

  const handlePreviewError = useCallback(() => {
    setIsPreviewError(true)
    previewLoadEnd()
  }, [])

  const onClickItem = async (url: string) => {
    if (!url || url === selectedImageUrl) return
    setIsPreviewError(false)
    previewLoadStart()
    setSelectedImageUrl(url)
    setSelectedFile(null)

    if (isBreakpoint('medium')) {
      setCurrentStep(1)
    }

    const file = await convertURLtoFile({
      url,
      type: 'video'
    })

    setSelectedFile(file)
  }

  // 유저가 입력한 소스의 길이가 해당 씬에서 요구하는 길이보다 짧은지 체크하기 위함
  const checkFileDuration = async (fileDuration: number) => {
    if (fileDuration && minDuration && fileDuration <= minDuration) {
      setSelectedImageUrl('')
      setSelectedFile(null)
      message.error(
        `${intl.formatMessage({
          id: 'VIDEO_FILE_DURATION_ERROR1'
        })} ${minDuration}${intl.formatMessage({
          id: 'VIDEO_FILE_DURATION_ERROR2'
        })}`
      )
    }
    previewLoadEnd()
  }

  const handleClickPrev = () => {
    setCurrentStep((prev) => Math.max(0, prev - 1))
  }

  const handleCropComplete = () => {
    if (!cropData || !selectedFile) return
    setCurrentStep(2)
    onComplete && onComplete(selectedFile, cropData)
  }

  useEffect(() => {
    handleCropComplete()
  }, [selectedFile, cropData])

  useEffect(() => {
    let timerId: NodeJS.Timeout
    if (!visible) {
      timerId = setTimeout(() => {
        setCurrentStep(0)
        setSelectedImageUrl('')
        setSelectedFile(null)
        setCropData(null)
      }, 500)
    }
    return () => {
      if (timerId) {
        clearInterval(timerId)
      }
    }
  }, [visible])

  return (
    <EditModalLayout
      visible={visible}
      closable={closable}
      title={useFormatText('VIDEO_INPUT_TITLE')}
      onClose={onClose}
      wrapperStyle={videoModalWrapperStyle}
    >
      <div
        style={{
          width: '100%',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1
        }}
      >
        {currentStep === 0 && !isBreakpoint('medium') && (
          <Wrapper>
            <DesktopFilePreview
              type='video'
              selectedUrl={selectedImageUrl}
              previewLoad={previewLoad}
              previewLoadEnd={previewLoadEnd}
              selectedAssetInfo={selectedAssetInfo}
              setCurrentStep={setCurrentStep}
              onClose={onClose}
              previewLoading={previewLoading}
              emptyIcon={<PreviewEmptyIcon />}
              emptyText={useFormatText('IMAGE_INPUT_PREVIEW_DEFAULT')}
              handleLoadedData={handleLoadedData}
              handlePreviewError={handlePreviewError}
              isPreviewError={isPreviewError}
            />
            <VideoModalTabs
              minDuration={minDuration}
              visible={visible}
              fileLoading={previewLoading}
              onClickItem={onClickItem}
              selectedImageUrl={selectedImageUrl}
              setSelectedAssetInfo={setSelectedAssetInfo}
              selectedTabIndex={selectedTabIndex}
              setSelectedTabIndex={setSelectedTabIndex}
            />
          </Wrapper>
        )}
        {currentStep === 0 && isBreakpoint('medium') && (
          <div style={{ width: '100%', height: '100%', padding: '0 16px' }}>
            <VideoModalTabs
              minDuration={minDuration}
              visible={visible}
              fileLoading={previewLoading}
              onClickItem={onClickItem}
              selectedImageUrl={selectedImageUrl}
              setSelectedAssetInfo={setSelectedAssetInfo}
              selectedTabIndex={selectedTabIndex}
              setSelectedTabIndex={setSelectedTabIndex}
            />
          </div>
        )}
        {currentStep === 1 && (
          <CropVideo
            video={selectedFile ? URL.createObjectURL(selectedFile) : ''}
            videoSize={videoSize}
            duration={duration}
            onComplete={(data) => setCropData(data)}
            onClose={onClose}
            handleClickPrev={handleClickPrev}
          />
        )}
        {currentStep === 2 && <FileLoading />}
      </div>
    </EditModalLayout>
  )
}

export default VideoModal

// STYLE ////////////////////////////////////////

export const ImageContainer = styled.div`
  position: relative;
  width: 100%; // NOTE: mobile issue
  flex-grow: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;
  padding: 0 8px;

  .empty-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .empty-icon {
      width: 48px;
      height: 48px;
      flex-shrink: 0;

      path,
      ellipse {
        stroke: ${({ theme }) => theme.colors.gray[300]};
      }
    }

    .empty-text {
      color: ${({ theme }) => theme.colors.gray[500]};
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */
      margin-top: 12px;
      user-select: none;
    }
  }
`

export const ImageWrapper = styled.div<{
  innerHeight?: number
}>`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 376px;
  max-height: 260px;
  margin-top: 16px;

  .selected-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center center;

    ${({ theme, innerHeight }) =>
      theme.breakpoints.medium(css`
        max-height: ${innerHeight && innerHeight > 667 ? '25vh' : '20vh'};
      `)}

    ${({ theme, innerHeight }) =>
      theme.breakpoints.small(css`
        min-height: ${innerHeight && innerHeight > 667 ? '25vh' : '20vh'};
      `)}
  }

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      height: auto;
      justify-content: center;
      padding: 0;
    `)}
`

export const PhotoLottieWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
`

const PreviewEmptyIcon = styled(IconSceneVideo)`
  width: 48px;
  height: 48px;
  flex-shrink: 0;

  path {
    stroke: ${({ theme }) => theme.colors.gray[300]};

    &#Vector_5 {
      stroke: none;
      fill: ${({ theme }) => theme.colors.gray[300]};
    }
  }
`

import { message } from 'antd'
import DefaultModal from '../../components/DefaultModal'
import { iconModalComplete } from '../../assets/icons'
import { useProjectContext } from '../../hooks/useProject'
import useFormatText from '../../hooks/useFormatText'
import { useRenderProjectMutation } from './_queries'
import ReactGA from 'react-ga4'
import useGetIsTrackingEventVerified from '../../hooks/useGetIsTrackingEventVerified'
import { redirectVplateLoginPage } from '../../utils/vplate'
import { RedirectProjectParams } from '../Main'

interface RenderModalProps {
  projectId: string
  projectTemplateTitle: string
  onClose: () => void
  redirectProject: (_: RedirectProjectParams) => void
}

const RenderModal = ({
  onClose,
  projectId,
  projectTemplateTitle,
  redirectProject
}: RenderModalProps) => {
  const { isLimit } = useProjectContext()

  const { renderMutate } = useRenderProjectMutation(redirectProject, onClose)
  const isTrackingEventVerified = useGetIsTrackingEventVerified()

  const renderModalTitle = useFormatText('RENDER_MESSAGE1')
  const renderModalContent = useFormatText('RENDER_MESSAGE2')
  const renderModalCencleBtnText = useFormatText('CONFIRM_CANCEL')
  const renderModalConfirmBtnText = useFormatText('CONFIRM_DONE')
  const renderModalErrorMsg = useFormatText('RENDER_COUNT_EXCEEDED')
  const changeStatusErrorMsg = useFormatText('CHANGE_STATUE_ERROR_MESSAGE')

  const handleConfirmButtonClick = async () => {
    if (isLimit) {
      message.error(renderModalErrorMsg)
      onClose()
      return
    }
    try {
      const vplateVideoEditorAuth = JSON.parse(
        window.localStorage.getItem('vplateVideoEditorAuth') || '{}'
      )
      if (!vplateVideoEditorAuth?.token) {
        redirectVplateLoginPage()
      }

      if (isTrackingEventVerified && projectTemplateTitle) {
        ReactGA.event({
          category: 'Start',
          action: 'ChooseTemplate',
          label: projectTemplateTitle
        })
      }

      renderMutate(projectId)
    } catch (error) {
      console.log('error', error)
      onClose()
      message.error(changeStatusErrorMsg)
    }
  }

  return (
    <DefaultModal
      icon={iconModalComplete}
      title={renderModalTitle}
      content={renderModalContent}
      cancleBtnText={renderModalCencleBtnText}
      confirmBtnText={renderModalConfirmBtnText}
      onClose={onClose}
      onConfirm={handleConfirmButtonClick}
    />
  )
}

export default RenderModal

import theme from '../../styles/theme'
import Typography from '../Typography'
import styled, {
  DefaultTheme,
  FlattenInterpolation,
  FlattenSimpleInterpolation,
  ThemeProps,
  css
} from 'styled-components'

interface Position {
  top?: number | string
  bottom?: number | string
  left?: number | string
  right?: number | string
}

type Direction = 'top' | 'bottom' | 'left' | 'right'

interface Tail {
  direction: Direction
  position: Position
}

export interface DefaultTooltipProps {
  contents: string
  position: Position
  tail: Tail
  children: JSX.Element
  width?: number
  touch?: boolean
  contentStyle?: React.CSSProperties
  wrapperStyle?: React.CSSProperties
  tailStyle?:
    | FlattenSimpleInterpolation
    | FlattenInterpolation<ThemeProps<DefaultTheme>>
  textStyle?: React.CSSProperties
  always?: boolean
}

const DefaultTooltip = ({
  contents,
  children,
  position,
  tail,
  width,
  touch = false,
  contentStyle,
  wrapperStyle,
  tailStyle,
  textStyle,
  always
}: DefaultTooltipProps) => {
  return (
    <Wrapper style={{ ...(wrapperStyle ?? {}) }} always={always}>
      {children}
      <Contents
        position={position}
        tail={tail}
        width={width}
        touch={touch}
        style={contentStyle}
        tailStyle={tailStyle}
      >
        <Typography
          type='caption1'
          style={{
            display: 'block',
            whiteSpace: width ? 'pre-wrap' : 'nowrap',
            ...textStyle
          }}
        >
          {contents}
        </Typography>
      </Contents>
    </Wrapper>
  )
}

export default DefaultTooltip

const topTailCss = css`
  border-top: none;
  border-bottom: 4px solid ${theme.colors.gray[800]};
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
`

const bottomTailCss = css`
  border-top: 4px solid ${theme.colors.gray[800]};
  border-bottom: none;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
`

const leftTailCss = css`
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: none;
  border-right: 6px solid ${theme.colors.gray[800]};
`

const rightTailCss = css`
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 6px solid ${theme.colors.gray[800]};
  border-right: none;
`

const getTailCss = (direction: Direction): FlattenSimpleInterpolation => {
  switch (direction) {
    case 'top':
      return topTailCss
    case 'bottom':
      return bottomTailCss
    case 'left':
      return leftTailCss
    case 'right':
      return rightTailCss
  }
}

type PositionValue = number | string | undefined

const getPositionCss = (
  position: Direction,
  value: PositionValue
): string | undefined => {
  if (typeof value === 'string') {
    return `${position}: ${value};`
  }
  return `${position}: ${value}px;`
}

const Contents = styled.div<{
  position: Position
  tail: Tail
  width: number | undefined
  touch: boolean
  always?: boolean
  tailStyle?:
    | FlattenSimpleInterpolation
    | FlattenInterpolation<ThemeProps<DefaultTheme>>
}>`
  position: absolute;
  ${({ position }) => getPositionCss('top', position?.top)};
  ${({ position }) => getPositionCss('bottom', position?.bottom)};
  ${({ position }) => getPositionCss('left', position?.left)};
  ${({ position }) => getPositionCss('right', position?.right)};
  z-index: 9999;
  pointer-events: none;
  padding: 6px 8px;
  border-radius: 2px;
  ${({ width }) => `width: ${width}px`};
  background-color: ${({ theme }) => theme.colors.gray[800]};
  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;
  font-weight: 500;
  line-height: 120%;

  &:before {
    content: ' ';
    position: absolute;
    ${({ tail }) => getPositionCss('top', tail.position?.top)};
    ${({ tail }) => getPositionCss('bottom', tail.position?.bottom)};
    ${({ tail }) => getPositionCss('left', tail.position?.left)};
    ${({ tail }) => getPositionCss('right', tail.position?.right)};
    ${({ tail }) => getTailCss(tail.direction)}

    ${({ tailStyle }) => tailStyle};
  }
`

const Wrapper = styled.div<{ always?: boolean }>`
  position: relative;
  width: fit-content;
  height: fit-content;
  background-color: ${theme.colors.white};
  & > ${Contents} {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  &:hover > ${Contents} {
    opacity: 1;
  }

  ${({ always }) =>
    always &&
    css`
      & > ${Contents} {
        opacity: 1;
      }
    `}
`

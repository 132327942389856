import { useIntl } from 'react-intl'

import { AbsoluteWrapper } from './styles'
import styled, { css } from 'styled-components'
import { Row } from 'antd'
import DefaultButton from '../DefaultButton'

interface EditMusicLayoutProps {
  title?: string
  description?: string
  onClose: () => void
  onOk: () => void
  onCloseText: string
  onOkText: string
  children: React.ReactNode
}

const EditMusicLayout = ({
  title,
  description,
  onClose,
  onOk,
  onCloseText,
  onOkText,
  children
}: EditMusicLayoutProps) => {
  const intl = useIntl()

  return (
    <PreviewWrapper>
      <Col gap={16}>
        <Col gap={12}>
          <Title>{title}</Title>
          <Row>
            <Description>{description}</Description>
          </Row>
        </Col>
        <div style={{ width: '100%' }}>{children}</div>
      </Col>
      <Row style={{ justifyContent: 'flex-end', gap: 12 }}>
        <StyledButton category='secondaryMonochrome' onClick={onClose}>
          {intl.formatMessage({ id: onCloseText })}
        </StyledButton>
        <StyledButton category='secondaryMulticolored' onClick={onOk}>
          {intl.formatMessage({ id: onOkText })}
        </StyledButton>
      </Row>
    </PreviewWrapper>
  )
}

export default EditMusicLayout

const Title = styled.div`
  color: ${({ theme }) => theme.colors.gray[800]};
  font-size: 16px;
  font-weight: 500;
  line-height: 100%;

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      font-size: 14px;
    `)};
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.text['#666']};
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      font-size: 13px;
    `)};
`
const colStyle = css`
  display: flex;
  flex-direction: column;
`

const Col = styled.div<{ gap: number }>`
  ${colStyle};
  gap: ${({ gap }) => gap}px;
`

const PreviewWrapper = styled(AbsoluteWrapper)`
  ${colStyle};
  gap: 24px;
`

const StyledButton = styled(DefaultButton)`
  padding: 12px 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 100%;
`

import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled, { useTheme } from 'styled-components'

import AreaLoading from './AreaLoading'
import { Fonts } from '../../types/fonts'
import {
  AreaType,
  PointType,
  getTestId,
  areaType,
  areaIcons,
  getRelativePoints,
  getRelativeThumbnailPath,
  getRelativeCenterPath,
  getRelativeIconPosition,
  getFontFormatByUrl,
  getFontSize
} from './constants'

interface AreaProps {
  points: PointType[]
  type?: AreaType
  data?: string
  currentFont: Fonts | null
  onClick?(): void
  isLoading?: boolean
  isFlickering: boolean
  index: number
}

const Area = ({
  index,
  points,
  data = '',
  currentFont,
  type = 'T',
  onClick: handleClick = () => {},
  isLoading,
  isFlickering
}: AreaProps) => {
  const [fontSize, setFontSize] = useState<number>(16)
  const [isFontReady, setIsFontReady] = useState<boolean>(true)
  const [fontStyle, setFontStyle] = useState<Record<string, string> | null>(
    null
  )
  const polygonNode = useRef<SVGPolygonElement | null>(null)
  const textNode = useRef<SVGTextElement | null>(null)
  const testId = getTestId(type)
  const theme = useTheme()

  const iconWidth = data ? 24 : 32

  const thumbnailUrl = useMemo(() => {
    if (!data) return
    let url = data
    if (type === 'V' && !url.includes('png')) {
      url = `${data.split('.mp4')[0]}.png`
    }
    return url
  }, [data, type])

  const firstPoint = useMemo(() => points[0], [points])

  const relativePoints = useMemo(() => getRelativePoints(points), [points])

  const relativeThumbnailPath = useMemo(
    () => getRelativeThumbnailPath(relativePoints),
    [relativePoints]
  )

  const relativeCenterPath = useMemo(
    () => getRelativeCenterPath(points, firstPoint),
    [points, firstPoint]
  )

  const relativeIconPosition = useMemo(
    () =>
      getRelativeIconPosition(
        !!data,
        type,
        relativePoints,
        relativeCenterPath,
        iconWidth
      ),
    [data, type, relativePoints, relativeCenterPath, iconWidth]
  )

  // handle fonts
  useEffect(() => {
    if (!currentFont) {
      setFontStyle(null)
      return
    }
    setFontStyle({
      fontFamily: currentFont.family,
      fontWeight:
        currentFont.weight?.toLowerCase()?.replace('italic', '') || '400',
      fontStyle: currentFont.weight?.toLowerCase()?.includes('italic')
        ? 'italic'
        : 'normal'
    })

    const isCssFile = currentFont?.fontFaceUrl?.includes('css')
    if (isCssFile) return

    setIsFontReady(false)
    const fontFace = new FontFace(
      currentFont?.fontFamily,
      `url(${currentFont?.fontFaceUrl})`
    )

    fontFace
      .load()
      .then(() => {
        setIsFontReady(true)
      })
      .catch(() => setIsFontReady(true))
  }, [currentFont])

  const fontFormat = useMemo(
    () => getFontFormatByUrl(currentFont?.fontFaceUrl || ''),
    [currentFont]
  )

  const adjustFontSize = useCallback(() => {
    if (!textNode.current || !polygonNode.current) return
    const padding = 4
    const maxWidth =
      Math.min(
        Math.abs(relativePoints[1][0] - relativePoints[0][0]),
        Math.abs(relativePoints[2][0] - relativePoints[3][0])
      ) -
      padding * 4
    const maxHeight =
      Math.min(
        Math.abs(relativePoints[3][1] - relativePoints[0][1]),
        Math.abs(relativePoints[2][1] - relativePoints[1][1])
      ) - padding

    const tempFontSize = getFontSize(data, fontStyle, maxWidth, maxHeight)
    setFontSize(tempFontSize)
  }, [data, fontStyle, relativePoints])

  useEffect(() => {
    if (type !== areaType.TEXT || !data || data === ' ') return
    adjustFontSize()
  }, [adjustFontSize, data, type])

  return (
    <>
      <Wrapper
        data-testid={testId}
        transform={`translate(${firstPoint.join(',')})`}
        onClick={handleClick}
        data={!!data}
      >
        <defs>
          <filter x='0' y='0' width='1.2' height='1.2' id='solid'>
            <feFlood floodColor={theme.colors.text.primary} />
            <feComposite in='SourceGraphic' operator='xor' />
          </filter>
        </defs>
        <defs>
          <linearGradient
            id='gradientWithData'
            x1='0%'
            y1='0%'
            x2='100%'
            y2='100%'
          >
            <stop offset='0%' stopColor='#3271DC' />
            <stop offset='47.59%' stopColor='#438AF5' />
            <stop offset='97.35%' stopColor='#3452BD' />
          </linearGradient>
          <linearGradient
            id='gradientNoData'
            x1='0%'
            y1='0%'
            x2='100%'
            y2='100%'
          >
            <stop offset='1.36%' stopColor='#F13939' />
            <stop offset='54.41%' stopColor='#FF6565' />
            <stop offset='102.22%' stopColor='#CD1616' />
          </linearGradient>
        </defs>
        {/* custom font style */}
        {currentFont && isFontReady && (
          <defs>
            <style>
              {`@import url('${currentFont?.fontFaceUrl}');`}
              {!currentFont?.fontFaceUrl?.includes('css') &&
                `@font-face {
                        font-family: '${
                          fontStyle?.fontFamily || currentFont?.fontFamily
                        }';
                        src: url('${
                          currentFont?.fontFaceUrl
                        }') format('${fontFormat}');
                    }`}
              {`#text-area-${index} {
                  font-family:"${fontStyle?.fontFamily}" !important;
                  font-weight: ${fontStyle?.fontWeight} !important;
                  font-style: ${fontStyle?.fontStyle} !important;
                }`}
            </style>
          </defs>
        )}
        {/* area */}
        <Polygon
          data={!!data}
          ref={polygonNode}
          className={data ? 'completed' : ''}
          isFlickering={isFlickering}
          points={relativePoints
            .map((point: number[]) => point.join(','))
            .join(',')}
        />
        {isLoading && (
          <AreaLoading
            x={relativeCenterPath.x}
            y={relativeCenterPath.y}
            iconWidth={iconWidth}
          />
        )}
        {!isLoading && isFontReady && (
          <>
            {type === areaType.TEXT && (
              <>
                {/* icon */}
                <image
                  width={iconWidth}
                  href={areaIcons.text[data ? 'completed' : 'default']}
                  x={relativeIconPosition.x}
                  y={relativeIconPosition.y}
                />
                {/* path */}
                <defs>
                  <path
                    id={`text-path-${index}`}
                    d={`M ${relativePoints[0]
                      ?.map((point: number, idx: number) =>
                        idx === 0
                          ? point + (relativePoints[3][idx] - point) / 2
                          : point +
                            (relativePoints[3][idx] - point + fontSize) / 2 -
                            4
                      )
                      ?.join(' ')} L ${relativePoints[1]
                      ?.map((point: number, idx: number) =>
                        idx === 0
                          ? point + (relativePoints[2][idx] - point) / 2
                          : point +
                            (relativePoints[2][idx] - point + fontSize) / 2 -
                            4
                      )
                      ?.join(' ')} Z`}
                    fill='none'
                    stroke='black'
                  />
                </defs>
                {/* content */}
                <Text
                  id={'text-area-' + index}
                  ref={textNode}
                  x={relativeCenterPath.x}
                  y={relativeCenterPath.y}
                  textAnchor='middle'
                  alignmentBaseline='middle'
                  style={{
                    ...fontStyle,
                    fontSize
                  }}
                >
                  <textPath href={`#text-path-${index}`}>{data}</textPath>
                </Text>
              </>
            )}
            {(type === areaType.PHOTO || type === areaType.VIDEO) && (
              <>
                {/* path */}
                <defs>
                  <clipPath id={`image-shape-${index}`}>
                    <polygon
                      points={relativePoints
                        .map((point: number[]) => point.join(','))
                        .join(',')}
                    />
                  </clipPath>
                </defs>
                {/* content */}
                {data && (
                  <image
                    href={thumbnailUrl}
                    x={relativeThumbnailPath.x}
                    y={relativeThumbnailPath.y}
                    width={relativeThumbnailPath.width}
                    height={relativeThumbnailPath.height}
                    preserveAspectRatio='xMaxYMax slice'
                    clipPath={`url(#image-shape-${index})`}
                  />
                )}
                {/* icon */}
                <image
                  width={iconWidth}
                  href={
                    (type === areaType.PHOTO
                      ? areaIcons.photo
                      : areaIcons.video)[data ? 'completed' : 'default']
                  }
                  x={relativeIconPosition.x}
                  y={relativeIconPosition.y}
                />
              </>
            )}
          </>
        )}
      </Wrapper>
    </>
  )
}

export default Area

const Wrapper = styled.g<{ data: boolean }>`
  @keyframes opacityAni {
    0% {
      opacity: 1;
    }
    40% {
      opacity: 0.2;
    }
    80% {
      opacity: 1;
    }
  }

  transition: opacityAni 0.2s ease-in-out;
  cursor: pointer;

  &:hover polygon {
    fill: ${({ data }) => (data ? 'rgba(0, 0, 0, 1)' : 'rgba(0, 0, 0, 0.8)')};
    stroke-width: 6;
  }
`

const Polygon = styled.polygon<{ data: boolean; isFlickering: boolean }>`
  fill: ${({ data }) => (data ? 'rgba(0, 0, 0, 0.8)' : 'rgba(0, 0, 0, 0.6)')};
  stroke-width: ${({ data, isFlickering }) => (isFlickering && !data ? 6 : 4)};
  stroke: ${({ data }) =>
    data ? 'url(#gradientWithData)' : 'url(#gradientNoData)'};
  transition: all 0.2s ease-in-out;
`

const Text = styled.text`
  fill: ${({ theme }) => theme.colors.white};
  font-size: 16px;
  font-weight: 700;
`

import { SyntheticEvent } from 'react'
import Video from '../../components/Video'
import useFormatText from '../../hooks/useFormatText'
import MediaModalButtons from './MediaModalButtons'
import { ImageContainer, ImageWrapper, PhotoLottieWrapper } from './PhotoModal'
import FileInfos from './Storage/FileInfos'
import { PreviewPanel, PreviewTitle } from './_stylesModal'

interface DesktopFilePreviewProps {
  type: 'image' | 'video'
  selectedUrl: string
  previewLoad: () => JSX.Element | null
  previewLoadEnd: () => void
  selectedAssetInfo: any
  setCurrentStep: (step: number) => void
  onClose: () => void
  previewLoading: boolean
  emptyText: string
  emptyIcon: JSX.Element
  handleLoadedData?: (event: SyntheticEvent<HTMLVideoElement, Event>) => void
  handlePreviewError?: () => void
  isPreviewError?: boolean
}

const DesktopFilePreview = ({
  type,
  selectedUrl,
  previewLoad,
  previewLoadEnd,
  selectedAssetInfo,
  setCurrentStep,
  onClose,
  previewLoading,
  emptyText,
  emptyIcon,
  handleLoadedData,
  handlePreviewError,
  isPreviewError
}: DesktopFilePreviewProps) => {
  return (
    <>
      <PreviewPanel>
        <PreviewTitle>{useFormatText('IMAGE_INPUT_PREVIEW')}</PreviewTitle>
        <ImageContainer>
          {!selectedUrl && (
            <div className='empty-container'>
              {emptyIcon}
              <span className='empty-text'>{emptyText}</span>
            </div>
          )}

          {selectedUrl && (
            <ImageWrapper innerHeight={document.documentElement.clientHeight}>
              {type === 'image' && (
                <img
                  onLoad={() => previewLoadEnd()}
                  className='selected-image'
                  src={selectedUrl}
                  alt='preview'
                />
              )}
              {type === 'video' && (
                <Video
                  videoClassName='selected-image'
                  src={selectedUrl}
                  isPreviewError={!!isPreviewError}
                  handleLoadedData={handleLoadedData ?? (() => {})}
                  handlePreviewError={handlePreviewError ?? (() => {})}
                  muted
                  autoPlay
                  playsInline
                />
              )}
              <PhotoLottieWrapper>{previewLoad()}</PhotoLottieWrapper>
            </ImageWrapper>
          )}

          {selectedUrl && selectedAssetInfo && (
            <FileInfos selectedAssetInfo={selectedAssetInfo} />
          )}
        </ImageContainer>
        <MediaModalButtons
          okButtonDisabled={!selectedUrl}
          onClickLoad={() => setCurrentStep(1)}
          onClickCancel={onClose}
          loading={previewLoading}
        />
      </PreviewPanel>
      <div
        style={{
          width: 1,
          height: 'calc(100% - 84px)',
          background: '#E1E1E1',
          alignSelf: 'flex-end'
        }}
      />
    </>
  )
}

export default DesktopFilePreview

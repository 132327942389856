import { Dispatch, SetStateAction, useMemo } from 'react'
import { useProjectContext } from '../../hooks/useProject'
import { UseModalProps } from '../../types/useModal'
import { files } from '../../utils/api'
import PhotoModal from './PhotoModal'
import TextModal from './TextModal'
import VideoModal, { CropData } from './VideoModal'
import { Fonts } from '../../types/fonts'

interface EditModalsProps {
  editModal: UseModalProps
  selectedSourceIndex: number
  selectedSceneIndex: number
  setProcessingSources: Dispatch<
    SetStateAction<{ sceneIndex: number; sourceIndex: number }[]>
  >
  setIsModified: Dispatch<SetStateAction<boolean>>
}

const EditModals = ({
  editModal,
  selectedSourceIndex,
  selectedSceneIndex,
  setProcessingSources,
  setIsModified
}: EditModalsProps) => {
  const { project, setProject, sceneList } = useProjectContext()
  const userSourcesValue =
    project?.sources?.[selectedSceneIndex]?.[selectedSourceIndex]

  const selectSource =
    sceneList?.[selectedSceneIndex]?.source?.[selectedSourceIndex]

  const selectedSourceType = selectSource?.sourceType

  const defaultFontsList: Array<Fonts | null> = useMemo(
    () =>
      ((project?.changedFonts || [])?.length > 0 && project?.changedFonts) ||
      project?.sources?.reduce((acc: null[], source) => {
        const result = source?.map((_) => null)
        return acc.concat(...result)
      }, []) ||
      [],
    [project]
  )

  const fontIndex = useMemo(
    () =>
      project?.sources?.reduce((acc: number, curr, idx) => {
        if (idx < selectedSceneIndex) acc += curr?.length
        if (idx === selectedSceneIndex) acc += selectedSourceIndex
        return acc
      }, 0) || 0,
    [project, selectedSceneIndex, selectedSourceIndex]
  )

  const currentFont = defaultFontsList[fontIndex] || null

  const photoSourceSize = {
    sourceWidth: selectSource?.sourceWidth || 0,
    sourceHeight: selectSource?.sourceHeight || 0
  }

  const designSource =
    project?.design?.sources?.[selectedSceneIndex]?.source?.[
      selectedSourceIndex
    ]

  const photoAspect =
    (designSource?.sourceWidth || 1) / (designSource?.sourceHeight || 1)

  const videoSize = {
    width: designSource?.sourceWidth || 1,
    height: designSource?.sourceHeight || 1
  }

  const patchUserSource = (
    sceneIndex: number,
    sourceIndex: number,
    value: string,
    changeOther?: Record<string, any>
  ) => {
    if (project) {
      const newUserSources = [...project.sources]
      newUserSources[sceneIndex][sourceIndex] = value
      setIsModified(true)
      setProject &&
        setProject({
          ...project,
          sources: newUserSources,
          ...(changeOther || {})
        })
    }
  }

  const setSourceProcessing = (
    sceneIndex: number,
    sourceIndex: number,
    isProcessing = true
  ) => {
    if (isProcessing) {
      setProcessingSources((current: any) => {
        return [...current, { sceneIndex, sourceIndex }]
      })
      return
    }
    setProcessingSources((current: any) => {
      return current.filter(
        (item: any) =>
          !(item.sceneIndex === sceneIndex && item.sourceIndex === sourceIndex)
      )
    })
  }
  const OnCompleteText = (
    value: string,
    selectedFont: Fonts | null,
    isFontApplyGlobal: boolean
  ) => {
    let result = defaultFontsList
    if (isFontApplyGlobal) {
      result = result?.map((_) => selectedFont)
    } else {
      result[fontIndex] = selectedFont
    }
    patchUserSource(selectedSceneIndex, selectedSourceIndex, value, {
      changedFonts: result
    })
    editModal.close()
  }
  const OnCompletePhoto = async (dataUrl: string) => {
    const sceneIndex = selectedSceneIndex
    const sourceIndex = selectedSourceIndex
    setSourceProcessing(sceneIndex, sourceIndex)
    const blob = await (await fetch(dataUrl)).blob()
    const res = await files().files.upload(blob)
    if (res?.url) {
      patchUserSource(sceneIndex, sourceIndex, res?.url)
      editModal.close()
    }
    setSourceProcessing(sceneIndex, sourceIndex, false)
  }

  const onCompleteVideo = async (file: File, cropData: CropData) => {
    const sceneIndex = selectedSceneIndex
    const sourceIndex = selectedSourceIndex
    setSourceProcessing(sceneIndex, sourceIndex)
    const selectedSource =
      sceneList?.[selectedSceneIndex]?.source?.[selectedSourceIndex]
    if (selectedSource) {
      const {
        sourceVideoTime: duration = 0,
        sourceWidth: renderWidth = 0,
        sourceHeight: renderHeight = 0
      } = selectedSource
      const result = await files().files.cropAndTrimVideo({
        file,
        duration,
        renderWidth,
        renderHeight,
        ...cropData
      })
      if (result) {
        const { videoUrl } = result
        patchUserSource(selectedSceneIndex, selectedSourceIndex, videoUrl)
        editModal.close()
      }
    }
    setSourceProcessing(sceneIndex, sourceIndex, false)
  }

  return (
    <div>
      <TextModal
        visible={editModal.visible && selectedSourceType === 'T'}
        onClose={editModal.close}
        onComplete={OnCompleteText}
        textLength={
          project?.design?.sources[selectedSceneIndex]?.source?.[
            selectedSourceIndex
          ]?.sourceTextLength?.[0]
        }
        defaultFont={currentFont}
        value={userSourcesValue}
        closeBtnType='round'
      />
      <PhotoModal
        sourceSize={photoSourceSize}
        visible={editModal.visible && selectedSourceType === 'I'}
        onClose={editModal.close}
        onComplete={OnCompletePhoto}
        value={userSourcesValue}
        aspect={photoAspect}
        closeBtnType='round'
      />
      <VideoModal
        minDuration={
          sceneList?.[selectedSceneIndex]?.source[selectedSourceIndex]
            ?.sourceVideoTime
        }
        visible={editModal.visible && selectedSourceType === 'V'}
        onClose={editModal.close}
        onComplete={onCompleteVideo}
        value={userSourcesValue}
        duration={
          project?.design?.sources?.[selectedSceneIndex]?.source?.[
            selectedSourceIndex
          ]?.sourceVideoTime
        }
        videoSize={videoSize}
        closeBtnType='round'
      />
    </div>
  )
}

export default EditModals

import { css } from 'styled-components'
import DefaultTooltip from '../../components/DefaultTooltip'

interface RemoveBgTooltipProps {
  isBreakpoint: (
    size: 'small' | 'medium' | 'large' | 'xLarge' | 'xxLarge' | 'xxxLarge'
  ) => boolean
  tooltipContents: string
  children: JSX.Element
}

const RemoveBgTooltip = ({
  isBreakpoint,
  tooltipContents,
  children
}: RemoveBgTooltipProps) => {
  return (
    <DefaultTooltip
      position={
        !isBreakpoint('medium')
          ? { top: -24, left: 0 }
          : { top: '50%', left: 'calc(100% + 9px)' }
      }
      tail={
        !isBreakpoint('medium')
          ? { direction: 'bottom', position: { top: 28, left: 12 } }
          : {
              direction: 'left',
              position: { top: '50%', left: -5 }
            }
      }
      contents={tooltipContents}
      contentStyle={{ transform: 'translateY(-50%)' }}
      tailStyle={css`
        transform: translateY(-50%);
      `}
      touch
    >
      {children}
    </DefaultTooltip>
  )
}

export default RemoveBgTooltip

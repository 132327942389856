import { InputHTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import { iconZoomSearch } from '../../assets/icons'
import theme from '../../styles/theme'

interface SearchInputProps extends InputHTMLAttributes<HTMLInputElement> {
  wrapperStyle?: React.CSSProperties
  handleSearch: () => void
}

const SearchInput = (props: SearchInputProps) => {
  return (
    <Wrapper
      onSubmit={(e) => {
        e.preventDefault()
        props.handleSearch()
      }}
      style={props.wrapperStyle}
    >
      <SearchButton />
      <Input {...props} />
    </Wrapper>
  )
}

export default SearchInput

const noOutline = css`
  border: none;
  outline: none;
`

const Wrapper = styled.form`
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid #c2c2c2;
  border-radius: 2px;
  padding: 8px 12px;
  gap: 8px;
`

const SearchButton = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  ${noOutline};
  padding: 0;
  background-color: ${theme.colors.white};
  background-image: url(${iconZoomSearch});
  background-repeat: no-repeat;
  cursor: pointer;
`

const Input = styled.input`
  width: 100%;
  ${noOutline};
  color: ${theme.colors.black};
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;

  &::placeholder {
    color: #a3a3a3;
  }
`

import styled, { css } from 'styled-components'
import Modal, { ModalProps } from '../../components/Modal'
import Button from '../../components/Button'
import { iconModalClose } from '../../assets/icons'

interface EditModalLayoutProps extends ModalProps {
  title: string
  children: React.ReactNode
}

const EditModalLayout = ({
  title,
  children,
  ...props
}: EditModalLayoutProps) => {
  return (
    <Modal
      notitleBar
      noFullHeight
      closable
      fitContent
      backgroundColor='rgba(0,0,0,0.7)'
      wrapperStyle={props?.wrapperStyle}
      style={css`
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 0;
        ${props?.style || ''};
      `}
      {...props}
    >
      <Title>{title}</Title>
      <CloseButton onClick={props?.onClose} />
      {children}
    </Modal>
  )
}

export default EditModalLayout

const CloseButton = styled(Button)`
  position: absolute;
  top: 24px;
  right: 20px;
  width: 24px;
  height: 24px;
  padding: 0;
  background-image: url(${iconModalClose});
  border: none;

  ${({ theme }) =>
    theme.breakpoints.large(css`
      top: 17px;
    `)};
`

const Title = styled.div`
  width: 100%;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray[800]};
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 24px */
  flex-shrink: 0;

  ${({ theme }) =>
    theme.breakpoints.large(css`
      height: 58px;
      font-size: 16px;
    `)};
`

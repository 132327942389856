import styled, { css } from 'styled-components'
import Dropdown from '../../components/Dropdown'
import { DropDownTitle, dropdownDisabledCss } from './_stylesTextModal'
import theme from '../../styles/theme'
import { useMemo, useState } from 'react'
import FontList from './FontList'
import BookMarkFontList from './BookMarkFontList'
import SearchInput from '../../components/SearchInput'
import useFormatText from '../../hooks/useFormatText'
import { Fonts, reqFontLang } from '../../types/fonts'
import { useFontsQuery } from './_queries'
import { fontReqLang } from './constants'

import FilterChip from '../../components/FilterChip'
import useLocalStorage from '../../hooks/useLocalStorage'

interface FontListDropdownProps {
  selectedFont: Fonts | null
  handleSelectFont: (font: Fonts | null) => void
  isToggled: boolean
}

const FontListDropdown = ({
  selectedFont,
  handleSelectFont,
  isToggled
}: FontListDropdownProps) => {
  const isKr = localStorage.getItem('locale')?.includes('ko') ?? false

  const [activeTabIndex, setActiveTabIndex] = useState<number>(0)
  const [searchValue, setSearchValue] = useState<string>('')
  const [reqLang, setReqLang] = useState<reqFontLang>('ko')
  const { fontsList, fetchNextPage, hasNext, isFetching } = useFontsQuery({
    search: searchValue,
    reqLang: [reqLang],
    preventFetch: isToggled
  })

  const [bookmarkedFonts, setBookmarkedFonts] = useLocalStorage<Fonts[]>(
    'favFont',
    []
  )

  const bookmarkedFontsByLang = useMemo(
    () => [...bookmarkedFonts.filter((font) => font.lang.includes(reqLang))],
    [reqLang, bookmarkedFonts]
  )

  const handleClickBookMark = (font: Fonts | null) => {
    if (!font) return

    if (bookmarkedFonts.includes(font)) {
      setBookmarkedFonts((prev) => {
        return prev.filter((item) => item?._id !== font._id)
      })
      return
    }
    setBookmarkedFonts((prev) => {
      return [...prev, font]
    })
  }

  const noSearchResult = searchValue && fontsList.length === 0

  const handleChangeSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value || '')
  }

  const handleSearch = () => {}

  const handleChangeTab = (index: number) => {
    setActiveTabIndex(index)

    if (searchValue) {
      setSearchValue('')
    }
  }

  const handleClickChip = (langId: reqFontLang) => {
    setReqLang(langId)
  }

  return (
    <Dropdown
      label={
        <DropDownTitle>
          {selectedFont
            ? `${
                selectedFont.name?.[isKr ? 'ko' : 'en'] || selectedFont.name
              } ${selectedFont.weight}`
            : useFormatText('text_0391')}
        </DropDownTitle>
      }
      style={DropdownCustomStyle(isToggled)}
    >
      <Container onClick={(e) => e.stopPropagation()}>
        <Tabs>
          <Tab
            isActive={activeTabIndex === 0}
            onClick={() => handleChangeTab(0)}
          >
            <span>{useFormatText('TEXT_MODAL_FONT_ALL')}</span>
          </Tab>
          <Tab
            isActive={activeTabIndex === 1}
            onClick={() => handleChangeTab(1)}
          >
            <span>{useFormatText('TEXT_MODAL_FONT_BOOKMARK')}</span>
          </Tab>
        </Tabs>
        <ContentContainer>
          <SearchInput
            value={searchValue}
            onChange={handleChangeSearchValue}
            placeholder={useFormatText('TEXT_MODAL_SEARCH_FONT_PLACEHOLDER')}
            handleSearch={handleSearch}
          />
          <div style={{ position: 'relative', marginBottom: 8 }}>
            <ChipsContainer>
              {fontReqLang.map((lang) => {
                return (
                  <FilterChip
                    key={lang.id}
                    isSelected={reqLang.includes(lang.id)}
                    onClick={() => handleClickChip(lang.id)}
                  >
                    {useFormatText(lang.name)}
                  </FilterChip>
                )
              })}
            </ChipsContainer>
          </div>
          {noSearchResult && bookmarkedFonts.length !== 0 && (
            <EmptyContainer>
              {useFormatText('TEXT_MODAL_SEARCH_FONT_EMPTY')}
            </EmptyContainer>
          )}
          {!noSearchResult && activeTabIndex === 0 && (
            <FontList
              fonts={searchValue ? fontsList : [null, ...fontsList]}
              selectedFont={selectedFont}
              handleSelectFont={handleSelectFont}
              fetchNextPage={fetchNextPage}
              hasNext={!!hasNext}
              isFetching={isFetching}
              bookmarkedFonts={bookmarkedFonts}
              handleClickBookMark={handleClickBookMark}
            />
          )}
          {activeTabIndex === 1 && bookmarkedFontsByLang.length === 0 && (
            <EmptyContainer>
              {useFormatText('TEXT_MODAL_BOOKMARK_FONT_EMPTY')}
            </EmptyContainer>
          )}
          {activeTabIndex === 1 && bookmarkedFontsByLang.length > 0 && (
            <BookMarkFontList
              selectedFont={selectedFont}
              handleSelectFont={handleSelectFont}
              bookmarkedFonts={bookmarkedFontsByLang}
              handleClickBookMark={handleClickBookMark}
            />
          )}
        </ContentContainer>
      </Container>
    </Dropdown>
  )
}

export default FontListDropdown

const Container = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #c2c2c2;
  background-color: ${theme.colors.white};
  box-shadow: 0px 5px 20px 4px rgba(51, 51, 51, 0.07);
`
const ContentContainer = styled.div`
  width: 100%;
  padding-left: 9px;
  padding-right: 9px;
  margin-top: 8px;
`

const Tabs = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  margin-top: 8px;
`

const Tab = styled.div<{ isActive: boolean }>`
  display: inline-flex;
  width: 50%;
  color: #c2c2c2;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  padding: 0 8px;

  span {
    display: inline-block;
    width: 100%;
    text-align: center;
    padding: 10px 0;
  }
  ${({ isActive }) =>
    isActive &&
    css`
      span {
        color: #4d4d4d;
        box-shadow: 0 -1px 0 0 #4d4d4d inset;
      }
    `}
`

const EmptyContainer = styled.div`
  width: 100%;
  height: 36px;
  color: #a3a3a3;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  padding: 8px 16px;
  margin-top: 12px;
  margin-bottom: 8px;
`

const DropdownCustomStyle = (isToggled: boolean) => css`
  flex: 1;
  ${isToggled && dropdownDisabledCss};
`

const ChipsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4px 4px 5px;
  margin-top: 8px;
  white-space: nowrap;
  overflow-x: auto;
`

import { useState } from 'react'
import {
  DefaultTheme,
  FlattenInterpolation,
  FlattenSimpleInterpolation,
  ThemeProps
} from 'styled-components'
import useOutsideClick from '../../hooks/useOutsideClick'
import { ArrowIcon, DropdownWrapper } from './_styles'

interface DropdownProps {
  label: React.ReactNode
  style?:
    | FlattenSimpleInterpolation
    | FlattenInterpolation<ThemeProps<DefaultTheme>>
  children: React.ReactNode
}

const Dropdown = ({ label, style, children }: DropdownProps) => {
  const [isActive, setIsActive] = useState(false)
  const dropdownRef = useOutsideClick<HTMLDivElement>(() => setIsActive(false))

  return (
    <DropdownWrapper
      ref={dropdownRef}
      onClick={() => {
        setIsActive((prevState) => !prevState)
      }}
      isActive={isActive}
      custom={style}
    >
      <div className={`dropdown ${isActive ? 'active' : 'inactive'}`}>
        <div className='label'>{label}</div>
        <ArrowIcon isActive={isActive} className='arrow-icon' />
      </div>
      {isActive && children}
    </DropdownWrapper>
  )
}

export default Dropdown

import React from 'react'
import styled, { css } from 'styled-components'
import DefaultButton from '../../components/DefaultButton'
import useFormatText from '../../hooks/useFormatText'
import { message } from 'antd'

interface MediaModalButtonsProps {
  okButtonDisabled: boolean
  onClickLoad: () => void
  onClickCancel: () => void
  loading: boolean
  buttonStyle?: React.CSSProperties
}

const MediaModalButtons = ({
  okButtonDisabled,
  onClickLoad,
  onClickCancel,
  loading,
  buttonStyle
}: MediaModalButtonsProps) => {
  const IMAGE_INPUT_PREVIEW_DEFAULT = useFormatText(
    'IMAGE_INPUT_PREVIEW_DEFAULT'
  )
  const handleClickLoadButton = () => {
    if (okButtonDisabled) {
      message.error(IMAGE_INPUT_PREVIEW_DEFAULT)
      return
    }
    onClickLoad()
  }

  return (
    <Footer>
      <DefaultButton category='secondaryMonochrome' onClick={onClickCancel}>
        {useFormatText('IMAGE_INPUT_CANCEL')}
      </DefaultButton>
      <DefaultButton
        category='secondaryMulticolored'
        onClick={handleClickLoadButton}
        loading={loading}
        style={buttonStyle}
      >
        {useFormatText('IMAGE_INPUT_SELECT')}
      </DefaultButton>
    </Footer>
  )
}

export default MediaModalButtons
export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: auto;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 100;

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      display: flex;
      flex-direction: row-reverse;
      position: relative;
      background-color: transparent;
      user-select: none;
      width: 100%;
    `)}
`

import styled, { css } from 'styled-components'

import { Checkbox } from 'antd'
import DefaultButton from '../DefaultButton'
import { iconTriangleFilledRight } from '../../assets/icons'

export const ColorPickerWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: 12px 0px;
  overflow: hidden;

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      padding: 8px 0px 10px;
    `)};

  .react-colorful {
    width: 100%;
    height: 100%;

    // NOTE: 위 아래 박스 pointer 스타일링
    .react-colorful__pointer {
      position: absolute;
      z-index: 1;
      box-sizing: border-box;
      width: 16px;
      height: 16px;
      transform: translate(-50%, -50%);
      background-color: ${({ theme }) => theme.colors.white};
      border: 1px solid ${({ theme }) => theme.colors.white};
      border-radius: 50%;
      box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
    }

    .react-colorful__saturation {
      position: relative;
      flex-grow: 1;
      height: 140px;
      border-color: transparent;
      border-bottom: 12px solid ${({ theme }) => theme.colors.black};
      border-radius: 0;

      ${({ theme }) =>
        theme.breakpoints.medium(css`
          border-radius: 4px;
        `)};
    }
    .react-colorful__last-control {
      margin-top: 8px;
      border-radius: 0;
      height: 12px;

      ${({ theme }) =>
        theme.breakpoints.medium(css`
          border-radius: 8px;
        `)};
    }
  }
`

export const HexColorInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  height: 38px;
  border: solid 1px ${({ theme }) => theme.colors.lightGrey};
  border-radius: 4px;
  padding: 7px 8px;

  input {
    width: 62px;
    height: 14px;
    color: ${({ theme }) => theme.colors.text['#222']};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    outline: none;
    border: none;
    padding: 0;
  }
`
export const AddFavoriteButton = styled(DefaultButton)`
  padding: 7px 12px;
  border: 1px solid ${({ theme }) => theme.colors.gray[100]};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.gray[500]};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;

  & > div {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  svg {
    width: 18px;
    height: 18px;

    path {
      stroke: ${({ theme }) => theme.colors.gray[500]};
    }
  }

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      padding: 6px 12px;
      font-size: 13px;
    `)};
`

export const EditFavoriteButton = styled(AddFavoriteButton)`
  gap: 4px;
  border-radius: 4px;

  &&& {
    path {
      stroke: none;
      fill: ${({ theme }) => theme.colors.gray[500]};
    }
  }
`

export const Container = styled.div`
  height: 100%;

  .tabs-wrapper {
    display: flex;
    flex-direction: column;
    padding: 16px 24px 0;
  }

  .tab-list {
    padding: 0;

    button {
      margin-left: 0;
      height: 48px;
    }
  }

  .tab-content {
    height: auto;
    flex: 1;
    padding: 0;
  }
`

export const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 44px);
  height: calc(100svh - 44px);
  height: 100%;
  position: relative;
  overflow: auto;
  padding-top: 24px;
  padding-left: 16px;
  padding-right: 16px;

  ${({ theme }) =>
    theme.breakpoints.xLarge(css`
      padding-bottom: 70px;
    `)};
`

export const ColorContainer = styled.div`
  width: 100%;
  position: relative;
  margin: 0 auto;
  overflow: auto;
`

export const Row = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Footer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  padding: 16px 0;
  margin-top: 16px;
  flex-direction: row-reverse;
  border-top: 1px solid ${({ theme }) => theme.colors.border.primary};
  ${({ theme }) =>
    theme.breakpoints.medium(css`
      padding: 20px 21px 60px;
    `)};
`

export const Preview = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  justify-content: center;
  gap: 47px;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.primary};
  padding-bottom: 24px;

  & > div {
    position: relative;
    text-align: center;
  }
`

export const SecondaryTypography = styled.span`
  color: ${({ theme }) => theme.colors.gray[600]};
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
`

export const ColorTypography = styled.span`
  color: ${({ theme }) => theme.colors.gray[700]};
  font-size: 14px;
  font-weight: 600;
  line-height: 100%;
`

export const EmptyTypography = styled.div`
  color: ${({ theme }) => theme.colors.gray[400]};
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  white-space: pre-wrap;
`

export const FavoriteEmptyTypography = styled(EmptyTypography)`
  width: 100%;
  height: 112px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ChipsRow = styled(Row)`
  margin-top: 30px;
`

export const ColorPickRow = styled(Row)`
  margin-top: 24px;
`

export const Count = styled.span`
  color: ${({ theme }) => theme.colors.gray[300]};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 14px */
`

export const BoldTypography = styled(ColorTypography)`
  color: ${({ theme }) => theme.colors.gray[700]};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 22.4px */
`

export const ColorColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`

export const IconArrowRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 27px;
  background-image: url(${iconTriangleFilledRight});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`

export const StyledColorColumn = styled(ColorColumn)`
  width: 80px;
  justify-content: center;
`

export const ChipsWrapper = styled(ColorColumn)`
  align-items: start;
  gap: 16px;

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      gap: 8px;
    `)};
`

export const StyledCheckbox = styled(Checkbox)`
  position: absolute;
  top: 3px;
  left: 3px;

  * {
    width: 10px;
    height: 10px;
  }

  &::after {
    height: 10px;
  }

  .ant-checkbox-inner:after {
    width: 4px;
    height: 8px;
    left: 10%;
  }
`

export const AlignCenter = styled.div`
  display: flex;
  align-items: center;
`

export const CheckAlignCenter = styled(AlignCenter)`
  gap: 8px;
  cursor: pointer;

  .checkbox-wrapper {
    width: 16px;
    height: 16px;

    .checkbox {
      width: 16px;
      height: 16px;

      &.not-checked {
        border: 1px solid ${({ theme }) => theme.colors.gray[200]};
      }
    }

    svg {
      width: 9px;
      height: 8px;
    }
  }

  span {
    color: ${({ theme }) => theme.colors.gray[500]};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */

    strong {
      color: ${({ theme }) => theme.colors.primary};
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 16px */
    }
  }
`

import { useEffect, useRef } from 'react'
import AudioPlayer from 'react-h5-audio-player'
import 'react-h5-audio-player/lib/styles.css'
import styled, { css } from 'styled-components'

import { IconPlayAudio, iconPause } from '../../assets/icons'

interface AudioProps {
  src?: string
  duration?: number
  startTime?: number
  handlePlay: (audio: HTMLAudioElement) => void
  handlePause: (audio: HTMLAudioElement, startTime?: number) => void
  handleSeeked?: (audio: HTMLAudioElement, startTime?: number) => void
  handleTimeUpdate?: (audio: HTMLAudioElement, startTime: number) => void
}

const Audio = ({
  src,
  handlePlay,
  handlePause,
  handleSeeked,
  handleTimeUpdate: handleTimeUpdateProps,
  duration,
  startTime = 0
}: AudioProps) => {
  // NOTE: using any due to library type issue
  const ref = useRef<any>()
  const audio = ref.current && ref.current.audio.current

  useEffect(() => {
    if (audio && startTime! > 0) {
      if (audio.paused) {
        handlePlay(audio)
      }
      audio.currentTime = startTime!
    }
  }, [audio, startTime])

  const handleTimeUpdate = () => {
    if (!audio || !handleTimeUpdateProps) return
    handleTimeUpdateProps(audio, startTime)
  }

  useEffect(() => {
    if (!audio) return
    audio.addEventListener('timeupdate', handleTimeUpdate)
    return () => {
      audio.removeEventListener('timeupdate', handleTimeUpdate)
      handlePause(audio)
    }
  }, [audio, startTime])

  return (
    <Wrapper>
      <AudioPlayer
        defaultDuration={duration || ''}
        preload='metadata'
        ref={ref}
        src={src}
        autoPlay={false}
        autoPlayAfterSrcChange={false}
        layout={'horizontal-reverse'}
        customVolumeControls={[]}
        showJumpControls={false}
        customAdditionalControls={[]}
        customIcons={{
          play: <IconPlayAudio width={18} height={18} />,
          pause: <img width={18} height={18} src={iconPause} alt={'pause'} />
        }}
        onPlay={(e) => handlePlay(e.currentTarget as HTMLAudioElement)}
        onPause={(e) =>
          handlePause(e.currentTarget as HTMLAudioElement, startTime)
        }
        onSeeked={(e) =>
          handleSeeked &&
          handleSeeked(e.currentTarget as HTMLAudioElement, startTime)
        }
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  /* // NOTE: library custom styling */
  font-feature-settings: 'tnum', 'tnum';

  .rhap_container {
    padding: 0;
    box-shadow: none;
  }

  .rhap_main-controls-button {
    margin: 0;
  }

  .rhap_main {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .rhap_time {
    color: ${({ theme }) => theme.colors.gray[500]};
    font-size: 14px;
    font-weight: 500;
    line-height: 100%;

    ${({ theme }) =>
      theme.breakpoints.medium(css`
        font-size: 13px;
      `)};
  }

  .rhap_progress-container {
    display: flex;
    align-items: center;
    height: 21px;
    flex: 1 0 auto;
    align-self: center;
    margin: 0 8px;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
  }

  .rhap_download-progress {
    background: #f3f3f3;
  }

  .rhap_progress-bar {
    height: 6px;
    border-radius: 4px;
  }
  .rhap_progress-filled {
    background-color: ${({ theme }) => theme.colors.brand.primary[400]};
    border-radius: 4px;
  }

  .rhap_progress-indicator {
    display: none;
  }

  .rhap_play-pause-button {
    width: 18px;
    height: 18px;
    font-size: inherit;
  }

  .rhap_controls-section {
    margin-right: 0;
    flex: none;
  }
`

export default Audio

import {
  iconReverseHorizontal,
  iconReverseVertical,
  iconRotate
} from '../../assets/icons'

export const getFunctions = (
  handleFlipX: () => void,
  handleFlipY: () => void,
  handleRotate: () => Promise<void>
) => [
  {
    icon: iconReverseHorizontal,
    text: 'IMAGE_INPUT_EDIT_BUTTON1',
    onClick: handleFlipX
  },
  {
    icon: iconReverseVertical,
    text: 'IMAGE_INPUT_EDIT_BUTTON2',
    onClick: handleFlipY
  },
  {
    icon: iconRotate,
    text: 'IMAGE_INPUT_EDIT_BUTTON3',
    onClick: handleRotate
  }
]

import styled, { css } from 'styled-components'
import {
  IconToggleArrowRight,
  sidebarToggleBackground
} from '../../assets/icons'

export const Wrapper = styled.div`
  display: flex;
  position: fixed;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 400;

  ${({ theme }) =>
    theme.breakpoints.xxxLarge(css`
      width: 64px;
      height: calc(100vh - 61px);
      height: calc(100svh - 61px);
      left: 0;
      top: 60px;
      bottom: unset;
      flex-direction: column;
      border-right: 1px solid ${theme.colors.border.primary};
      box-shadow: 0px 0px 8px 2px rgba(124, 124, 124, 0.08);
    `)};
  ${({ theme }) =>
    theme.breakpoints.large(css`
      position: fixed;
      width: 100%;
      height: auto;
      left: 0;
      top: unset;
      bottom: 0;
      flex-direction: row;
      border-top: 1px solid ${theme.colors.border.primary};
      justify-content: center;
    `)};
`

export const ToggleButton = styled.button<{ open: boolean }>`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(100%, -50%);
  width: 28px;
  height: 87px;
  background-color: transparent;
  background-image: url(${sidebarToggleBackground});
  background-size: contain;
  background-repeat: no-repeat;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
  z-index: 220;
`

export const ArrowRight = styled(IconToggleArrowRight)<{ open: boolean }>`
  position: absolute;
  left: -5px;
  top: 50%;
  width: 24px;
  height: 24px;

  ${({ open }) =>
    open
      ? `
      transform: translateY(-50%) rotate(180deg);
      `
      : `transform: translateY(-50%) rotate(0deg);`};
`

const DRAWER_LEFT_POSITION_WIDTH = 420
export const DrawerWrapper = styled.div<{ visible: boolean }>`
  position: absolute;
  z-index: 200;
  transition: all 0.3s ease-in-out;

  ${({ theme, visible }) =>
    theme.breakpoints.xxxLarge(css`
      width: ${DRAWER_LEFT_POSITION_WIDTH}px;
      height: 100%;
      top: 0;
      left: ${visible ? '64px' : '-420px'};
      border-right: 1px solid ${theme.colors.border.primary};
      box-shadow: 0px 0px 8px 2px rgba(124, 124, 124, 0.08);
    `)};

  ${({ theme, visible }) =>
    theme.breakpoints.large(css`
      width: 100vw;
      height: 65vh;
      top: unset;
      left: 0;
      bottom: ${visible ? '0' : '-100vh'};
    `)}

  ${({ theme, visible }) =>
    theme.breakpoints.medium(css`
      position: fixed;
      width: 100vw;
      top: ${visible ? 0 : '100vh'};
      height: 100vh;
      height: 100svh;
      left: 0;

      bottom: ${visible ? 0 : '-100vh'};
      @supports (-webkit-touch-callout: none) {
        height: -webkit-fill-available;
      }
    `)};
`

export const MenuRow = styled.div`
  display: block;

  ${({ theme }) =>
    theme.breakpoints.large(css`
      width: 100%;
      max-width: 500px;
      display: flex;
      justify-content: space-around;
    `)}
  ${({ theme }) =>
    theme.breakpoints.medium(css`
      max-width: inherit;
    `)}
`

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 240;
`

import styled, {
  DefaultTheme,
  FlattenInterpolation,
  FlattenSimpleInterpolation,
  ThemeProps,
  css
} from 'styled-components'

import { IconDropdownArrow } from '../../assets/icons'
import theme from '../../styles/theme'

export const DropdownWrapper = styled.div<{
  custom?:
    | FlattenSimpleInterpolation
    | FlattenInterpolation<ThemeProps<DefaultTheme>>
  isActive: boolean
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: auto;
  font-size: 14px;
  line-height: 20px;
  z-index: 200;
  cursor: pointer;
  user-select: none;

  .dropdown {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 6px 12px;
    border: 1px solid #c2c2c2;
    border-radius: 4px;

    .label {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    ${({ isActive }) =>
      isActive &&
      css`
        border-color: #4d4d4d;
        transition: all 0.2s ease-in-out;
      `}
  }

  ${({ custom }) => custom && custom};
`

export const ArrowIcon = styled(IconDropdownArrow)<{ isActive: boolean }>`
  width: 17px;
  height: 24px;
  transition: all 0.2s ease-in-out;

  ${({ isActive }) =>
    isActive &&
    css`
      transform: rotate(-180deg);
    `}
`

export const SubMenu = styled.ul`
  position: absolute;
  top: 48px;
  right: 0;
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  list-style: none;
  margin: 0;
  padding: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
`

export const SubMenuItem = styled.li<{ isSelected: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 38px;
  padding: 8px 20px 8px 12px;
  background-color: #fff;
  cursor: pointer;

  ${({ isSelected }) =>
    !isSelected &&
    css`
      &:hover {
        color: #131313;
        background-color: ${theme.colors.background.primary};
      }
    `}

  ${({ isSelected }) =>
    isSelected &&
    css`
      color: ${theme.colors.text.active};
      background-color: ${theme.colors.background.active};
    `}
`

import { useIntl } from 'react-intl'
import Tabs from '../../components/Tabs'
import Storage from './Storage'
import VideoList from './VideoList'
import { css } from 'styled-components'

interface VideoModalTabsProps {
  minDuration?: number
  visible?: boolean
  fileLoading: boolean
  onClickItem: (url: string) => void
  selectedImageUrl: string
  setSelectedAssetInfo: (asset: any) => void
  selectedTabIndex: number
  setSelectedTabIndex: React.Dispatch<React.SetStateAction<number>>
}

const VideoModalTabs = ({
  minDuration,
  visible,
  fileLoading,
  onClickItem,
  selectedImageUrl,
  setSelectedAssetInfo,
  selectedTabIndex,
  setSelectedTabIndex
}: VideoModalTabsProps) => {
  const intl = useIntl()

  return (
    <Tabs
      tabs={[
        {
          title: intl.formatMessage({ id: 'VIDEO_INPUT_FROM_FREESTOCK' }),
          children: (
            <div
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <VideoList
                minDuration={minDuration}
                visible={visible}
                fileLoading={fileLoading}
                onClickItem={onClickItem}
                selectedImageUrl={selectedImageUrl}
              />
            </div>
          ),
          onClick: () => setSelectedTabIndex(0)
        },
        {
          title: intl.formatMessage({ id: 'IMAGE_INPUT_FROM_STORAGE' }),
          children: (
            <>
              <Storage
                onClickItem={(asset: any) => {
                  if (!asset) return
                  onClickItem(asset?.convertedUrl || asset?.originalUrl)
                  setSelectedAssetInfo(asset)
                }}
                type='video'
              />
            </>
          ),
          onClick: () => setSelectedTabIndex(1)
        }
      ]}
      selectedTabIndex={selectedTabIndex}
      wrapperStyle={tabWrapperStyle}
    />
  )
}

export default VideoModalTabs

const tabWrapperStyle = css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .tab-content {
    flex-grow: 1;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;

    ${({ theme }) => theme.breakpoints.medium`
        height: calc(100% - 35px);
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        overflow-y: auto;
    `};
  }

  .tab-list {
    height: 36px;
    flex-shrink: 0;
    gap: 20px;
    padding: 0;
    margin-top: 24px;
  }
  .tab-list-item {
    &:first-of-type {
      margin-left: 0;
    }
  }
`

import { useMemo } from 'react'
import styled, { css } from 'styled-components'

import { useProjectContext } from '../../hooks/useProject'

import Area from './Area'
import { Template } from '../../types/template'
import ScenePreview from '../ScenePreview'
import { getHeightByTemplateRatio } from './constants'
import { Fonts } from '../../types/fonts'

type ScreenProps = {
  selectedSceneIndex: number
  isSourceProcessing: (sceneIndex: number, sourceIndex: number) => boolean
  onSourceClick(index: number): void
  isFlickering: boolean
  designInfo?: Template
}

const Screen = ({
  selectedSceneIndex,
  isSourceProcessing,
  isFlickering,
  onSourceClick,
  designInfo
}: ScreenProps) => {
  const { project, sceneList } = useProjectContext()

  const SCENE_PREVIEW_WIDTH = designInfo?.templateRatio === 2 ? 280 : 420 // 9:16은 좁은 화면

  const scene = useMemo(
    () =>
      sceneList && {
        ...sceneList[selectedSceneIndex],
        source:
          sceneList[selectedSceneIndex]?.source?.map(
            (item, sourceIndex: number) => ({
              ...item,
              isLoading: isSourceProcessing(selectedSceneIndex, sourceIndex)
            })
          ) || []
      },
    [isSourceProcessing, sceneList, selectedSceneIndex]
  )

  const sources = useMemo(() => {
    return (scene?.source || [])
      .map((item, index) => {
        return { ...item, index }
      })
      .sort(({ sourceZindex: a = -1 }, { sourceZindex: b = -1 }) => {
        return a < b ? -1 : 1
      })
  }, [scene])

  const scenePreviewHeight = getHeightByTemplateRatio(
    SCENE_PREVIEW_WIDTH,
    designInfo?.templateRatio
  )

  const currentFontsList: Array<Fonts | null> = useMemo(() => {
    let startIndex = 0
    let endIndex = 0
    const defaultList =
      project?.sources?.reduce((acc: null[], source, idx) => {
        if (idx < selectedSceneIndex) startIndex += source?.length
        if (idx === selectedSceneIndex) endIndex += startIndex + source?.length
        const result = source?.map((_) => null)
        return acc.concat(...result)
      }, []) || []
    const allList =
      ((project?.changedFonts || [])?.length > 0 && project?.changedFonts) ||
      defaultList
    return allList.slice(startIndex, endIndex)
  }, [project, selectedSceneIndex])

  return (
    <>
      <Wrapper>
        <ScreenContainer>
          <svg viewBox='0 0 1280 720' className='source-image'>
            <image
              href={scene?.sceneImage}
              width='100%'
              height='100%'
              style={{ opacity: 0.8 }}
            />
            {sources?.map(
              ({ sourceArea, sourceType, index, isLoading }) =>
                ['I', 'V', 'T'].includes(`${sourceType}`.toUpperCase()) && (
                  <Area
                    key={sourceType + index}
                    index={index}
                    isLoading={isLoading}
                    points={sourceArea}
                    type={sourceType}
                    data={project?.sources?.[selectedSceneIndex]?.[index]}
                    currentFont={currentFontsList?.[index]}
                    onClick={() => onSourceClick && onSourceClick(index)}
                    isFlickering={isFlickering}
                  />
                )
            )}
          </svg>
          {/* preview vedio */}
          <svg
            viewBox={`0 0 ${SCENE_PREVIEW_WIDTH} 816`}
            className='scene-preview'
            style={{ maxWidth: SCENE_PREVIEW_WIDTH }}
          >
            <foreignObject width='100%' height='100%'>
              <ScenePreview
                designInfo={designInfo}
                selectedSceneIndex={selectedSceneIndex}
                scenePreviewHeight={scenePreviewHeight}
              />
            </foreignObject>
          </svg>
        </ScreenContainer>
      </Wrapper>
    </>
  )
}

export default Screen

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 1800px;
  margin: 0 auto;
  padding: 0 24px;
`

const ScreenContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  .scene-preview {
    height: 100%;
  }
  .source-image {
    aspect-ratio: 16 / 9;
    height: 100%;
  }
  ${({ theme }) =>
    theme.breakpoints.xxLarge(css`
      .scene-preview {
        max-width: 280px;
        height: 100%;
      }
    `)};
  ${({ theme }) =>
    theme.breakpoints.xLarge(css`
      .scene-preview {
        display: none;
      }
    `)};
`

import { IconBlueChecked, IconCaution } from '../../assets/icons'
import { reqFontLang } from '../../types/fonts'

interface ConvertProps {
  url: string
  type: 'image' | 'video'
}

export const convertURLtoFile = async ({ url, type }: ConvertProps) => {
  const response = await fetch(url)
  const data = await response.blob()
  const ext = url.split('.').pop() // url 구조에 맞게 수정할 것
  const filename = url.split('/').pop() // url 구조에 맞게 수정할 것
  const metadata = { type: `${type}/${ext}` }
  const file = new File([data], filename!, metadata)
  return file
}

export const getDuration = (file: File): Promise<number> =>
  new Promise((resolve, reject) => {
    try {
      const video = document.createElement('video')
      video.preload = 'metadata'
      video.src = URL.createObjectURL(file)

      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src)
        resolve(video.duration)
      }

      video.onerror = (error) => reject(error)
    } catch (error) {
      reject(error)
    }
  })

export function detectLanguage(text: string) {
  let lang: reqFontLang = 'en'

  // 정규식 정의
  const regexKorean = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/ // 한글 범위
  const regexVietnamese =
    // eslint-disable-next-line no-misleading-character-class
    /[\u0080-\u00FF\u0100-\u024F\u1E00-\u1EFF\u0300-\u036F]/ // 베트남어 범위
  const regexEnglish = /^[a-zA-Z]+$/ // 영어 범위

  if (regexKorean.test(text)) {
    lang = 'ko' // 한국어
  } else if (regexVietnamese.test(text)) {
    lang = 'vi' // 베트남어
  } else if (regexEnglish.test(text)) {
    lang = 'en' // 영어
  }

  return lang
}

export const getTextInfo = (size = 18) => ({
  valid: {
    icon: <IconBlueChecked width={size} height={size} />,
    text: 'TEXT_INPUT_GOOD',
    background: '#F6F9FF',
    color: '#1F76F9'
  },
  lengthValid: {
    icon: <IconCaution width={size} height={size} />,
    text: 'TEXT_INPUT_WARN',
    background: '#FFF6F6',
    color: '#EE2F2E'
  },
  fontValid: {
    icon: <IconCaution width={size} height={size} />,
    text: 'TEXT_INPUT_FONT_BAD',
    background: '#FFF6F6',
    color: '#EE2F2E'
  }
})

const accepts = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/webp',
  'video/mp4'
]

const MAX_FILE_SIZE = {
  image: 20 * 1024 * 1024,
  video: 500 * 1024 * 1024
} as const

/** 파일 크기, 확장자 체크 */
export const checkFiles = (files: File[]) => {
  const filteredFiles: any = []
  const errorMessages: string[] = []

  if (!files) return { filteredFiles, errorMessages }
  Array.from(files).forEach((file) => {
    const fileType = file.type.split('/')[0] as keyof typeof MAX_FILE_SIZE

    if (!accepts.includes(file.type)) {
      errorMessages.push('AI_FILE_UPLOAD_ERROR2')
      return
    }
    if (file.size > MAX_FILE_SIZE[fileType]) {
      errorMessages.push('AI_FILE_UPLOAD_ERROR1')
      return
    }
    filteredFiles.push(file)
  })

  return {
    filteredFiles,
    errorMessages
  }
}

const reqLangItem: { id: reqFontLang; name: string }[] = [
  {
    id: 'ko',
    name: 'text_0392'
  },
  {
    id: 'en',
    name: 'text_0393'
  },
  {
    id: 'vi',
    name: 'text_0394'
  }
]

const getItemByLang = () => {
  const isKr = localStorage.getItem('locale')?.includes('ko') ?? false
  return isKr ? reqLangItem : [...reqLangItem.splice(1), reqLangItem[0]]
}

export const fontReqLang = getItemByLang()

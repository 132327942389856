import { Dispatch, MouseEvent, SetStateAction } from 'react'
import styled, { css } from 'styled-components'
import useFormatText from '../../hooks/useFormatText'
import { Orientation } from '../../Editor/EditModals/FreeImage'
import { IconPhotoLandscape, IconPhotoPortrait } from '../../assets/icons'

interface PhotoFilterProps {
  orientation: Orientation
  setOrientation: Dispatch<SetStateAction<Orientation>>
}

const PhotoFilter = ({ orientation, setOrientation }: PhotoFilterProps) => {
  const handleClickMenu = (e: MouseEvent<HTMLButtonElement>) => {
    const targetLi = e.currentTarget as HTMLButtonElement
    setOrientation(targetLi.dataset.orientation as Orientation)
  }

  const IMAGE_INPUT_FROM_FREESTOCK_FILTER_B = useFormatText(
    'IMAGE_INPUT_FROM_FREESTOCK_FILTER_B'
  )
  const IMAGE_INPUT_FROM_FREESTOCK_FILTER_C = useFormatText(
    'IMAGE_INPUT_FROM_FREESTOCK_FILTER_C'
  )
  const IMAGE_INPUT_FROM_FREESTOCK_FILTER_A = useFormatText(
    'IMAGE_INPUT_FROM_FREESTOCK_FILTER_A'
  )

  return (
    <Wrapper>
      <Button
        onClick={handleClickMenu}
        data-orientation={''}
        isSelected={orientation === ''}
        tabIndex={0}
      >
        {IMAGE_INPUT_FROM_FREESTOCK_FILTER_A}
      </Button>
      <Button
        onClick={handleClickMenu}
        data-orientation={'landscape'}
        isSelected={orientation === 'landscape'}
        tabIndex={1}
      >
        <IconPhotoLandscape />
        {IMAGE_INPUT_FROM_FREESTOCK_FILTER_B}
      </Button>
      <Button
        onClick={handleClickMenu}
        data-orientation={'portrait'}
        isSelected={orientation === 'portrait'}
        tabIndex={2}
      >
        <IconPhotoPortrait />
        {IMAGE_INPUT_FROM_FREESTOCK_FILTER_C}
      </Button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 16px 0;
`

export const Button = styled.button<{ isSelected: boolean }>`
  display: inline-flex;
  gap: 4px;
  padding: 9px 12px;
  border-radius: 24px;
  border: none;
  outline: none;
  background: ${({ theme }) => theme.colors.gray[10]};
  color: ${({ theme }) => theme.colors.gray[500]};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 14px */
  cursor: pointer;

  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      background: ${theme.colors.brand.primary[30]};
      color: ${theme.colors.brand.primary[400]};
      font-weight: 500;

      &&& {
        box-shadow: 0 0 0 1px ${theme.colors.brand.primary[400]} inset;
      }
    `};

  svg {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
  }
`

export default PhotoFilter

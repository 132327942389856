import styled from 'styled-components'
import iconZoomIn from '../../assets/icons/icon_zoom_in.svg'
import iconZoomOut from '../../assets/icons/icon_zoom_out.svg'

interface ZoomButtonsProps {
  zoom: number
  onClickZoomIn: () => void
  onClickZoomOut: () => void
}

const ZoomButtons = ({
  zoom,
  onClickZoomIn,
  onClickZoomOut
}: ZoomButtonsProps) => {
  const zoomPercentage = Math.round(zoom * 100)

  return (
    <ButtonWrapper>
      <StyledButton icon={iconZoomOut} onClick={onClickZoomOut}></StyledButton>
      <span>{zoomPercentage}%</span>
      <StyledButton icon={iconZoomIn} onClick={onClickZoomIn}></StyledButton>
    </ButtonWrapper>
  )
}

export default ZoomButtons

const ButtonWrapper = styled.div`
  position: absolute;
  right: 16px;
  bottom: 13px;
  display: flex;
  align-items: center;
  gap: 8px;
  background: ${({ theme }) => theme.colors.white};
  padding: 6px 12px;
  border-radius: 24px;
  color: ${({ theme }) => theme.colors.gray[700]};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  & > span {
    width: 30px;
    text-align: center;
  }
`

const StyledButton = styled.button`
  width: 20px;
  height: 20px;
  background: ${(props: { icon: string }) =>
    `url(${props.icon}) no-repeat center`};
  border: none;
  cursor: pointer;
`

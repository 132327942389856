import { Template } from '../../types/template'
import useToggle from '../../hooks/useToggle'
import styled from 'styled-components'
import useFormatText from '../../hooks/useFormatText'
import { IconInfoLine } from '../../assets/icons'
import theme from '../../styles/theme'
import { useCallback, useEffect, useMemo, useRef } from 'react'

interface ScenePreviewProps {
  designInfo?: Template
  selectedSceneIndex: number
  scenePreviewHeight?: number
}

const ScenePreview = ({
  designInfo,
  selectedSceneIndex,
  scenePreviewHeight
}: ScenePreviewProps) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const { isToggled: isMouseOver, toggle: toggleIsMouseOver } = useToggle(false)
  const selectSceen = useMemo(
    () =>
      designInfo?.previewFrame[selectedSceneIndex] ||
      designInfo?.videoDuration ||
      0,
    [designInfo?.previewFrame, designInfo?.videoDuration, selectedSceneIndex]
  )
  const startSceen = useMemo(() => {
    const prev = designInfo?.previewFrame[selectedSceneIndex - 1] || 0
    const curr =
      designInfo?.previewFrame[selectedSceneIndex] ||
      designInfo?.videoDuration ||
      0
    return prev ? curr - (curr - prev) / 2 : 0
  }, [designInfo?.previewFrame, designInfo?.videoDuration, selectedSceneIndex])

  const endSceen = useMemo(() => {
    const curr =
      designInfo?.previewFrame[selectedSceneIndex] ||
      designInfo?.videoDuration ||
      0
    const next =
      designInfo?.previewFrame[selectedSceneIndex + 1] ||
      designInfo?.videoDuration ||
      0
    return next === designInfo?.videoDuration ? next : curr + (next - curr) / 2
  }, [designInfo?.previewFrame, designInfo?.videoDuration, selectedSceneIndex])

  useEffect(() => {
    const observedVideoElement = videoRef && videoRef?.current
    if (observedVideoElement) {
      if (isMouseOver) {
        observedVideoElement.currentTime = startSceen
        const playPromise = observedVideoElement.play()
        if (playPromise !== undefined) {
          // error : Uncaught (in promise) DOMException: The play() request was interrupted by a call to pause().
          playPromise.then((_) => {}).catch((_) => {})
        }
      } else {
        observedVideoElement.pause()
        observedVideoElement.currentTime = selectSceen
      }
    }
  }, [isMouseOver, selectSceen, startSceen])

  const handleVideoTimeUpdate = useCallback(() => {
    try {
      const video = videoRef && videoRef?.current
      if (video) {
        if (video.currentTime >= endSceen || video.currentTime < startSceen) {
          video.currentTime = startSceen
        }
      }
    } catch (error) {
      console.log(error)
    }
  }, [startSceen, endSceen])

  useEffect(() => {
    const observedVideoElement = videoRef && videoRef?.current
    if (!observedVideoElement) return
    observedVideoElement.addEventListener('timeupdate', handleVideoTimeUpdate)
    return () => {
      observedVideoElement.removeEventListener(
        'timeupdate',
        handleVideoTimeUpdate
      )
    }
  }, [handleVideoTimeUpdate])

  return (
    <Container>
      <ScenePreviewInfo>
        <IconInfoLine className='info-icon' />
        {useFormatText('SCENE_PREVIEW_INFO')}
      </ScenePreviewInfo>
      <SceneNumber># {selectedSceneIndex + 1}</SceneNumber>
      <video
        ref={videoRef}
        src={designInfo?.temPreviewVideo + `?t=${selectSceen}`}
        preload='metadata'
        muted
        loop
        onMouseOver={toggleIsMouseOver}
        onMouseLeave={toggleIsMouseOver}
        width='100%'
        height={scenePreviewHeight}
      />
    </Container>
  )
}

export default ScenePreview

const Container = styled.div<Pick<ScenePreviewProps, 'scenePreviewHeight'>>`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  gap: 8px;
`

const SceneNumber = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  padding: 4px 5px;
  border-radius: 4px;
  background: rgba(17, 17, 17, 0.8);
  color: ${theme.colors.white};
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 10px */
`

const ScenePreviewInfo = styled.div`
  display: flex;
  gap: 6px;
  color: ${theme.colors.gray[400]};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  user-select: none;

  .info-icon path {
    fill: ${theme.colors.gray[400]};
  }
`

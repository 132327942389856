import styled, { css } from 'styled-components'
import { memo } from 'react'
import { IconBlueCheckedCircle } from '../../assets/icons'
import SceneNumber from './SceneNumber'

type SceneItemProps = {
  completed?: boolean
  selected?: boolean
  onClick?(): void
  index?: number
  thumbnailSrc?: string
}

const SceneItem = (props: SceneItemProps) => {
  const { index = 0, selected, completed, onClick, thumbnailSrc } = props

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: 4
      }}
    >
      <Wrapper
        selected={selected}
        completed={completed}
        data-testid='scene-image'
        onClick={onClick}
        thumbnailSrc={thumbnailSrc || ''}
      >
        <SceneNumber index={index} />
        {completed && <IconCheck />}
      </Wrapper>
    </div>
  )
}

export default memo(SceneItem)

const Wrapper = styled.button<SceneItemProps>`
  position: relative;
  display: inline-block;
  width: 142px;
  height: 76px;
  padding: 0;
  margin: 0;
  background-image: ${({ thumbnailSrc }) => `url('${thumbnailSrc}')`};
  background-color: ${({ theme }) => theme.colors.white};
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;

  ${({ selected }) =>
    !selected
      ? css`
          &::after {
            content: '';
            position: absolute;
            inset: 0;
            background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.3) 0%,
              rgba(0, 0, 0, 0.3) 100%
            );
          }
        `
      : css`
          &&& {
            box-shadow: 0 0 0 2px#F20E04 inset;
          }
        `};

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      height: 80px;
    `)};

  .scene-number {
    position: absolute;
    left: 8px;
    top: 8px;
  }
`

Wrapper.defaultProps = {
  type: 'button'
}

const IconCheck = styled(IconBlueCheckedCircle)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
`

import styled from 'styled-components'
import {
  IconBlackChecked,
  iconStarGray,
  iconStarYellow
} from '../../assets/icons'

interface FontAccordionItemProps {
  isSelected: boolean
  isBookMark?: boolean
  handleSelectedFont: () => void
  handleClickBookMark: () => void
  showBookMark: boolean
  children: React.ReactNode
}

const FontItem = ({
  isSelected,
  isBookMark,
  handleSelectedFont,
  handleClickBookMark,
  showBookMark,
  children
}: FontAccordionItemProps) => {
  return (
    <SelectItem onClick={() => handleSelectedFont()}>
      {isSelected && <Checked />}
      {children}
      {!!showBookMark && (
        <BookMarkButton
          isBookMark={!!isBookMark}
          onClick={(e) => {
            e.stopPropagation()
            handleClickBookMark()
          }}
        />
      )}
    </SelectItem>
  )
}

export default FontItem

const BookMarkButton = styled.button<{ isBookMark: boolean }>`
  width: 20px;
  height: 20px;
  margin: 4px;
  visibility: ${({ isBookMark }) => (isBookMark ? 'visible' : 'hidden')};
  border: none;
  padding: 0;
  background-color: transparent;
  background-image: ${({ isBookMark }) =>
    `url(${isBookMark ? iconStarYellow : iconStarGray})`};
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
`

const SelectItem = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 8px 12px 8px 30px;
  border-radius: 2px;
  color: #1a1a1a;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  &:hover {
    background-color: #f5f5f5;

    ${BookMarkButton} {
      visibility: visible;
    }
  }
`

const Checked = styled(IconBlackChecked)`
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
`

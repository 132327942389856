import styled, { css } from 'styled-components'
import Container from '../../components/Container'

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.backgroundEditor};
`

const HEADER_HEIGHT_PC = 60
const HEADER_HEIGHT_MOBILE = 50
const SIDEBAR_HEIGHT = 64

export const Layout = styled.div`
  width: 100%;
  height: calc(100vh - ${HEADER_HEIGHT_PC}px);
  height: calc(100svh - ${HEADER_HEIGHT_PC}px);
  padding-top: 30px;
  overflow-y: auto;
  overflow-x: hidden;

  ${({ theme }) =>
    theme.breakpoints.large(css`
      height: calc(100vh - ${HEADER_HEIGHT_PC}px- ${SIDEBAR_HEIGHT}px);
      height: calc(100svh - ${HEADER_HEIGHT_PC}px - ${SIDEBAR_HEIGHT}px);
      padding-top: 56px;
    `)};

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      height: calc(100vh - ${HEADER_HEIGHT_MOBILE}px - ${SIDEBAR_HEIGHT}px);
      height: calc(100svh - ${HEADER_HEIGHT_MOBILE}px - ${SIDEBAR_HEIGHT}px);
    `)};
`

const CLOSED_SIDEBAR_WIDTH = 64
const OPENED_SIDEBAR_WIDTH = 420 + CLOSED_SIDEBAR_WIDTH

export const Content = styled.div<{ $isOpen: boolean }>`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex: 1;
  align-items: top;
  justify-content: center;
  transition: all 0.4s ease-in-out;

  ${({ theme }) =>
    theme.breakpoints.small(css`
      @supports (-webkit-touch-callout: none) {
        -webkit-overflow-scrolling: touch;
        overflow: scroll;
      }
    `)};

  ${({ $isOpen }) =>
    $isOpen
      ? css`
          margin-left: ${OPENED_SIDEBAR_WIDTH / 2}px;
        `
      : css`
          margin-left: ${CLOSED_SIDEBAR_WIDTH / 2}px;
        `}

  ${({ theme }) =>
    theme.breakpoints.large(
      css`
        margin-left: 0;
      `
    )}
`

export const MainContainer = styled(Container)<{ $isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  transition: all 0.4s ease-in-out 0s;

  ${({ $isOpen }) =>
    $isOpen
      ? css`
          width: calc(100% - ${OPENED_SIDEBAR_WIDTH}px);
        `
      : css`
          width: calc(100% - ${CLOSED_SIDEBAR_WIDTH}px);
        `};

  ${({ theme }) =>
    theme.breakpoints.large(
      css`
        width: 100%;
      `
    )}
`

export const CenterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ScreenWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 220px);
`

export const BottomContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 24px 24px 0;

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      border-top: 1px solid ${({ theme }) => theme.colors.border.primary};
      padding: 20px 16px 0;
    `)};
`

import styled, { css } from 'styled-components'
import theme from '../../styles/theme'
import DefaultButton from '../DefaultButton'

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  .title {
    color: ${theme.colors.gray[700]};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }
`

export const UploadButton = styled(DefaultButton)`
  position: relative;
  display: flex;
  width: 100%;
  height: 44px;
  justify-content: center;
  align-items: center;
  gap: 130px;
  flex-shrink: 0;
  border-radius: 24px;
  background-color: ${theme.colors.activeBg};
  font-size: 16px;
  overflow: hidden;

  &,
  &:hover {
    border: none;
    color: ${theme.colors.brand.primary[400]};
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 48px;
    transform: translateY(-50%);
    width: 1px;
    height: 21px;
    background-color: ${theme.colors.primary};
    opacity: 0.3;
  }

  img {
    width: 48px;
    height: 44px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
    padding: 10px 12px;
    cursor: pointer;
  }
`

export const PreviewWrapper = styled.div`
  width: 100%;

  .empty {
    width: 100%;
    text-align: center;
    color: ${theme.colors.text.secondary};
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    line-height: 140%;
    margin-top: 83px;
    margin-bottom: 95px;

    ${({ theme }) =>
      theme.breakpoints.medium(css`
        margin-bottom: 93px;
      `)}
  }
`

export const Container = styled.div`
  min-height: 300px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`

export const FilePreview = styled.div<{ isEmpty: boolean }>`
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 18.2px */
  margin-top: 6px;
  margin-bottom: 24px;

  img,
  video {
    max-width: 242px;
    max-height: 100%;
    border-radius: 4px;
  }
`

export const ListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  .container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      padding: 7px 12px;
      border-color: ${theme.colors.gray[100]};

      span {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        color: ${theme.colors.gray[500]};
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 14px */

        svg {
          width: 18px;
          height: 18px;
          flex-shrink: 0;

          path {
            fill: ${theme.colors.gray[500]};
          }
        }
      }
    }
  }

  .checkbox {
    width: 16px;
    height: 16px;
  }

  .check-info {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    margin-right: 12px;
    cursor: pointer;
    user-select: none;

    .check-count {
      color: ${theme.colors.primary};
      font-size: 16px;
      font-weight: 600;
    }
  }

  .list-info {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .count {
    color: ${theme.colors.gray[400]};
    font-size: 14px;
    font-weight: 500;
    line-height: 100%;
  }

  .list {
    width: 100%;
    min-height: 200px;
    margin-top: 8px;
    flex: 1;
    position: relative;

    .scroller {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      overflow-y: auto;
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      align-content: baseline;

      .item {
        position: relative;
        width: calc(50% - 6px);
        height: fit-content;
      }

      ${theme.breakpoints.medium(css`
        position: absolute;
        top: 0;
        left: 0;
      `)};
    }
  }
`

export const itemStyle = css`
  width: auto;

  & > div {
    height: 102px;
  }
`

export const LoadingWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
`

export const SkeletonItem = styled.div`
  display: flex;
  width: calc(50% - 6px);
  max-height: 126px;
  aspect-ratio: 16 / 9;

  & > div {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 4px;

    .ant-skeleton-image {
      width: 100%;
      height: 100%;
    }
  }
`

import { useEffect } from 'react'
import FontItem from './FontItem'
import { Fonts } from '../../types/fonts'
import useFormatText from '../../hooks/useFormatText'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'

interface FontListProps {
  fonts: (Fonts | null)[]
  selectedFont: Fonts | null
  handleSelectFont: (font: Fonts | null) => void
  fetchNextPage: () => void
  hasNext: boolean
  isFetching: boolean
  bookmarkedFonts: Fonts[]
  handleClickBookMark: (value: Fonts | null) => void
}

const FontList = ({
  fonts,
  selectedFont,
  bookmarkedFonts,
  hasNext,
  isFetching,
  fetchNextPage,
  handleSelectFont,
  handleClickBookMark
}: FontListProps) => {
  const isKr = localStorage.getItem('locale')?.includes('ko') ?? false

  const { ref: observeRef, inView } = useInView({
    threshold: 0,
    triggerOnce: true
  })

  useEffect(() => {
    inView && fetchNextPage()
  }, [inView, hasNext])

  return (
    <div style={{ maxHeight: 240, overflowY: 'auto' }}>
      {fonts.map((item) => {
        return (
          <FontItem
            key={item?._id || 0}
            isSelected={selectedFont?._id === item?._id}
            isBookMark={
              !!item && !!bookmarkedFonts.find((font) => font._id === item._id)
            }
            handleSelectedFont={() => handleSelectFont(item)}
            handleClickBookMark={() => handleClickBookMark(item)}
            showBookMark={!!item}
          >
            {item ? (
              <img
                src={
                  process.env.REACT_APP_FRONT_FILE_API_URL +
                  item.nameAssets[isKr ? 'ko' : 'en']
                }
                style={{ objectFit: 'contain' }}
              />
            ) : (
              <DefaultFont>{useFormatText('text_0391')}</DefaultFont>
            )}
          </FontItem>
        )
      })}
      {!isFetching && hasNext && (
        <div style={{ width: '100%', height: '8px' }} ref={observeRef} />
      )}
    </div>
  )
}

export default FontList

const DefaultFont = styled.p`
  width: 100%;
  height: 32px;
  line-height: 32px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray[800]};
`

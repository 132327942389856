import styled, { css } from 'styled-components'
import theme from '../../styles/theme'

const FilterChip = styled.button<{ isSelected: boolean }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  padding: 8px 12px;
  border-radius: 24px;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  background-color: ${theme.colors.background.secondary};
  color: #858585;
  cursor: pointer;

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${theme.colors.background.active};
      color: ${theme.colors.primary};
      border: 1px solid ${theme.colors.primary};
      scroll-snap-align: center;
    `};

  .mobile & {
    padding: 9px 12px;
  }
`
export default FilterChip

import {
  iconContentPhoto,
  iconContentVideo,
  iconContentText,
  iconPhotoCompletion,
  iconVideoCompletion,
  iconTextCompletion,
  iconPhotoIncompletion,
  iconVideoIncompletion,
  iconTextIncompletion
} from '../../assets/icons'

/** 디자인 비율: {['1:1', '9:16', '16:9', '4:5'][templateRatio - 1]} */
export const getHeightByTemplateRatio = (
  width: number,
  templateRatio?: number
) => {
  if (!templateRatio) return

  switch (templateRatio - 1) {
    case 0:
    default:
      return width
    case 1:
      return (width / 9) * 16
    case 2:
      return (width / 16) * 9
    case 3:
      return (width / 4) * 5
  }
}

const PHOTO = 'I'
const VIDEO = 'V'
const TEXT = 'T'

export type AreaType = typeof PHOTO | typeof VIDEO | typeof TEXT | string

export const areaType = {
  TEXT,
  PHOTO,
  VIDEO
}

export const getTestId = (type: AreaType) => {
  switch (type) {
    case PHOTO:
      return 'image-area'
    case VIDEO:
      return 'video-area'
    case TEXT:
      return 'text-area'
    default:
      return ''
  }
}

export const areaIcons = {
  photo: {
    default: iconPhotoIncompletion,
    active: iconContentPhoto,
    completed: iconPhotoCompletion
  },
  video: {
    default: iconVideoIncompletion,
    active: iconContentVideo,
    completed: iconVideoCompletion
  },
  text: {
    default: iconTextIncompletion,
    active: iconContentText,
    completed: iconTextCompletion
  }
}

const getCenterOfPolygonFromPoints = (_points: [number, number][]) => {
  try {
    const arrayX = _points.map((point) => point[0])
    const arrayY = _points.map((point) => point[1])
    const minX = Math.min(...arrayX)
    const maxX = Math.max(...arrayX)
    const minY = Math.min(...arrayY)
    const maxY = Math.max(...arrayY)
    return [minX + (maxX - minX) / 2, minY + (maxY - minY) / 2]
  } catch (error) {
    return [0, 0]
  }
}

export type PointType = [number, number]

export const getRelativePoints = (points: PointType[]) => {
  return JSON.parse(JSON.stringify(points)).map((point: number[]) => {
    point[0] -= points[0][0]
    point[1] -= points[0][1]
    return point
  })
}

export const getRelativeThumbnailPath = (relativePoints: PointType[]) => {
  return {
    x: Math.min(relativePoints[0][0], relativePoints[3][0]),
    y: Math.min(relativePoints[0][1], relativePoints[2][0]),
    width:
      Math.floor(Math.min(relativePoints[0][0], relativePoints[3][0])) +
      Math.max(relativePoints[1][0], relativePoints[2][0]),
    height:
      Math.floor(Math.min(relativePoints[0][1], relativePoints[1][1])) +
      Math.max(relativePoints[3][1], relativePoints[2][1])
  }
}

export const getRelativeCenterPath = (
  points: PointType[],
  firstPoint: PointType
) => {
  return {
    x: getCenterOfPolygonFromPoints(points)[0] - firstPoint[0],
    y: getCenterOfPolygonFromPoints(points)[1] - firstPoint[1]
  }
}

export const getRelativeIconPosition = (
  hasData: boolean,
  type: AreaType,
  relativePoints: PointType[],
  relativeCenterPath: { x: number; y: number },
  iconWidth: number
) => {
  if (!hasData) {
    return {
      x: relativeCenterPath.x - iconWidth / 2,
      y: relativeCenterPath.y - iconWidth / 2
    }
  }
  if (type === areaType.TEXT) {
    return {
      x: relativePoints[1][0] - iconWidth - 4,
      y: relativePoints[1][1] - iconWidth / 2
    }
  }
  return {
    x: relativePoints[2][0] - iconWidth - 12,
    y: relativePoints[2][1] - iconWidth - 12
  }
}

export const getFontFormatByUrl = (fontFaceUrl: string) => {
  const last = fontFaceUrl?.split('.')
  const format = last?.[(last?.length || 0) - 1]
    ?.toUpperCase()
    ?.replace('OTF', 'opentype')
    ?.replace('TTF', 'truetype')
  return format
}

export const getFontSize = (
  innerText: string,
  fontStyle: Record<string, string> | null,
  maxWidth: number,
  maxHeight: number
) => {
  let tempFontSize = 16

  const tempSpan = document.createElement('span')
  if (fontStyle) {
    tempSpan.style.fontFamily = fontStyle?.fontFamily
    tempSpan.style.fontWeight = fontStyle?.fontWeight
    tempSpan.style.fontStyle = fontStyle?.fontStyle
    tempSpan.style.lineHeight = '100%'
  }
  tempSpan.style.fontSize = tempFontSize + 'px'
  tempSpan.style.visibility = 'hidden'
  tempSpan.innerText = innerText

  document.body.appendChild(tempSpan)
  // 폰트 크기를 조정하여 요소 내의 텍스트가 polygon 크기에 맞게 설정
  while (tempSpan.offsetWidth > maxWidth || tempSpan.offsetHeight > maxHeight) {
    tempFontSize -= 1
    tempSpan.style.fontSize = tempFontSize + 'px'
  }

  while (tempSpan.offsetWidth < maxWidth && tempSpan.offsetHeight < maxHeight) {
    tempFontSize += 1
    tempSpan.style.fontSize = tempFontSize + 'px'
  }

  document.body.removeChild(tempSpan)
  return tempFontSize
}

import styled from 'styled-components'
import useFormatText from '../../hooks/useFormatText'
import { getCount, getUserSourceCount, screenSourceCounts } from './constants'
import ProgressBar from '../ProgressBar'
import { useProjectContext } from '../../hooks/useProject'
import SceneNumber from '../SceneList/SceneNumber'
import { css } from 'styled-components'

interface SceneProgressProps {
  selectedSceneIndex: number
}

const SceneProgress = ({ selectedSceneIndex }: SceneProgressProps) => {
  const { project, sceneList } = useProjectContext()
  const sceneSources = project?.sources?.[selectedSceneIndex].map(
    (_item, idx) => {
      return sceneList?.[selectedSceneIndex]?.source?.[idx]
    }
  )
  const userSources = project?.sources?.[selectedSceneIndex].map(
    (item, idx) => {
      return {
        value: item,
        sourceType: sceneSources && sceneSources[idx]?.sourceType
      }
    }
  )

  return (
    <Container>
      <InfoWrap>
        <Info>
          <SceneNumber index={selectedSceneIndex + 1} />
          <span className='title'>{useFormatText('SCENE_COMPONENTS')}</span>
        </Info>
        <ComponentsContainer>
          {screenSourceCounts.map(({ icon, type }) => {
            return (
              <div key={type} className='components'>
                {icon}
                <span className='item'>
                  {getUserSourceCount(userSources, type)} /{' '}
                  {getCount(sceneSources, type)}
                </span>
              </div>
            )
          })}
        </ComponentsContainer>
      </InfoWrap>
      <ProgressBar />
    </Container>
  )
}

export default SceneProgress

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 24px;
  margin-top: 20px;
  border-top: 1px solid ${({ theme }) => theme.colors.border.primary};

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      padding: 16px 0;
    `)}

  ${({ theme }) =>
    theme.breakpoints.small(css`
      padding: 16px 0 12px;
      margin-top: 16px;
    `)}
`
const InfoWrap = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  color: ${({ theme }) => theme.colors.gray[600]};
  font-size: 14px;
  font-weight: 600;
  line-height: 140%; /* 19.6px */

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      gap: 6px;
    `)}
`

const Info = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const ComponentsContainer = styled.div`
  display: flex;
  align-items: center;

  .components {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-right: 12px;

    &:last-child {
      margin-right: 0;
    }
  }

  .item {
    color: ${({ theme }) => theme.colors.gray[700]};
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
  }

  & > svg {
    margin-right: 4px;
  }
`

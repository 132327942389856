import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  margin: 0 auto;
`

export default Container

import { useInfiniteQuery } from 'react-query'
import { service } from '../../utils/api'
import { Fonts, reqFontLang } from '../../types/fonts'
import { PageListResponse } from '../../utils/api/service'

export const fetchFonts = async (
  page: number,
  reqLang?: reqFontLang[],
  search?: string,
  limit?: number
): Promise<PageListResponse<Fonts> | undefined> => {
  const res = await service().fonts.list({
    page,
    limit: limit || 20,
    reqLang,
    search
  })
  if (res) {
    return res
  }
  return undefined
}

interface useFontsQueryProps {
  search: string
  reqLang: reqFontLang[]
  preventFetch?: boolean
}
export const useFontsQuery = ({
  search,
  reqLang,
  preventFetch
}: useFontsQueryProps) => {
  const {
    data: fontsData,
    fetchNextPage,
    refetch: refetchFonts,
    isFetching
  } = useInfiniteQuery(
    ['fonts', reqLang, search],
    async ({ pageParam = 1 }) => {
      return await fetchFonts(pageParam, reqLang, search, 40)
    },
    {
      enabled: !preventFetch,
      getNextPageParam: (currentPage, allPage) => {
        if (currentPage?.result && currentPage?.result?.length <= 0) {
          return false
        }
        return allPage.length + 1
      }
    }
  )

  const fontsList =
    (fontsData?.pages &&
      Array.isArray(fontsData.pages) &&
      fontsData.pages.length > 0 &&
      fontsData.pages.reduce(
        (acc: Fonts[], fonts) => [...acc, ...(fonts?.result || [])],
        []
      )) ||
    []

  const hasNext =
    fontsData?.pages &&
    Array.isArray(fontsData?.pages) &&
    fontsData.pages[(fontsData?.pages?.length || 0) - 1]?.hasNext

  return { fontsList, fetchNextPage, refetchFonts, isFetching, hasNext }
}

import { AxiosResponse } from 'axios'
import { Auth } from '../../components/AuthHelper'
import { Project } from '../../types/project'
import { initializeApp } from 'firebase/app'

import { getAuth, signInWithCustomToken } from 'firebase/auth'
import qs from 'query-string'
import axiosConfig from './axiosConfig'
import { Music } from '../../types/music'
import { Fonts, reqFontLang } from '../../types/fonts'

interface ProjectResponse {
  code: string
  result: Project
  isRenderable: boolean
  needCheck: boolean
  nextReset: Date
  planType: number
  renderCnt: number
  renderLimit: number
}

export interface PageListResponse<T> {
  code: string
  result: T[]
  resultTotalCount: number
  NumberPerPage: number
  currentPage: number
  pageCount: number
  pageCounter: number
  hasPrev: boolean
  hasNext: boolean
  prev?: number | null
  next?: number | null
}

function api() {
  const queryString = qs.parse(location.search.replace('?', ''))
  const { token, type: tokenType }: Auth = JSON.parse(
    window.localStorage.getItem('vplateVideoEditorAuth') || '{}'
  )
  const isFromIframe = window.parent !== window
  let accessToken = queryString?.token || token

  const defaultParams =
    accessToken && tokenType === 'userId'
      ? {
          userId: accessToken
        }
      : {}

  const axios = axiosConfig()

  const firebaseConfig = {
    apiKey: 'AIzaSyAErRvPeL_86zLFLv7Pq7VlVjzyMsn8ASA',
    authDomain: 'vpalte-1a287.firebaseapp.com',
    databaseURL: 'https://vpalte-1a287.firebaseio.com',
    projectId: 'vpalte-1a287',
    storageBucket: 'vpalte-1a287.appspot.com',
    messagingSenderId: '858627446974',
    appId: '1:858627446974:web:e7b5f48720f67abf06e6ef'
  }

  const handleProjectData = (res: AxiosResponse<ProjectResponse>): Project => {
    const projectData = res.data.result
    const renderData: Partial<ProjectResponse> = { ...res.data }
    delete renderData.code
    delete renderData.result

    return {
      ...renderData,
      ...projectData
    }
  }

  // NOTE: firebase v9 needs auth for param
  const firebaseApp = initializeApp(firebaseConfig)
  const firebaseAuth = getAuth(firebaseApp)

  const projects = {
    async findOne(projectId: string): Promise<Project | undefined> {
      try {
        // 플러그인으로 들어왔고 토큰이 없을 때엔 ai api, 아닐 경우 vplate api => 렌더 횟수 제한 때문
        const res = await axios.get<ProjectResponse>(
          `/subtemplate/${projectId}`,
          {
            baseURL:
              !isFromIframe && !accessToken
                ? `${process.env.REACT_APP_API_URL}/plugin`
                : process.env.REACT_APP_VPLATE_API_URL
          }
        )
        if (res?.data?.code === 'SUCCESS' && res?.data?.result) {
          return handleProjectData(res)
        }
        const errorMsg = (() => {
          if (res?.data?.code === 'SUBTEMPLATE_NOT_FOUND') {
            return 'SUBTEMPLATE_NOT_FOUND'
          }
          if (res?.data?.code === 'USER_ID_UNMATCHED') {
            return 'USER_ID_UNMATCHED'
          }
          return 'SUBTEMPLATE_LOAD_ERROR'
        })()
        throw errorMsg
      } catch (error) {
        console.log(error)
        if (typeof error === 'string') {
          throw new Error(error)
        }
      }
      return
    },
    async patch(
      projectId: string,
      data: {
        userSource?: (string | null)[][]
        bgm?: string | null
        bgmUrl?: string | null
        changedColor?: (string | null)[]
        changedFonts?: (Fonts | null)[]
        lastSceneType?: string | number | null
        contactNumber?: string | null
        fhd?: boolean
        title?: string
        userId?: string
      }
    ): Promise<Project | undefined> {
      const res = await axios.patch<ProjectResponse>(
        `/subtemplate/${projectId}`,
        { ...defaultParams, ...data },
        {
          baseURL:
            !isFromIframe && !accessToken
              ? `${process.env.REACT_APP_API_URL}/plugin`
              : process.env.REACT_APP_VPLATE_API_URL
        }
      )
      return handleProjectData(res)
    },

    async changeStatus(projectId: string, status: number) {
      try {
        const res = await axios.patch(
          `/subtemplate/${projectId}/status`,
          { ...defaultParams, status },
          {
            baseURL: process.env.REACT_APP_VPLATE_API_URL
          }
        )
        const result = res?.data

        if (result?.code === 'SUCCESS') {
          return true
        }
        if (result?.code === 'WAITING') {
          throw {
            code: 'WAITING',
            msg: 'RENDER_LIMIT_TITLE'
          }
        }
        throw { code: 'FAIL', msg: 'ERROR_CONFIRM_MESSAGE' }
      } catch (error) {
        if (
          error instanceof Object &&
          'response' in error &&
          error.response instanceof Object &&
          'status' in error.response &&
          error.response.status === 400
        ) {
          throw { code: 'FAIL', msg: 'RENDER_COUNT_EXCEEDED' }
        }
        throw error
      }
    },

    async checkAutoRender({ reqId = '' }) {
      try {
        const result = await axios.get(`/auto-render/${reqId}`)
        return result.data.data
      } catch (error) {
        console.log(error)
        return
      }
    },

    async postAdPoint(persona: string, projectId?: string) {
      try {
        const result = await axios.post(`watch/scenario/${projectId || ''}`, {
          // usedPoint: idx
          persona: persona
        })
        if (result.data.code === 'SUCCESS') {
          return true
        }
        throw new Error('generate scenario fail')
      } catch (error) {
        console.log(error)
        throw error
      }
    },
    async readyCompletePlace(reqId: string) {
      try {
        const result = await axios({
          method: 'patch',
          url: `/airequest/place/auto/${reqId}`,
          baseURL: process.env.REACT_APP_VPLATE_API_URL
        })
        return result.data.code
      } catch (error) {
        console.log(error)
        return
      }
    },
    async readyComplete(reqId: string) {
      try {
        const result = await axios({
          method: 'patch',
          url: `/airequest/auto/${reqId}`,
          baseURL: process.env.REACT_APP_VPLATE_API_URL
        })
        return result.data.code
      } catch (error) {
        console.log(error)
        return
      }
    },
    async removeImageBg(imageUrl: string, userId: string) {
      try {
        const result = await axios.post(
          '/rmbg',
          {
            ...defaultParams,
            imageUrl,
            userId
          },
          {
            baseURL: `${process.env.REACT_APP_API_URL}/plugin`
          }
        )

        if (result.data.code === 'SUCCESS') {
          return result.data.data
        } else {
          const getErrMsg = (errMsg: string): string => {
            switch (errMsg) {
              case 'INVALID INPUT':
                return 'REMOVE_BACKGROUND_ERROR_INVLID_INPUT'
              case 'INVALID IMAGE FORMAT':
                return 'REMOVE_BACKGROUND_ERROR_INVLID_IMAGE_FORMAT'
              case 'IMAGE PROCCESS FAILED':
              case 'INTERNAL SERVER ERROR':
                return 'REMOVE_BACKGROUND_DEFAULT_ERROR'
              default:
                return 'REMOVE_BACKGROUND_DEFAULT_ERROR'
            }
          }
          const errMsg = getErrMsg(result.data.errMsg)
          throw new Error(errMsg)
        }
      } catch (error) {
        throw new Error('REMOVE_BACKGROUND_DEFAULT_ERROR')
      }
    },
    async getAiInfoPlace(reqId: string) {
      try {
        const result = await axios.get(`/airequest/check/place/${reqId}`, {
          baseURL: process.env.REACT_APP_VPLATE_API_URL
        })
        if (result?.data?.code === 'SUCCESS') {
          const obj = {
            ...result.data.data,
            media: [...result.data.media],
            progress: { ...result.data.progress },
            totalMediaLen: result.data.totalMediaLen
          }

          return obj
        }
        throw new Error(result?.data?.errMsg)
      } catch (error) {
        throw new Error('AI_LOADING_MODAL_DEFAULT_ERROR')
      }
    },
    async getAiInfoProduct(reqId: string) {
      try {
        const result = await axios.get(`/airequest/check/${reqId}`, {
          baseURL: process.env.REACT_APP_VPLATE_API_URL
        })
        if (result?.data?.code === 'SUCCESS') {
          const obj = {
            ...result.data.data,
            media: [...result.data.media],
            progress: { ...result.data.progress },
            totalMediaLen: result.data.totalMediaLen
          }

          return obj
        }
        throw new Error(result?.data?.errMsg)
      } catch (error) {
        throw new Error('AI_LOADING_MODAL_DEFAULT_ERROR')
      }
    },
    async newDesignPlaceByAiRequestId(aiRequestId: string) {
      try {
        const res = await axios.post(
          `/aiRequest/place/new-design/${aiRequestId}`,
          { ...defaultParams },
          {
            baseURL: process.env.REACT_APP_VPLATE_API_URL
          }
        )

        if (res?.data?.code === 'SUCCESS') {
          return res?.data?.data
        }
        throw { code: 'FAIL', msg: res?.data?.message }
      } catch (error) {
        if (
          error instanceof Object &&
          'response' in error &&
          error.response instanceof Object &&
          'status' in error.response &&
          error.response.status === 400
        ) {
          throw { code: 'FAIL', msg: 'RENDER_COUNT_EXCEEDED' }
        }
        throw error
      }
    },
    async newDesignByAiRequestId(aiRequestId: string) {
      try {
        const res = await axios.post(
          `/aiRequest/new-design/${aiRequestId}`,
          { ...defaultParams },
          {
            baseURL: process.env.REACT_APP_VPLATE_API_URL
          }
        )

        if (res?.data?.code === 'SUCCESS') {
          return res?.data?.data
        }
        throw { code: 'FAIL', msg: res?.data?.message }
      } catch (error) {
        if (
          error instanceof Object &&
          'response' in error &&
          error.response instanceof Object &&
          'status' in error.response &&
          error.response.status === 400
        ) {
          throw { code: 'FAIL', msg: 'RENDER_COUNT_EXCEEDED' }
        }
        throw error
      }
    }
  }

  const music = {
    async list(data: {
      limit?: number
      page?: number
      moods?: number[]
      genres?: number[]
    }) {
      const {
        limit = 10,
        page = 1,
        moods: musicMood = [0],
        genres: musicGenre = [0]
      } = data
      const result = await axios.get<PageListResponse<Music>>('/music', {
        params: {
          limit,
          page,
          musicMood: musicMood.join(','),
          musicGenre: musicGenre.join(',')
        },
        baseURL: process.env.REACT_APP_VPLATE_API_URL
      })
      if (result?.data?.code === 'SUCCESS') {
        return result?.data
      }
      return result?.data || {}
    }
  }

  const fonts = {
    async list(data: {
      limit?: number
      page?: number
      reqLang?: reqFontLang[]
      search?: string
    }) {
      const { limit = 10, page = 1, reqLang: reqLang = [], search = '' } = data
      const result = await axios.get<PageListResponse<Fonts>>('/fonts', {
        params: {
          limit,
          page,
          reqLang: reqLang.join(','),
          search
        },
        baseURL: process.env.REACT_APP_VPLATE_API_URL
      })
      if (result?.data?.code === 'SUCCESS') {
        return result?.data
      }
      return result?.data || {}
    }
  }

  const users = {
    async login(data: {
      userEmail: string
      password: string
      channel: string
    }) {
      try {
        const { userEmail, password, channel } = data
        const signinResult = await axios({
          method: 'post',
          url: `/user/login`,
          data: {
            userEmail,
            password,
            channel
          },
          baseURL: process.env.REACT_APP_VPLATE_API_URL
        })
        const result = signinResult?.data
        accessToken = result?.accessToken
        if (result?.code === 'SUCCESS') {
          return result
        }
        const errorMessage = (() => {
          switch (result.code) {
            case 'USER_NOT_FOUND':
              return 'LOGIN_ERROR_EMAIL'
            case 'PASSWORD_COMPARE_ERROR':
              return 'LOGIN_ERROR_PASSWORD'
            case 'NETWORK_ERROR':
              return 'LOGIN_ERROR_NETWORK'
            default:
              return 'LOGIN_ERROR_DEFAULT'
          }
        })()
        throw errorMessage
      } catch (error) {
        console.log('login error', error)
        throw error
      }
    },
    async existsEmail(
      data = {
        userEmail: ''
      }
    ) {
      try {
        const result = await axios.post(`/user/existsEmail`, data, {
          baseURL: process.env.REACT_APP_VPLATE_API_URL
        })
        return result.data
      } catch (error) {
        console.log(error)
        return
      }
    },
    async signup(
      data = {
        idToken: '',
        userEmail: '',
        password: '',
        userName: '',
        userPhoneNumber: '',
        channel: '',
        inflow: null
      }
    ) {
      try {
        const result = await axios({
          method: 'post',
          url: `/user/signup`,
          data,
          baseURL: process.env.REACT_APP_VPLATE_API_URL
        })
        if (result?.data?.code === 'SUCCESS') {
          return result.data
        }
      } catch (error) {
        console.log(error)
      }
      return {}
    },
    async getReqId(projectId: string) {
      try {
        const result = await axios.get(`/ai-request/${projectId}`)
        return result.data.data
      } catch (error) {
        console.log(error)
      }
    },
    async getUserInfo() {
      try {
        const result = await axios({
          method: 'get',
          url: '/user/info',
          baseURL: process.env.REACT_APP_VPLATE_API_URL
        })
        return result?.data?.result
      } catch (error) {
        console.log(error)
        return {}
      }
    },
    async getUserLocation() {
      try {
        const result = await axios({
          method: 'get',
          url: '/user/location',
          baseURL: process.env.REACT_APP_VPLATE_API_URL
        })
        if (result.data.code === 'SUCCESS') {
          return result.data.data
        } else {
          throw new Error()
        }
      } catch (error) {
        console.error(error)
        throw new Error()
      }
    },
    async recoveryEmail({ idToken }: { idToken: string }) {
      try {
        const result = await axios({
          method: 'post',
          url: '/user/findEmail',
          data: {
            idToken
          },
          baseURL: process.env.REACT_APP_VPLATE_API_URL
        })
        if (result?.data?.code === 'SUCCESS') {
          return result.data
        }
      } catch (error) {
        console.log(error)
        return {}
      }
    },
    async changePassword(data: {
      idToken: string
      userEmail: string
      password: string
    }) {
      try {
        if (accessToken || data.idToken) {
          const result = await axios({
            method: 'patch',
            url: '/user/password',
            data,
            baseURL: process.env.REACT_APP_VPLATE_API_URL
          })

          if (result?.data?.code === 'SUCCESS') {
            return result?.data
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    async getIsHide() {
      try {
        const result = await axios({
          method: 'get',
          url: '/user/hide-until',
          baseURL: process.env.REACT_APP_VPLATE_API_URL
        })
        if (result?.data?.code === 'SUCCESS') {
          return result?.data?.data
        }
      } catch (error) {
        console.log(error)
      }
    },
    async setHide() {
      try {
        const result = await axios({
          method: 'post',
          url: '/user/hide-until',
          baseURL: process.env.REACT_APP_VPLATE_API_URL
        })
        return result?.data?.code
      } catch (error) {
        console.log(error)
      }
    },
    async getLimit() {
      try {
        if (accessToken) {
          const result = await axios({
            method: 'get',
            url: '/user/limit',
            baseURL: process.env.REACT_APP_VPLATE_API_URL
          })
          if (result?.data?.isSuccess) {
            return result?.data
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  const auth = {
    // 0: 회원가입, 1: 이메일 찾기, 2: 비밀번호 복구
    async requestPhoneVerifyCode(
      data = {
        phoneNumber: '',
        type: 0
      }
    ) {
      try {
        const { phoneNumber, type: phoneVerificationType } = data
        const result = await axios({
          method: 'post',
          url: '/user/requestPhoneVerification',
          data: {
            phoneNumber,
            phoneVerificationType
          },
          baseURL: process.env.REACT_APP_VPLATE_API_URL
        })

        return result.data
      } catch (error) {
        console.log(error)
        return false
      }
    },
    async verifyPhoneCode(data = { phoneNumber: '', verifyCode: '' }) {
      try {
        const { phoneNumber, verifyCode } = data
        const result = await axios({
          method: 'post',
          url: '/user/verifyPhoneToken',
          data: {
            phoneNumber,
            token: verifyCode
          },
          baseURL: process.env.REACT_APP_VPLATE_API_URL
        })
        if (result?.data?.code === 'SUCCESS') {
          const { idToken: customToken } = result?.data?.result || {}
          if (customToken) {
            const signInResult = await signInWithCustomToken(
              firebaseAuth,
              customToken
            )
            if (signInResult) {
              const idToken = await signInResult.user.getIdToken()
              return { idToken }
            }
            return false
          }
        }
        return false
      } catch (error) {
        console.log(error)
        return false
      }
    },
    async refreshToken(data = { refreshToken: '' }) {
      try {
        const { refreshToken } = data
        const tokenResult = await axios({
          method: 'post',
          url: '/user/token',
          data: { refreshToken },
          baseURL: process.env.REACT_APP_VPLATE_API_URL
        })
        if (tokenResult?.data?.code === 'SUCCESS') {
          return tokenResult.data
        }
        return false
      } catch (error) {
        console.log(error)
        return false
      }
    }
  }

  return { projects, music, fonts, users, auth }
}

export default api

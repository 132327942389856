import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { IconCicleLeftArrow } from '../../assets/icons'
import { useProjectContext } from '../../hooks/useProject'
import SceneItem from './SceneItem'

type SceneListProps = {
  onSceneChange?(index: number): void
  selectedSceneIndex?: number
}

const SceneList = ({ onSceneChange, selectedSceneIndex }: SceneListProps) => {
  const { sceneList } = useProjectContext()

  const [scrollLeft, setScrollLeft] = useState(0)
  const [isLastScroll, setIsLastScroll] = useState(false)

  const wrapperRef = useRef<HTMLDivElement>(null)
  const listWrapperRef = useRef<HTMLDivElement>(null)

  const handleListWheel: React.WheelEventHandler<HTMLDivElement> = (e) => {
    e.currentTarget.scrollLeft += e.deltaY * 1.5
  }

  const scrollList = (offset: number) => {
    if (!listWrapperRef.current) return

    const left = listWrapperRef.current.scrollLeft + offset
    setScrollLeft(left)

    listWrapperRef.current.scrollTo({
      left,
      behavior: 'smooth'
    })
  }

  const handleScroll = useCallback(() => {
    if (listWrapperRef.current) {
      const {
        scrollLeft: scrollLeftValue,
        scrollWidth,
        clientWidth
      } = listWrapperRef.current
      setScrollLeft(scrollLeftValue)
      setIsLastScroll(scrollWidth - scrollLeftValue === clientWidth)
    }
  }, [wrapperRef?.current?.clientWidth])

  useEffect(() => {
    listWrapperRef?.current?.addEventListener('scroll', handleScroll)

    return () => {
      listWrapperRef?.current?.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (!sceneList) return
    handleScroll()
  }, [sceneList, handleScroll])

  return (
    <Wrapper
      ref={wrapperRef}
      isShowLeftArrow={scrollLeft !== 0}
      isShowRightArrow={!isLastScroll}
    >
      {scrollLeft !== 0 && (
        <ArrowButton
          onClick={() =>
            scrollList(-((listWrapperRef.current?.offsetWidth || 0) / 8) * 6)
          }
          direction='left'
        >
          <IconCicleLeftArrow className='circle-arrow' />
        </ArrowButton>
      )}
      <ListWrapper ref={listWrapperRef} onWheel={handleListWheel}>
        {sceneList?.map(({ sceneImage, isCompleted }, index) => (
          <SceneItem
            key={sceneImage}
            index={index + 1}
            thumbnailSrc={sceneImage}
            onClick={() => onSceneChange && onSceneChange(index)}
            selected={selectedSceneIndex === index}
            completed={isCompleted}
          />
        ))}
      </ListWrapper>
      {!isLastScroll && (
        <ArrowButton
          onClick={() =>
            scrollList(((listWrapperRef.current?.offsetWidth || 0) / 8) * 6)
          }
          direction='right'
        >
          <IconCicleLeftArrow
            className='circle-arrow'
            style={{ rotate: '180deg' }}
          />
        </ArrowButton>
      )}
    </Wrapper>
  )
}

export default SceneList

const Wrapper = styled.div<{
  isShowLeftArrow: boolean
  isShowRightArrow: boolean
}>`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;

  ${({ isShowRightArrow }) =>
    isShowRightArrow &&
    css`
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: -1px;
        width: 32px;
        height: 100%;
        background: linear-gradient(
          -90deg,
          #fff 19.64%,
          rgba(255, 255, 255, 0) 100%
        );
        z-index: 1;
      }
    `};

  ${({ isShowLeftArrow }) =>
    isShowLeftArrow &&
    css`
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -1px;
        width: 32px;
        height: 100%;
        background: linear-gradient(
          90deg,
          #fff 19.64%,
          rgba(255, 255, 255, 0) 100%
        );
        z-index: 1;
      }
    `};
`

const ArrowButton = styled.button<{ direction: 'left' | 'right' }>`
  position: absolute;
  top: 50%;
  display: inline-flex;
  align-items: flex-start;
  width: 24px;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  z-index: 2;
  transform: translateY(-50%);

  svg {
    width: 24px;
    height: 24px;
  }

  ${({ direction }) =>
    direction === 'left'
      ? css`
          left: 0;
        `
      : css`
          right: 0;
        `}
`

ArrowButton.defaultProps = { type: 'button' }

const ListWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 12px;
  flex-wrap: nowrap;
  overflow-x: auto;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`

import styled, { css, keyframes } from 'styled-components'
import theme from '../../styles/theme'
import { IconChecked } from '../../assets/icons'

interface CheckboxProps {
  label?: string
  checked?: boolean
  onChange?: (checked: boolean) => void
  clickAble?: boolean
  showDefaultCheckIcon?: boolean
}

const Checkbox = ({
  label,
  checked = false,
  onChange,
  clickAble = true,
  showDefaultCheckIcon = false
}: CheckboxProps) => {
  const handleCheckboxChange = (e: React.MouseEvent) => {
    e.stopPropagation()
    const newChecked = !checked
    if (onChange) {
      onChange(newChecked)
    }
  }

  return (
    <Wrapper
      className='checkbox-wrapper'
      onClick={handleCheckboxChange}
      clickAble={clickAble}
    >
      <StyledCheckbox
        checked={checked}
        showDefaultCheckIcon={showDefaultCheckIcon}
        className={`checkbox ${checked ? 'checked' : 'not-checked'}`}
      >
        <IconChecked />
      </StyledCheckbox>
      {label}
    </Wrapper>
  )
}

export default Checkbox

const Wrapper = styled.div<{ clickAble?: boolean }>`
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  ${({ clickAble }) => !clickAble && `pointer-events: none;`};
`

const checkAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`

const StyledCheckbox = styled.div<{
  showDefaultCheckIcon: boolean
  checked?: boolean
}>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  background-color: ${({ checked }) =>
    checked ? theme.colors.brand.primary[400] : theme.colors.white};
  border-radius: 2px;
  border: 1.5px solid
    ${({ checked }) =>
      checked ? theme.colors.brand.primary[400] : theme.colors.gray[100]};
  transition: all 0.2s;
  animation: ${(props) => (props.checked ? checkAnimation : 'none')} 0.4s;

  svg {
    width: 8px;
    height: 6px;
    visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};

    ${({ showDefaultCheckIcon, checked }) =>
      showDefaultCheckIcon &&
      css`
        visibility: visible;

        path {
          fill: ${checked ? theme.colors.white : '#c2c2c2'};
        }
      `};
  }
`

import { Row } from 'antd'
import { Dispatch, SetStateAction } from 'react'
import ColorChip from './ColorChip'
import theme from '../../styles/theme'
import Checkbox from '../Checkbox'
import styled from 'styled-components'

interface ColorChipsProps {
  colors: string[]
  setColor?: Dispatch<SetStateAction<string>>
  isEditMode?: boolean
  checkList?: string[]
  handleCheckList?: (color: string) => void
}

const ColorChips = ({
  colors,
  setColor,
  isEditMode,
  checkList,
  handleCheckList
}: ColorChipsProps) => {
  const handleClckChip = (color: string) => {
    handleCheckList && handleCheckList(color)
  }

  const chips = colors.map((color) => {
    const isChecked = checkList && checkList.includes(color)

    return (
      <Wrapper key={color} onClick={() => handleClckChip(color)}>
        <ColorChip
          setColor={isEditMode ? null : setColor}
          color={color}
          chipStyle={{
            borderWidth: isEditMode && isChecked ? 1.5 : 1,
            borderColor: isEditMode && isChecked && theme.colors.primary
          }}
        />
        {isEditMode && <Checkbox checked={isChecked} />}
      </Wrapper>
    )
  })

  return <Row style={{ gap: 16 }}>{chips}</Row>
}

export default ColorChips

const Wrapper = styled.div`
  position: relative;

  .checkbox-wrapper {
    position: absolute;
    top: 4px;
    left: 4px;

    .checkbox {
      width: 14px;
      height: 14px;

      &.not-checked {
        border: 2px solid ${({ theme }) => theme.colors.gray[200]};
      }
    }

    svg {
      width: 9px;
      height: 8px;
    }
  }
`

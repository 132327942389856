import styled, { css } from 'styled-components'
import Button from '../Button'
import theme from '../../styles/theme'

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 16px 24px 0;

  .tab-content {
    height: calc(100% - 52px);
    padding: 0;
  }

  .tab-list {
    padding-top: 0;
  }

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      padding: 0;
    `)};
`

export const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 24px;

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      padding: 24px 16px 0;
    `)};
`

export const Wrapper = styled.div<{ isMobile?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 auto;
  touch-action: none;
  overscroll-behavior-y: none;

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      padding: 30px 16px 24px;
    `)};
`

export const MusicListWrapper = styled(Wrapper)<{ isMobile?: boolean }>`
  padding-bottom: 30px;

  ${({ theme }) =>
    theme.breakpoints.xLarge(
      css`
        padding-bottom: 84px;
      `
    )};

  ${({ theme }) =>
    theme.breakpoints.medium(
      css`
        padding-bottom: 40px;
        height: calc(100% - 270px);
      `
    )};
`

export const Row = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`

export const Col = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

export const StyledButton = styled(Button)`
  padding: 10px 8px;

  span {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${theme.colors.gray[500]};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
  }

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      font-weight: 500;
    `)};
`

export const BreakAllContainer = styled.div`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  word-break: break-all;
  flex: 1;
`

export const Filter = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 8px;
`

const HEIGHT = 34
export const RenderTrackContainer = styled.div<{
  background: string | undefined
}>`
  width: 100%;
  height: ${HEIGHT}px;
  position: relative;
  background: ${theme.colors.border.primary};
  background-image: ${({ background }) => background && `url('${background}')`};
  background-position: 50% 50%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
`

const BORDER_WIDTH = 2
export const RenderThumbContainer = styled.div<{ width: number }>`
  position: relative !important;
  width: ${({ width }) => width}%;
  height: ${HEIGHT}px;
  border: ${BORDER_WIDTH}px solid ${theme.colors.primary};
  border-radius: 2px;

  .track-left,
  .track-right {
    position: absolute;
    top: 0;
    height: ${HEIGHT - BORDER_WIDTH}px;
  }

  .track-left {
    left: -${BORDER_WIDTH}px;
  }

  .track-right {
    right: -${BORDER_WIDTH}px;
  }
`

export const FilterWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 33px;
  z-index: 500;
  width: 100%;
  padding: 24px 20px;
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.gray[100]};
  border-radius: 4px;
  box-shadow: 0px 0px 8px 2px rgba(124, 124, 124, 0.08);
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const FilterCol = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const FilterTitle = styled.div`
  color: ${theme.colors.text['#222']};
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
`

export const RoundButton = styled(Button)<{ primary: boolean }>`
  padding: 8px 12px;
  border-radius: 24px;
  border: 1px solid ${theme.colors.gray[100]};
  color: ${theme.colors.gray[500]};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;

  ${({ primary }) =>
    primary &&
    css`
      color: ${theme.colors.brand.primary[400]};
      border-color: ${theme.colors.brand.primary[400]};
      background-color: ${theme.colors.brand.primary[10]};
    `};
`

export const LottieContainer = styled.div`
  margin-top: 20px;
`

export const FilterButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0px;
  border: none;
`

export const Subtitle = styled.div`
  color: ${theme.colors.gray[700]};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 22.4px */
`

export const ContentNone = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 47px;
  color: ${theme.colors.gray[400]};
  margin-top: 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`

export const AudioCol = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const BgmTitle = styled.div`
  height: 14px;
  color: ${theme.colors.text['#222']};
  font-size: 14px;
  font-weight: 500;
`

export const BgmTitleCol = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const ListTitle = styled(Row)`
  position: relative;
`

export const ListWrapper = styled(BgmTitleCol)`
  position: relative;
  width: 100%;
  flex: 1;
  min-height: 200px;
`

export const BgmRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${theme.colors.gray[800]};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 14px */
`

export const AbsoluteWrapper = styled.div`
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  padding: 24px 20px;
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.border.secondary};
  border-radius: 4px;
  z-index: 1;

  &&& {
    box-shadow: 0px 0px 8px rgba(124, 124, 124, 0.08);
  }

  &::before {
    content: '';
    position: absolute;
    top: -22px;
    left: 30px;
    transform: translateX(-50%);
    border-width: 11px;
    border-style: solid;
    border-color: transparent transparent ${theme.colors.border.secondary}
      transparent;
  }

  &::after {
    content: '';
    position: absolute;
    top: -18px;
    left: 30px;
    transform: translateX(-50%);
    border-width: 9px;
    border-style: solid;
    border-color: transparent transparent ${theme.colors.white} transparent;
    z-index: 1;
  }

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      padding: 33px 16px;
    `)};
`

export const EmptyList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 250px);
  color: ${({ theme }) => theme.colors.text['#666']};
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`

export const MusicItemContainer = styled.div<{ isSelect: boolean }>`
  border-bottom: 1px solid ${theme.colors.border.secondary};

  &:last-child {
    border-bottom: none;
  }

  ${({ isSelect }) =>
    isSelect &&
    css`
      position: relative;
    `};
`

import React, { useCallback, useEffect, useState } from 'react'

import Button from '../../components/Button'

import { message } from 'antd'
import { Project } from '../../types/project'
import { PROJECT_STATUS } from '../../types/constants'
import checkXSS from '../../utils/libs/checkXSS'
import {
  FormatTextOptions,
  getStatusText,
  getTooltipMessage
} from './constants'
import { useProjectContext } from '../../hooks/useProject'
import {
  RightWrapper,
  EditIcon,
  LeftWrapper,
  StyledInput,
  StyledTypography,
  Wrapper,
  CenterWrapper,
  DefaultInputWrapper
} from './_stylesHeader'

import { UseModalProps } from '../../types/useModal'
import { useIntl } from 'react-intl'
import { useLocalStorage } from 'usehooks-ts'
import { AuthInitialValue } from '../../components/AuthHelper'

import DefaultTooltip from '../../components/DefaultTooltip'
import DefaultButton from '../../components/DefaultButton'
import { useModifyProjectMutation } from './_queries'

import theme from '../../styles/theme'
import RemainingRendering from './RemainingRendering'
import useBreakpoint from '../../hooks/useBreakpoint'
import { IconArrowLeft, IconEditMessage } from '../../assets/icons'

message.config({ maxCount: 3 })

interface HeaderProps {
  isModified: boolean
  onCompleteClick?(): void
  setSelectedSceneIndex: React.Dispatch<React.SetStateAction<number>>
  setIsFlickering: React.Dispatch<React.SetStateAction<boolean>>
  basicModal: UseModalProps
  redirectProject: (options: { isRender: boolean }) => void
}

const Header = ({
  isModified,
  onCompleteClick,
  setSelectedSceneIndex,
  setIsFlickering,
  basicModal,
  redirectProject
}: HeaderProps) => {
  const intl = useIntl()
  const [vplateVideoEditorAuth] = useLocalStorage(
    'vplateVideoEditorAuth',
    AuthInitialValue
  )
  const { project, setProject, sceneList, projectId, isLimit } =
    useProjectContext()
  const {
    title,
    workProgress,
    renderProgress,
    status = 0,
    renderCnt,
    renderLimit,
    planType,
    needCheck
  } = project || {}

  const [showInput, setShowInput] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const { modifyMutate } = useModifyProjectMutation()

  const statusText = getStatusText(intl, status, renderProgress)
  const isExcededLimit =
    needCheck &&
    typeof renderCnt === 'number' &&
    typeof renderLimit === 'number' &&
    renderCnt >= renderLimit

  const disabled =
    status > PROJECT_STATUS.EDIT ||
    status === PROJECT_STATUS.WAIT ||
    isExcededLimit

  const { isMobile } = useBreakpoint()

  const onChangeInput = useCallback((event) => {
    const value = event.currentTarget.value
    const result = checkXSS(value)

    if (result.length !== value.length) {
      message.warning({
        content: intl.formatMessage({ id: 'TITLE_WARNING_MESSAGE' }),
        duration: 0.3
      })
      return
    }
    setInputValue(result)
  }, [])

  const onRenameProject = async (
    e:
      | React.FocusEvent<HTMLInputElement>
      | React.KeyboardEvent<HTMLInputElement>
  ) => {
    setShowInput(false)
    if (!inputValue || inputValue === title) return
    const { value } = e.currentTarget
    const result = { ...project, title: checkXSS(value) } as Project
    try {
      setProject && (await setProject(result))
    } catch (error) {
      if (error instanceof Error) {
        setInputValue(title || '')
        message.error(error.message)
      }
    }
  }

  const handleNotComplete = (index: number) => {
    const msg = intl.formatMessage(
      {
        id: 'TOAST_MESSASGE_INCOMPLETE'
      },
      {
        n: index + 1
      }
    )
    message.warning(msg)

    setSelectedSceneIndex(index)
    setIsFlickering(true)
  }

  const handleOnclick = () => {
    const notCompleteSceneIndex = (sceneList || []).findIndex(
      ({ isCompleted }) => !isCompleted
    )
    if (notCompleteSceneIndex !== -1) {
      handleNotComplete(notCompleteSceneIndex)
      return
    }
    onCompleteClick && onCompleteClick()
  }

  const formatText = (id: string, options?: FormatTextOptions) => {
    return intl.formatMessage({ id }, options)
  }

  const tooltipMessage = getTooltipMessage(
    status,
    isModified,
    isLimit,
    formatText,
    renderLimit
  )

  // 완료된 프로젝트여도 수정한 부분이 있으면 status 변경
  useEffect(() => {
    if (
      projectId &&
      status &&
      status >= PROJECT_STATUS.RENDER_DONE &&
      isModified
    ) {
      modifyMutate(projectId)
    }
  }, [projectId, status, isModified])

  useEffect(() => {
    if (title) {
      setInputValue(title)
    }
  }, [title])

  useEffect(() => {
    if (isLimit) {
      basicModal.open()
    }
  }, [isLimit])

  return (
    <Wrapper isMobile={isMobile}>
      <LeftWrapper>
        {project?.userId && (
          <Button
            link
            onClick={() => redirectProject({ isRender: false })}
            style={{ padding: 0 }}
          >
            <IconArrowLeft />
            {!isMobile && intl.formatMessage({ id: 'BACK' })}
          </Button>
        )}
      </LeftWrapper>
      <CenterWrapper>
        {!showInput ? (
          <DefaultInputWrapper>
            <StyledTypography isMobile={isMobile} bold>
              {title}
            </StyledTypography>
            <EditIcon onClick={() => setShowInput(true)}>
              <IconEditMessage />
            </EditIcon>
          </DefaultInputWrapper>
        ) : (
          <StyledInput
            value={inputValue}
            autoFocus
            maxLength={20}
            onBlur={onRenameProject}
            onPressEnter={onRenameProject}
            onChange={onChangeInput}
            style={{ borderColor: theme.colors.primary }}
          />
        )}
      </CenterWrapper>
      <RightWrapper>
        {vplateVideoEditorAuth?.token && (
          <RemainingRendering
            renderCnt={renderCnt}
            renderLimit={renderLimit}
            planType={planType}
            isMobile={isMobile}
            needCheck={needCheck}
          />
        )}
        {workProgress !== undefined && (
          <DefaultTooltip
            position={{
              top: 48,
              right: 0
            }}
            tail={{
              direction: 'top',
              position: { top: -4, right: 12 }
            }}
            contents={tooltipMessage}
            touch
          >
            <DefaultButton
              category='primary'
              onClick={handleOnclick}
              disabled={disabled}
            >
              {statusText}
            </DefaultButton>
          </DefaultTooltip>
        )}
      </RightWrapper>
    </Wrapper>
  )
}

export default Header

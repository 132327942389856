import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTheme } from 'styled-components'
import { HexColorPicker, HexColorInput } from 'react-colorful'
import 'antd/dist/antd.min.css'
import ColorChip from './ColorChip'
import ColorChips from './ColorChips'
import { basicColors } from './constants'
import {
  AddFavoriteButton,
  AlignCenter,
  BoldTypography,
  CheckAlignCenter,
  ChipsRow,
  ChipsWrapper,
  ColorContainer,
  ColorPickRow,
  ColorPickerWrapper,
  ColorTypography,
  Container,
  Count,
  EditFavoriteButton,
  EmptyTypography,
  FavoriteEmptyTypography,
  Footer,
  HexColorInputWrapper,
  IconArrowRight,
  Preview,
  Row,
  SecondaryTypography,
  StyledColorColumn,
  Wrapper
} from './styles'
import LottieIcon from '../Lottie'
import { useIntl } from 'react-intl'
import { IconHeart, IconPenCil, IconTrashcan } from '../../assets/icons'
import PanelTitle from '../PanelTitle'

import DefaultButton from '../DefaultButton'
import useBreakpoint from '../../hooks/useBreakpoint'
import useFormatText from '../../hooks/useFormatText'
import { useLocalStorage } from 'usehooks-ts'
import EmptyText from '../EmptyText'
import Checkbox from '../Checkbox'

type ColorPanelProps = {
  selectedDrawerIndex: number
  title: string
  isMutateLoading?: boolean
  isReset: boolean
  setIsReset: Dispatch<SetStateAction<boolean>>
  defaultColor?: string
  currentColor?: string
  onColorChange?(newColor?: string, prevColor?: string): void
  handleDrawerClose: () => void
}

const ColorPanel = (props: ColorPanelProps) => {
  const {
    selectedDrawerIndex,
    title,
    isMutateLoading,
    isReset,
    setIsReset,
    defaultColor: initialDefaultColor,
    currentColor: initialCurrentColor,
    onColorChange = () => {},
    handleDrawerClose
  } = props
  const intl = useIntl()
  const { isBreakpoint } = useBreakpoint()
  const theme = useTheme()
  const defaultColor = `#${initialDefaultColor}` || theme.colors.white
  const currentColor =
    initialDefaultColor !== initialCurrentColor && initialCurrentColor
      ? `#${initialCurrentColor}`
      : ''

  const [changedColor, setChangedColor] = useState(currentColor)

  const [favColor, setFavColor] = useLocalStorage<string[]>('favColor', [])
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [checkList, setCheckList] = useState<string[]>([])
  const formatEmptyText = useFormatText('SIDE_NAV_COLOR_CONTENT_AFTER_NONE')
  const isResetVisible = changedColor && changedColor !== defaultColor

  //NOTE: 색깔 즐겨찾기 로직
  const handleAddColor = () => {
    if (favColor) {
      //NOTE: 색깔 중복 체크
      if (favColor.includes(changedColor)) {
        return
      }
      //NOTE: 35개 넘어가면 앞에꺼 삭제
      if (favColor.length > 34) {
        favColor.pop()
      }

      favColor.unshift(changedColor)
      setFavColor(favColor)
    } else {
      setFavColor([changedColor])
    }
  }

  const handleResetColor = () => {
    if (!changedColor) return
    initialDefaultColor && setChangedColor('#' + initialDefaultColor)
    setIsReset(false)
  }

  const handleCheckList = (color: string) => {
    if (checkList.includes(color)) {
      setCheckList((prev) => prev.filter((item) => item !== color))
    } else {
      setCheckList((prev) => [...prev, color])
    }
  }

  const handleClickEditFavorite = (e: React.MouseEvent) => {
    e.stopPropagation()
    if (isEditMode && checkList.length > 0) {
      const newFavColor = favColor.filter(
        (prevColor) => !checkList.includes(prevColor)
      )
      setFavColor(newFavColor)
      setCheckList([])
    }
    setIsEditMode((prev) => !prev)
    setCheckList([])
  }

  const handleClickCheckAll = () => {
    if (favColor.length === checkList.length) {
      setCheckList([])
    } else {
      setCheckList([...favColor])
    }
  }

  useEffect(() => {
    setChangedColor(currentColor)
  }, [currentColor])

  useEffect(() => {
    if (isReset) {
      handleResetColor()
    }
  }, [isReset])

  useEffect(() => {
    if (selectedDrawerIndex === -1) {
      setIsEditMode(false)
    }
  }, [selectedDrawerIndex])

  return (
    <>
      <Container>
        {initialDefaultColor && (
          <PanelTitle
            title={title}
            handleReset={isResetVisible ? handleResetColor : null}
            handleDrawerClose={handleDrawerClose}
          >
            <Wrapper>
              {isMutateLoading ? (
                <LottieIcon />
              ) : (
                initialDefaultColor && (
                  <ColorContainer>
                    <div>
                      <Preview>
                        <StyledColorColumn>
                          <ColorTypography>
                            {intl.formatMessage({
                              id: 'SIDE_NAV_COLOR_CONTENT_DAFAULT'
                            })}
                          </ColorTypography>
                          <ColorChip color={defaultColor} />
                          <SecondaryTypography>
                            {defaultColor.toUpperCase()}
                          </SecondaryTypography>
                        </StyledColorColumn>
                        <IconArrowRight />
                        <StyledColorColumn>
                          {changedColor ? (
                            <>
                              <ColorTypography>
                                {intl.formatMessage({
                                  id: 'SIDE_NAV_COLOR_CONTENT_AFTER'
                                })}
                              </ColorTypography>
                              <ColorChip
                                color={changedColor}
                                setColor={setChangedColor}
                              />
                              <SecondaryTypography>
                                {changedColor.toUpperCase()}
                              </SecondaryTypography>
                            </>
                          ) : (
                            <EmptyTypography>{formatEmptyText}</EmptyTypography>
                          )}
                        </StyledColorColumn>
                      </Preview>
                      <ColorPickRow>
                        <BoldTypography>
                          {intl.formatMessage({
                            id: 'SIDE_NAV_COLOR_CONTENT_PICK'
                          })}
                        </BoldTypography>
                        <div>
                          <AddFavoriteButton
                            category='compactMonochrome'
                            onClick={handleAddColor}
                          >
                            <IconHeart width={18} height={18} />
                            {intl.formatMessage({
                              id: 'SIDE_NAV_COLOR_CONTENT_ADD_FAVORITE'
                            })}
                          </AddFavoriteButton>
                        </div>
                      </ColorPickRow>
                      <Row
                        style={{
                          flexDirection: 'column',
                          paddingTop: 0,
                          paddingBottom: 0
                        }}
                      >
                        <ColorPickerWrapper>
                          <HexColorPicker
                            color={changedColor}
                            onChange={setChangedColor}
                          />
                        </ColorPickerWrapper>
                      </Row>
                      <Row>
                        <HexColorInputWrapper>
                          <ColorChip
                            color={changedColor}
                            chipStyle={{ width: 24, height: 24 }}
                          />
                          <HexColorInput
                            prefixed
                            color={changedColor.toUpperCase()}
                            onChange={setChangedColor}
                            size={7}
                          />
                        </HexColorInputWrapper>
                      </Row>
                      <ChipsWrapper>
                        <ChipsRow>
                          <BoldTypography>
                            {intl.formatMessage({
                              id: 'SIDE_NAV_COLOR_CONTENT_BASIC'
                            })}
                          </BoldTypography>
                        </ChipsRow>
                        <ColorChips
                          setColor={setChangedColor}
                          colors={basicColors}
                        />
                      </ChipsWrapper>
                      <ChipsWrapper
                        style={{
                          gap: favColor.length === 0 ? 7 : 12
                        }}
                      >
                        <ChipsRow style={{ height: 32 }}>
                          <AlignCenter style={{ gap: 4 }}>
                            <BoldTypography>
                              {intl.formatMessage({
                                id: 'SIDE_NAV_COLOR_CONTENT_FAVORITE'
                              })}
                            </BoldTypography>
                            <Count>({favColor.length})</Count>
                          </AlignCenter>
                          {favColor.length > 0 && (
                            <AlignCenter style={{ gap: 12 }}>
                              {isEditMode && (
                                <CheckAlignCenter onClick={handleClickCheckAll}>
                                  <Checkbox
                                    checked={
                                      favColor.length === checkList.length
                                    }
                                  />
                                  <span>
                                    <strong>{checkList.length}</strong>
                                    {useFormatText(
                                      'STORAGE_CHECK_COUNT_UNIT'
                                    )}{' '}
                                    {useFormatText('STORAGE_SELECT')}
                                  </span>
                                </CheckAlignCenter>
                              )}
                              <EditFavoriteButton
                                category='compactMonochrome'
                                onClick={handleClickEditFavorite}
                              >
                                {isEditMode ? <IconTrashcan /> : <IconPenCil />}
                                {intl.formatMessage({
                                  id: isEditMode ? 'DELETE' : 'EDIT'
                                })}
                              </EditFavoriteButton>
                            </AlignCenter>
                          )}
                        </ChipsRow>
                        <div
                          style={{
                            width: '100%',
                            maxHeight: 152,
                            overflowY: 'auto'
                          }}
                        >
                          {favColor.length === 0 && (
                            <FavoriteEmptyTypography>
                              {useFormatText(
                                'SIDEBAR_COLOR_PANEL_EMPTY_FAVORITE_COLOR'
                              )}
                            </FavoriteEmptyTypography>
                          )}
                          {favColor.length > 0 && (
                            <ColorChips
                              setColor={setChangedColor}
                              colors={favColor}
                              isEditMode={isEditMode}
                              checkList={checkList}
                              handleCheckList={handleCheckList}
                            />
                          )}
                        </div>
                      </ChipsWrapper>
                      <Footer>
                        <DefaultButton
                          category='secondaryMulticolored'
                          onClick={() => {
                            onColorChange(changedColor.replace(/#/, ''))
                          }}
                          fullWidth={isBreakpoint('medium')}
                          style={{ padding: 12, fontWeight: 600 }}
                        >
                          {intl.formatMessage({
                            id: 'SIDE_NAV_COLOR_CONTENT_BUTTON_DONE'
                          })}
                        </DefaultButton>
                      </Footer>
                    </div>
                  </ColorContainer>
                )
              )}
            </Wrapper>
          </PanelTitle>
        )}
        {!initialDefaultColor && (
          <PanelTitle
            title={title}
            handleDrawerClose={handleDrawerClose}
            wrapperStyle={{ height: '100%' }}
          >
            <EmptyText
              text={intl.formatMessage({ id: 'SIDE_NAV_COLOR_CONTENT_NONE' })}
            />
          </PanelTitle>
        )}
      </Container>
    </>
  )
}

export default ColorPanel

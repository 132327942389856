import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { IconBlank } from '../../assets/icons'
import PhotoFilter from '../../components/Dropdown/PhotoFilter'
import useFormatText from '../../hooks/useFormatText'
import {
  BlankWrapper,
  Content,
  ImageList,
  ImageListItem,
  LottieWrapper,
  ObserveElement
} from './_stylesModal'
import { PhotoData } from '../../utils/api/images'
import AspectRatioSizer from '../../components/AspectRatioSizer'
import { useInView } from 'react-intersection-observer'
import useFetchPexels, { MEDIA_TYPE } from '../../hooks/useFetchPexels'
import useLoading from '../../hooks/useLoading'
import SearchInputForm from './SearchInputForm'

export type Orientation = 'landscape' | 'portrait' | ''

interface FreeImageProps {
  visible?: boolean
  selectedImageUrl: string
  onClickImage: (img: PhotoData | string) => void
  previewLoading: boolean
  setSelectedAssetInfo: Dispatch<SetStateAction<any>>
}

const FreeImage = ({
  visible,
  selectedImageUrl,
  onClickImage,
  previewLoading,
  setSelectedAssetInfo
}: FreeImageProps) => {
  const [orientation, setOrientation] = useState<Orientation>('')
  const [query, setQuery] = useState('')

  const {
    media: imageList,
    fetchNextPage,
    refetchMedia: refetchImage,
    isFetching
  } = useFetchPexels({
    type: MEDIA_TYPE.PHOTO,
    words: query,
    orientation: orientation,
    preventFetch: !visible
  })

  const hasResult = imageList?.pages[0] && imageList.pages[0]?.total > 0

  const {
    renderLoading: listLoad,
    startLoading: listLoadStart,
    endLoading: listLoadEnd
  } = useLoading()

  const { ref: observeRef, inView } = useInView({
    threshold: 0,
    triggerOnce: true
  })

  const handleSearch: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()
    const formData = new FormData(e.target as HTMLFormElement)
    setQuery(formData.get('query') as string)
  }

  useEffect(() => {
    isFetching ? listLoadStart() : listLoadEnd()
  }, [isFetching, listLoadEnd, listLoadStart])

  useEffect(() => {
    visible && refetchImage()
  }, [orientation, refetchImage])

  useEffect(() => {
    inView && fetchNextPage()
  }, [fetchNextPage, inView])

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative'
      }}
    >
      <SearchInputForm
        onSubmit={handleSearch}
        name='query'
        autoComplete='off'
        placeholder={useFormatText(
          'IMAGE_INPUT_FROM_FREESTOCK_SEARCH_PLACEHOLDER'
        )}
      />
      <PhotoFilter orientation={orientation} setOrientation={setOrientation} />
      <Content>
        {hasResult ? (
          <ImageList>
            {imageList &&
              imageList.pages.map((page) => {
                return page?.data.map((d) => {
                  const image = d as PhotoData
                  return (
                    <ImageListItem
                      key={image.id}
                      src={image.thumbnail}
                      selected={image?.image === selectedImageUrl}
                      onClick={() => {
                        onClickImage(image)
                        setSelectedAssetInfo(null)
                      }}
                      isPreviewLoading={previewLoading}
                    >
                      <AspectRatioSizer aspect={16 / 9} />
                    </ImageListItem>
                  )
                })
              })}
          </ImageList>
        ) : (
          <>
            {!isFetching && (
              <BlankWrapper>
                <IconBlank />
                <span>{useFormatText('NO_SEARCH_RESULT')}</span>
              </BlankWrapper>
            )}
          </>
        )}
        {isFetching ? (
          <LottieWrapper>{listLoad()}</LottieWrapper>
        ) : (
          <>{hasResult && <ObserveElement ref={observeRef} />}</>
        )}
      </Content>
    </div>
  )
}

export default FreeImage

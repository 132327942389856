import styled from 'styled-components'
import theme from '../../styles/theme'
import useFormatText from '../../hooks/useFormatText'
import PlanTitleBadge from '../PlanTitleBadge'
import { useProjectContext } from '../../hooks/useProject'
import { useStorageInfoQuery } from '../../Editor/EditModals/Storage/_queries'

const StorageInfo = () => {
  const { project } = useProjectContext()
  const { storageData } = useStorageInfoQuery()
  const { percentage, usedSize, limitSize } = storageData || {}

  return (
    <Wrapper>
      <div className='info-wrapper'>
        <div className='storage-info'>
          <span className='title'>
            {useFormatText('SIDE_NAV_STORAGE_MAX_SIZE')}
          </span>
          <PlanTitleBadge
            planType={project?.planType}
            style={{ marginBottom: 0 }}
          />
        </div>
        <div className='size-info'>
          <span className='current'>{usedSize}GB</span>
          <span className='total'>/ {limitSize}GB</span>
        </div>
      </div>
      <ProgressBar value={percentage}>
        <div className='progress' />
      </ProgressBar>
      <div className='progress-info'>
        {percentage}% {useFormatText('STORAGE_USED')}
      </div>
    </Wrapper>
  )
}

export default StorageInfo

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  background-color: ${theme.colors.white};
  padding-top: 20px;
  padding-bottom: 18px;

  .info-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid ${theme.colors.border.primary};
    padding-top: 20px;

    .storage-info {
      display: flex;
      align-items: center;
      gap: 8px;

      .title {
        color: ${theme.colors.gray[800]};
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%; /* 16px */
      }
    }

    .size-info {
      display: flex;
      align-items: center;
      gap: 4px;
      color: ${theme.colors.gray[700]};
      font-size: 16px;
      font-weight: 400;
      line-height: 100%;

      .current {
        color: ${theme.colors.brand.primary[400]};
        font-weight: 500;
      }
    }
  }

  .progress-info {
    color: ${theme.colors.gray[400]};
    font-size: 14px;
    font-weight: 500;
    line-height: 100%;
    margin-top: 10px;
    padding-bottom: 20px;
  }
`

const ProgressBar = styled.div<{ value: number }>`
  position: relative;
  width: 100%;
  height: 6px;
  background-color: ${theme.colors.gray[100]};
  border-radius: 6px;
  margin-top: 12px;
  overflow: hidden;

  .progress {
    position: absolute;
    top: 0;
    left: 0;
    width: ${({ value }) => value}%;
    height: 100%;
    background-color: ${theme.colors.brand.primary[400]};
    border-radius: 4px;
  }
`

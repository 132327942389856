import Button from '../Button'
import useFormatText from '../../hooks/useFormatText'
import { IconPenCil, IconTrashcan, iconMore } from '../../assets/icons'
import { useEffect, useRef, useState } from 'react'
import UploadInfo from './UploadInfo'
import FileItem from '../../Editor/EditModals/Storage/FileItem'
import {
  useDeleteAssetsMutation,
  useStorageInfiniteQuery
} from '../../Editor/EditModals/Storage/_queries'
import { useInView } from 'react-intersection-observer'
import LottieIcon from '../Lottie'
import StorageInfo from './StorageInfo'
import { useIntl } from 'react-intl'
import EmptyText from '../EmptyText'
import { Skeleton } from 'antd'
import {
  Container,
  FilePreview,
  ListWrapper,
  LoadingWrapper,
  PreviewWrapper,
  SkeletonItem,
  UploadButton,
  Wrapper,
  itemStyle
} from './styles'
import useStorageFile from '../../Editor/EditModals/Storage/hooks/useStorageFile'
import Checkbox from '../Checkbox'

const accepts = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/webp',
  'video/mp4'
]

const Panel = ({ isActive }: { isActive: boolean }) => {
  const intl = useIntl()
  const [isShowUploadInfo, setIsShowUploadInfo] = useState(false)
  const [selectedFile, setSelectedFile] = useState<any | null>(null)
  const [files, setFiles] = useState<any[]>([])
  const [checkedList, setCheckedList] = useState<any[]>([])
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const wrapperRef = useRef<HTMLDivElement>(null)

  const formatText = (text: string) => {
    return intl.formatMessage({ id: text })
  }

  const fileRef = useRef<HTMLInputElement>(null)
  const { ref: observeRef, inView } = useInView({
    threshold: 0,
    triggerOnce: true
  })

  const {
    storageData,
    fetchNextPage,
    isFetching,
    isLoading,
    resultTotalCount,
    hasNext
  } = useStorageInfiniteQuery({
    limit: 20,
    files,
    setFiles
  })

  const { deleteAssetsMutate } = useDeleteAssetsMutation(setCheckedList)

  const { notUploadedFiles, handleInputFileChange } = useStorageFile({
    files,
    setFiles,
    queryKey: ['assets', undefined, 0]
  })

  const uploadingFiles = files.filter((item) => item?.uploadProgress > 0)
  const items = [...uploadingFiles, ...storageData]

  const selectedFileUrl =
    selectedFile?.convertedUrl || selectedFile?.originalUrl || selectedFile?.url

  const deleteFile = () => {
    if (checkedList.length === 0) return
    const checkIds = checkedList.map((file) => file?._id)

    if (!checkIds.every((id) => id)) {
      setFiles((prev) => {
        return prev.filter((file) => !checkedList.includes(file))
      })
    }

    deleteAssetsMutate(checkIds)
  }

  const handleEditButtonClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    if (isEditMode) {
      deleteFile()
    }
    setIsEditMode((prev) => !prev)
  }

  const handleClickCheckAll = () => {
    if (checkedList.length === items.length) {
      setCheckedList([])
    } else {
      setCheckedList(items)
    }
  }

  const handleItemClick = (file: any) => {
    if (!isEditMode) {
      setSelectedFile(file)
      return
    }
    if (checkedList.includes(file)) {
      setCheckedList((prev) => {
        return prev.filter((item) => {
          if (item?._id && file?._id) {
            return item._id !== file._id
          }
          return item.name !== file.name
        })
      })
    } else {
      setCheckedList((prev) => [...prev, file])
    }
  }

  useEffect(() => {
    inView && fetchNextPage()
  }, [inView])

  return (
    <Wrapper ref={wrapperRef}>
      <div style={{ position: 'relative', paddingBottom: 30 }}>
        <UploadButton
          category='secondaryMulticolored'
          onClick={() => fileRef.current && fileRef.current.click()}
        >
          {useFormatText('SIDE_NAV_STORAGE_UPLOAD')}
          <img
            src={iconMore}
            alt='more info'
            onClick={(e) => {
              e.stopPropagation()
              setIsShowUploadInfo(true)
            }}
          />
        </UploadButton>
        {isShowUploadInfo && (
          <UploadInfo onClose={() => setIsShowUploadInfo(false)} />
        )}
      </div>
      <Container>
        <PreviewWrapper>
          <div className='title'>
            {useFormatText('SIDE_NAV_STORAGE_PREVIEW')}
          </div>
          <FilePreview isEmpty={!selectedFile}>
            {selectedFile && (
              <>
                {selectedFile.type.includes('image') ? (
                  <img src={selectedFileUrl} />
                ) : (
                  <video
                    src={selectedFileUrl}
                    controls
                    autoPlay
                    muted
                    playsInline
                  />
                )}
              </>
            )}
            {!selectedFile && formatText('SIDE_NAV_STORAGE_EMPTY')}
          </FilePreview>
        </PreviewWrapper>
        <ListWrapper>
          <div className='container'>
            <div className='list-info'>
              <span className='title'>
                {useFormatText('SIDE_NAV_STORAGE_MEDIA_FILE')}
              </span>
              {!isLoading && (
                <span className='count'>({resultTotalCount})</span>
              )}
            </div>
            <div>
              {isEditMode && (
                <div className='check-info' onClick={handleClickCheckAll}>
                  <Checkbox checked={checkedList.length === items.length} />
                  <div className='info-text'>
                    <span className='check-count'>{checkedList.length}</span>
                    {formatText('STORAGE_CHECK_COUNT_UNIT')}
                    <span style={{ marginLeft: 4 }}>
                      {formatText('STORAGE_SELECT')}
                    </span>
                  </div>
                </div>
              )}
              <Button onClick={handleEditButtonClick}>
                {isEditMode ? <IconTrashcan /> : <IconPenCil />}
                {useFormatText(isEditMode ? 'DELETE' : 'EDIT')}
              </Button>
            </div>
          </div>
          {!isLoading ? (
            <>
              <div className='list'>
                <div className='scroller'>
                  {Array.isArray(notUploadedFiles) &&
                    notUploadedFiles.map((notUploadedFile) => (
                      <SkeletonItem
                        key={`${notUploadedFile?._id || notUploadedFile?.name}`}
                      >
                        <Skeleton.Image />
                      </SkeletonItem>
                    ))}
                  {(items || []).length > 0 && (
                    <>
                      {items.map((file: any) => {
                        const isChecked = checkedList.find((item) => {
                          return item?._id
                            ? item?._id === file?._id
                            : item?.name == file?.name
                        })
                        return (
                          <div className='item' key={file._id}>
                            <FileItem
                              file={file}
                              isChecked={isChecked}
                              onClick={() => handleItemClick(file)}
                              wrapperStyle={itemStyle}
                              showCheckbox={isEditMode}
                            />
                          </div>
                        )
                      })}

                      {isFetching ? (
                        <div style={{ margin: '20px auto' }}>
                          <LottieIcon />
                        </div>
                      ) : (
                        hasNext && (
                          <div style={{ margin: 20 }} ref={observeRef} />
                        )
                      )}
                    </>
                  )}
                  {(items || []).length === 0 && (
                    <EmptyText
                      text={useFormatText('STORAGE_EMPTY_TEXT')}
                      style={{ height: '100%' }}
                    />
                  )}
                </div>
              </div>
            </>
          ) : (
            <LoadingWrapper>
              <LottieIcon />
            </LoadingWrapper>
          )}
          {isActive && <StorageInfo />}
        </ListWrapper>
      </Container>

      <input
        ref={fileRef}
        multiple
        accept={accepts.join(',')}
        type='file'
        onChange={handleInputFileChange}
        style={{ display: 'none' }}
      />
    </Wrapper>
  )
}

export default Panel

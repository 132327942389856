import { Modal } from 'antd'
import theme from '../../styles/theme'
import { IntlShape } from 'react-intl'

// 플러그인으로 렌더 완료된 프로젝트에 접속할 경우 접근 X, 프로젝트 페이지로 리다이렉트
export const showAlreadyRenderModal = (
  intl: IntlShape,
  redirectProject: (options: { isRender: boolean }) => void
) => {
  const { formatMessage } = intl
  Modal.warning({
    title: formatMessage({ id: 'ALREADY_RENDER' }),
    content: formatMessage({ id: 'GO_PROJECT_SHOW_VIDEO' }),
    onOk: () => redirectProject({ isRender: false }),
    okText: formatMessage({ id: 'OK_BUTTON' }),
    centered: true,
    closable: false,
    keyboard: false,
    okButtonProps: {
      style: {
        backgroundColor: theme.colors.primary,
        borderColor: theme.colors.primary
      }
    }
  })
}

export const sendMessageToParent = (obj: {
  code: string
  data: any
  isRender?: boolean
  isWaiting?: boolean
  tryOtherTemplate?: boolean
}) => {
  if (typeof window?.parent?.postMessage === 'function') {
    window.parent.postMessage(JSON.stringify(obj), '*')
  }
}

import { Dispatch, SetStateAction } from 'react'

import Tabs from '../../components/Tabs'
import { PhotoData } from '../../utils/api/images'

import { useIntl } from 'react-intl'
import { css } from 'styled-components'
import Storage from './Storage'
import FreeImage from './FreeImage'

interface PhotoModalTabsProps {
  visible?: boolean
  selectedImageUrl: string
  setSelectedImageUrl: Dispatch<SetStateAction<string>>
  previewLoadStart: () => void
  previewLoading: boolean
  setSelectedAssetInfo: Dispatch<SetStateAction<any>>
  selectedTabIndex: number
  setSelectedTabIndex: Dispatch<SetStateAction<number>>
}

const PhotoModalTabs = (props: PhotoModalTabsProps) => {
  const {
    visible,
    selectedImageUrl,
    setSelectedImageUrl,
    previewLoadStart,
    previewLoading,
    setSelectedAssetInfo,
    selectedTabIndex,
    setSelectedTabIndex
  } = props

  const intl = useIntl()
  const { formatMessage } = intl

  const onClickImage = (img: PhotoData | string) => {
    const image = typeof img === 'string' ? img : img?.image
    const str = typeof img === 'string' ? '' : '?w=1280'
    const url = `${image}${str}`
    if (selectedImageUrl === url) return
    setSelectedImageUrl(url)
    previewLoadStart()
  }

  const getTabs = () => {
    const freeTab = {
      title: formatMessage({ id: 'IMAGE_INPUT_FROM_FREESTOCK' }),
      children: (
        <FreeImage
          visible={visible}
          selectedImageUrl={selectedImageUrl}
          onClickImage={onClickImage}
          previewLoading={previewLoading}
          setSelectedAssetInfo={setSelectedAssetInfo}
        />
      ),
      onClick: () => setSelectedTabIndex(0)
    }

    const assetTab = {
      title: formatMessage({ id: 'IMAGE_INPUT_FROM_STORAGE' }),
      children: (
        <Storage
          onClickItem={(asset: any) => {
            if (!asset) return
            onClickImage(asset?.convertedUrl || asset?.originalUrl)
            setSelectedAssetInfo(asset)
          }}
          type='image'
        />
      ),
      onClick: () => setSelectedTabIndex(1)
    }

    return [freeTab, assetTab]
  }

  return (
    <Tabs
      tabs={getTabs()}
      selectedTabIndex={selectedTabIndex}
      wrapperStyle={wrapperStyle}
    />
  )
}

export default PhotoModalTabs

const wrapperStyle = css`
  height: 100%;
  display: flex;
  flex-direction: column;

  .tab-content {
    flex-grow: 1;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;

    ${({ theme }) => theme.breakpoints.medium`
        height: calc(100% - 35px);
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        overflow-y: auto;
    `};
  }

  .tab-list {
    height: 36px;
    flex-shrink: 0;
    gap: 20px;
    padding: 0;
    margin-top: 24px;
  }

  .tab-list-item {
    padding: 10px 0;

    &:first-of-type {
      margin-left: 0;
    }
  }
`

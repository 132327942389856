import styled from 'styled-components'
import useFormatText from '../../hooks/useFormatText'
import LottieIcon from '../../components/Lottie'
import { threeDotsLoading } from '../../assets/lottie'

const FileLoading = () => {
  return (
    <Container>
      <LottieIcon
        width={100}
        height={100}
        options={{ animationData: threeDotsLoading }}
      />
      <Text>{useFormatText('EDIT_MEDIA_FILE_LOADING_INFO')}</Text>
    </Container>
  )
}

export default FileLoading

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding-top: 36px;
  padding-bottom: 44px;
`

const Text = styled.div`
  color: ${({ theme }) => theme.colors.gray[800]};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  padding: 0 16px;
  white-space: pre-wrap;
  text-align: center;
`

import { formatSeconds } from '../../utils/libs'
import {
  BgmRow,
  BreakAllContainer,
  FilterButton,
  ListTitle,
  ListWrapper,
  MusicItemContainer,
  StyledButton,
  Subtitle
} from './styles'
import { MusicListProps } from './types'
import { useMusicQuery } from './_queries'

import LottieIcon from '../Lottie'
import { Music } from '../../types/music'
import MusicFilterContent from './MusicFilterContent'
import { useIntl } from 'react-intl'

import { IconEditBGM, IconFilter } from '../../assets/icons'
import PreviewMusic from './PreviewMusic'
import TrimMusic from './TrimMusic'
import { useEffect, useRef, useState } from 'react'
import { message } from 'antd'
import { useModal } from '../../hooks/useModal'
import EmptyText from '../EmptyText'

const MusicList = ({
  onChange = () => {},
  duration,
  setCurrentMusicUrl,
  handlePlay,
  handlePause
}: MusicListProps) => {
  const intl = useIntl()
  const filterPopover = useModal()

  const { musicList, isLoading } = useMusicQuery()

  const [currentStep, setCurrentStep] = useState<'preview' | 'trim' | null>(
    null
  )
  const [selectedMusic, setSelectedMusic] = useState<Music | null>(null)
  const [startTime, setStartTime] = useState(0)
  const [selectedMoods, setSelectedMoods] = useState<number[]>([0])
  const [selectedGenres, setSelectedGenres] = useState<number[]>([0])
  const filterRef = useRef<HTMLDivElement>(null)

  const getFilteredMusicList = () => {
    if (!Array.isArray(musicList)) return []

    return musicList.filter(
      (music: { musicMood: number; musicGenre: number }) => {
        return (
          (selectedMoods.includes(0) ||
            selectedMoods.includes(music.musicMood)) &&
          (selectedGenres.includes(0) ||
            selectedGenres.includes(music.musicGenre))
        )
      }
    )
  }

  const handleClose = () => {
    setSelectedMusic(null)
    setStartTime(0)
    setCurrentStep(null)
  }

  const hanleChangeMusic = (musicUrl?: string) => {
    if (!musicUrl) return
    setCurrentMusicUrl(musicUrl)
    selectedMusic && onChange(musicUrl, selectedMusic)
    setSelectedMusic(null)
    message.success(intl.formatMessage({ id: 'SIDE_NAV_CHANGED' }))
  }

  const handleClickMusic = (item: Music) => {
    setSelectedMusic(item)
  }

  useEffect(() => {
    if (!selectedMusic || (selectedMusic && currentStep === 'trim')) {
      setCurrentStep('preview')
    }
  }, [selectedMusic])

  useEffect(() => {
    setStartTime(0)
  }, [selectedMusic])

  return (
    <>
      <ListTitle style={{ marginBottom: 25 }}>
        <Subtitle>
          {intl.formatMessage({ id: 'SIDE_NAV_BGM_CONTENT_LIST' })}
        </Subtitle>
        <div ref={filterRef}>
          <FilterButton link onClick={() => filterPopover.toggle()}>
            <IconFilter />
          </FilterButton>
          {filterPopover.visible && (
            <MusicFilterContent
              setSelectedMoods={setSelectedMoods}
              selectedMoods={selectedMoods}
              selectedGenres={selectedGenres}
              setSelectedGenres={setSelectedGenres}
              filterElement={filterRef.current}
              onClickOutside={() => filterPopover.close()}
            />
          )}
        </div>
      </ListTitle>
      <ListWrapper onClick={(e) => e.stopPropagation()}>
        <BreakAllContainer>
          {isLoading ? (
            <LottieIcon />
          ) : getFilteredMusicList().length > 0 ? (
            getFilteredMusicList().map((item: Music) => {
              const isSelect = item === selectedMusic
              return (
                <MusicItemContainer key={item._id} isSelect={isSelect}>
                  <StyledButton
                    link
                    block
                    onClick={() => handleClickMusic(item)}
                  >
                    <BgmRow>
                      <IconEditBGM />
                      {item.musicTitle}
                    </BgmRow>
                    <span>{formatSeconds(item.musicLength)}</span>
                  </StyledButton>
                  {isSelect && currentStep === 'preview' && (
                    <PreviewMusic
                      onClose={handleClose}
                      selectedMusic={selectedMusic}
                      setSelectedMusic={setSelectedMusic}
                      setDrawerPage={setCurrentStep}
                      handlePlay={handlePlay}
                      handlePause={handlePause}
                    />
                  )}
                  {isSelect && currentStep === 'trim' && (
                    <TrimMusic
                      onClose={handleClose}
                      selectedMusic={selectedMusic}
                      setStartTime={setStartTime}
                      duration={duration}
                      startTime={startTime}
                      handlePlay={handlePlay}
                      handlePause={handlePause}
                      hanleChangeMusic={hanleChangeMusic}
                    />
                  )}
                </MusicItemContainer>
              )
            })
          ) : (
            <EmptyText
              text={intl.formatMessage({
                id: 'SIDE_NAV_BGM_CONTENT_FILTERED_LIST_NONE'
              })}
            />
          )}
        </BreakAllContainer>
      </ListWrapper>
    </>
  )
}

export default MusicList

import styled from 'styled-components'
import { iconZoomSearch } from '../../assets/icons'

interface SearchInputFormProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  onSubmit: React.FormEventHandler<HTMLElement>
}

const SearchInputForm = ({ onSubmit, ...props }: SearchInputFormProps) => {
  return (
    <Form onSubmit={onSubmit}>
      <Input {...props} />
      <SearchButton type='submit' />
    </Form>
  )
}

export default SearchInputForm

const Form = styled.form`
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.gray[100]};
  padding: 8px 11px;
`

const Input = styled.input`
  flex-grow: 1;
  border: none;
  outline: none;
  color: ${({ theme }) => theme.colors.gray[800]};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray[400]};
  }
`

const SearchButton = styled.button`
  width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.colors.white};
  background-image: url(${iconZoomSearch});
  background-size: contain;
  background-repeat: no-repeat;
  border: none;
  outline: none;
  cursor: pointer;
  margin-left: 8px;
`

import styled from 'styled-components'
import useFormatText from '../../hooks/useFormatText'

const SceneNumber = ({ index }: { index: number }) => {
  return (
    <Number className='scene-number'>
      {useFormatText('SCENE_UNIT')} {`0${index}`.slice(-2)}
    </Number>
  )
}

export default SceneNumber

export const Number = styled.div`
  width: auto;
  height: auto;
  display: inline-block;
  padding: 4px 5px;
  border-radius: 4px;
  background: rgba(34, 34, 34, 0.8);
  color: ${({ theme }) => theme.colors.white};
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  pointer-events: none;
  user-select: none;
  z-index: 10;
`

import styled, { css } from 'styled-components'
import DefaultButton from '../../components/DefaultButton'

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;

  ${({ theme }) =>
    theme.breakpoints.large(css`
      padding-bottom: 0px;
    `)}

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    `)};
`

export const CropWrapper = styled.div<{ aspect?: number }>`
  width: 100%;
  height: 50vh;
  max-height: 451px;
  position: relative;
  background-color: ${({ theme }) => theme.colors.gray[500]};

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      height: 45vh;
    `)};
`

export const FunctionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-top: 24px;

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      flex-wrap: wrap;
      gap: 8px;
    `)};
`

export const CropFunctionButton = styled.button<{
  icon: string
  disabled: boolean
}>`
  height: 40px;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  border: 1px solid ${({ theme }) => theme.colors.gray[100]};
  border-radius: 4px;
  padding: 8px 20px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.gray[800]};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */

  &::before {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    background-image: url('${({ icon }) => icon}');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      white-space: nowrap;
      padding: 8px 12px;
    `)};
`

export const LoadingWrapper = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
`

export const Container = styled.div`
  width: 100%;
  height: 100%;
`

export const CropInfo = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.gray[800]};
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-top: 24px;
  margin-bottom: 36px;
  white-space: pre-wrap;
`

export const CropButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding: 0 24px;

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      position: relative;
    `)}
`

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

export const RemoveBgButton = styled(DefaultButton)<{ icon: string }>`
  padding: 0;

  & > div {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
    border-radius: 24px;
    padding: 6px 12px;
    background-color: ${({ theme }) => theme.colors.brand.secondary[30]};
    color: ${({ theme }) => theme.colors.brand.secondary[500]};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 14px */
    cursor: pointer;

    &::before {
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      background-image: url('${({ icon }) => icon}');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50% 50%;
    }

    ${({ disabled }) =>
      disabled &&
      css`
        background-color: ${({ theme }) => theme.colors.gray[50]};
        color: ${({ theme }) => theme.colors.gray[300]};
        cursor: not-allowed;
      `};
  }
`

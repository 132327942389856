import React, { SetStateAction, forwardRef, useMemo } from 'react'
import useFormatText from '../../hooks/useFormatText'
import {
  Count,
  EmptyText,
  TextArea,
  TextAreaContainer,
  TextLengthInfo
} from './_stylesTextModal'
import { detectLanguage, getTextInfo } from './constants'
import { Fonts } from '../../types/fonts'

const MAX_LENGTH = 84

interface EditTextProps {
  text: string
  textLength: number
  handleChangeText: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
  isToggled: boolean
  setIsToggled: React.Dispatch<SetStateAction<boolean>>
  selectedFont: Fonts | null
}

const EditText = forwardRef<HTMLTextAreaElement, EditTextProps>(
  (
    {
      text,
      textLength,
      handleChangeText,
      isToggled,
      setIsToggled,
      selectedFont
    },
    inputRef
  ) => {
    const textLengthInfo = useMemo(() => {
      const textInfo = getTextInfo()
      const checkLang = detectLanguage(text)
      if (selectedFont && !selectedFont.lang?.includes(checkLang))
        return textInfo.fontValid

      if (text.length > 0 && text.length > textLength)
        return textInfo.lengthValid

      return textInfo.valid
    }, [text, selectedFont, textLength])

    return (
      <div style={{ height: 136 }}>
        <TextAreaContainer>
          <TextArea
            value={text}
            onChange={handleChangeText}
            maxLength={MAX_LENGTH}
            placeholder={useFormatText('TEXT_MODAL_PLACEHOLDER')}
            autoFocus
            disabled={isToggled}
            ref={inputRef}
            onBlur={() => text === ' ' && setIsToggled(true)}
          />
          {!isToggled && (
            <Count length={text.length} textLength={textLength}>
              {text.length} / {textLength}
            </Count>
          )}
          {isToggled && (
            <EmptyText>{useFormatText('TEXT_MODAL_EMPTY')}</EmptyText>
          )}
        </TextAreaContainer>
        {!isToggled && text && (
          <TextLengthInfo
            style={{
              background: textLengthInfo.background,
              color: textLengthInfo.color
            }}
          >
            {textLengthInfo.icon}
            {useFormatText(textLengthInfo.text)}
          </TextLengthInfo>
        )}
      </div>
    )
  }
)

export default EditText

import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { ModalProps } from '../../components/Modal'

import CropImage from '../CropFile/CropImage'
import useLoading from '../../hooks/useLoading'
import FileLoading from '../CropFile/FileLoading'
import { Wrapper, photoModalWrapperStyle } from './_stylesModal'
import PhotoModalTabs from './PhotoModalTabs'
import useBreakpoint from '../../hooks/useBreakpoint'
import MobileFilePreview from './MobileFilePreview'
import useFormatText from '../../hooks/useFormatText'
import EditModalLayout from './EditModalLayout'
import DesktopFilePreview from './DesktopFilePreview'
import { IconScenePhoto } from '../../assets/icons'

//TYPE //////////////////////////////////////////////

type SourceSizeType = {
  sourceWidth: number
  sourceHeight: number
}

type PhotoModalProps = ModalProps & {
  value?: string
  onClose: () => void
  onComplete?(dataUrl: string): void
  aspect?: number
  sourceSize: SourceSizeType
  innerHeight?: number
}

//TYPE FIN //////////////////////////////////////////////

const PhotoModal = (props: PhotoModalProps) => {
  const { onClose, visible, onComplete, aspect = 1, sourceSize } = props

  const { isBreakpoint } = useBreakpoint()

  const [currentStep, setCurrentStep] = useState(0)
  const [selectedImageUrl, setSelectedImageUrl] = useState('')

  const [selectedAssetInfo, setSelectedAssetInfo] = useState<any>(null)
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)

  // 크롭 로딩 중일 땐 모달 닫기 불가
  const closable = currentStep !== 2

  const {
    isLoading: previewLoading,
    renderLoading: previewLoad,
    startLoading: previewLoadStart,
    endLoading: previewLoadEnd
  } = useLoading()

  const handleClickPrev = () => {
    setCurrentStep((prev) => Math.max(0, prev - 1))
  }

  useEffect(() => {
    let timerId: NodeJS.Timeout
    if (!visible) {
      timerId = setTimeout(() => {
        setCurrentStep(0)
        setSelectedImageUrl('')
        setSelectedTabIndex(0)
      }, 500)
    }
    return () => {
      if (timerId) {
        clearInterval(timerId)
      }
    }
  }, [visible])

  //NOTE: PEXEL FIN

  return (
    <EditModalLayout
      visible={visible}
      closable={closable}
      title={useFormatText('IMAGE_INPUT_TITLE')}
      onClose={onClose}
      wrapperStyle={photoModalWrapperStyle}
    >
      <div
        style={{
          width: '100%',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1
        }}
      >
        {currentStep === 0 && !isBreakpoint('medium') && (
          <Wrapper>
            <DesktopFilePreview
              type='image'
              selectedUrl={selectedImageUrl}
              previewLoad={previewLoad}
              previewLoadEnd={previewLoadEnd}
              selectedAssetInfo={selectedAssetInfo}
              setCurrentStep={setCurrentStep}
              onClose={onClose}
              previewLoading={previewLoading}
              emptyIcon={<PreviewEmptyIcon />}
              emptyText={useFormatText('IMAGE_INPUT_PREVIEW_DEFAULT')}
            />
            <PhotoModalTabs
              visible={visible}
              selectedImageUrl={selectedImageUrl}
              setSelectedImageUrl={setSelectedImageUrl}
              previewLoadStart={previewLoadStart}
              previewLoading={previewLoading}
              setSelectedAssetInfo={setSelectedAssetInfo}
              selectedTabIndex={selectedTabIndex}
              setSelectedTabIndex={setSelectedTabIndex}
            />
          </Wrapper>
        )}
        {currentStep === 0 && isBreakpoint('medium') && (
          <div style={{ width: '100%', height: '100%', padding: '0 16px' }}>
            {!selectedImageUrl && (
              <PhotoModalTabs
                visible={visible}
                selectedImageUrl={selectedImageUrl}
                setSelectedImageUrl={setSelectedImageUrl}
                previewLoadStart={previewLoadStart}
                previewLoading={previewLoading}
                setSelectedAssetInfo={setSelectedAssetInfo}
                selectedTabIndex={selectedTabIndex}
                setSelectedTabIndex={setSelectedTabIndex}
              />
            )}
            {selectedImageUrl && (
              <MobileFilePreview
                type='image'
                selectedImageUrl={selectedImageUrl}
                setSelectedImageUrl={setSelectedImageUrl}
                selectedAssetInfo={selectedAssetInfo}
                previewLoad={previewLoad}
                previewLoadEnd={previewLoadEnd}
                handleLoadClick={() => setCurrentStep(1)}
              />
            )}
          </div>
        )}
        {currentStep === 1 && (
          <CropImage
            image={selectedImageUrl}
            aspect={aspect}
            restrictPosition
            onCrop={async (dataUrl) => {
              setCurrentStep(2)
              onComplete && onComplete(dataUrl)
            }}
            handleClickPrev={handleClickPrev}
            sourceSize={sourceSize}
          />
        )}
        {currentStep === 2 && <FileLoading />}
      </div>
    </EditModalLayout>
  )
}

export default PhotoModal

// STYLE ////////////////////////////////////////

export const ImageContainer = styled.div`
  position: relative;
  width: 100%; // NOTE: mobile issue
  flex-grow: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;
  padding: 0 8px;

  .empty-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .empty-text {
      color: ${({ theme }) => theme.colors.gray[500]};
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */
      margin-top: 12px;
      user-select: none;
    }
  }
`

export const PreviewEmptyIcon = styled(IconScenePhoto)`
  width: 48px;
  height: 48px;
  flex-shrink: 0;

  path,
  ellipse {
    stroke: ${({ theme }) => theme.colors.gray[300]};
  }
`

export const ImageWrapper = styled.div<{
  innerHeight?: number
}>`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 376px;
  max-height: 260px;
  margin-top: 16px;

  .selected-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center center;

    ${({ theme, innerHeight }) =>
      theme.breakpoints.medium(css`
        max-height: ${innerHeight && innerHeight > 667 ? '25vh' : '20vh'};
      `)}

    ${({ theme, innerHeight }) =>
      theme.breakpoints.small(css`
        min-height: ${innerHeight && innerHeight > 667 ? '25vh' : '20vh'};
      `)}
  }

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      height: auto;
      justify-content: center;
      padding: 0;
    `)}
`

export const PhotoLottieWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
`

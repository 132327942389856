import styled, { css } from 'styled-components'
import Button from '../../components/Button'
import theme from '../../styles/theme'

export const disabledCss = css`
  border: 1px solid #e0e0e0;
  background-color: #f0f0f0;
`

export const disabledTextColor = css`
  color: #c2c2c2;
`

export const dropdownDisabledCss = css`
  pointer-events: none;

  .dropdown {
    ${disabledCss};
  }
  .label > span {
    ${disabledTextColor};
  }
`

export const Container = styled.div<{ isToggled: boolean; isChecked: boolean }>`
  width: 100%;
  padding: 42px 24px 24px;

  .checkbox-wrapper {
    gap: 8px;
    color: ${theme.colors.gray[400]};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 16px;
    margin-bottom: 40px;
    user-select: none;

    ${({ isChecked }) =>
      isChecked &&
      css`
        .checkbox {
          border-color: #333;
          background-color: #333;
        }
      `}

    ${({ isToggled }) =>
      isToggled &&
      css`
        ${disabledTextColor};

        .checkbox {
          ${disabledCss};
        }
      `}
  }
`

export const Title = styled.div`
  color: ${theme.colors.gray[800]};
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 120%;
  margin-bottom: 66px;
`

export const Flex = styled.div`
  display: flex;
  align-items: center;
`

export const Subtitle = styled.div`
  color: ${theme.colors.gray[800]};
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
`

export const CloseButton = styled(Button)`
  position: absolute;
  top: 24px;
  right: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  padding: 0;

  path {
    fill: ${theme.colors.gray[300]};
  }
`

export const DropDownTitle = styled.span`
  color: ${theme.colors.gray[800]};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
`

export const TextAreaContainer = styled.div`
  position: relative;
  margin-top: 16px;
`

export const TextArea = styled.textarea<{ disabled: boolean }>`
  width: 100%;
  height: 82px;
  border-radius: 4px;
  border: 1px solid ${theme.colors.gray[100]};
  outline: none;
  color: ${theme.colors.gray[800]};
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  padding: 12px;
  resize: none;

  &::placeholder {
    color: ${theme.colors.gray[200]};
    font-weight: 400;
    line-height: 140%;
  }

  &:focus {
    border: 1px solid #4d4d4d;
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.06);
  }

  &:disabled {
    border-radius: 2px;
    ${disabledCss};
  }
`

export const Count = styled.span<{ length: number; textLength: number }>`
  position: absolute;
  right: 12px;
  bottom: 12px;
  display: inline-flex;
  align-items: center;
  color: #858585;
  font-size: 12px;
  font-weight: 500;
  line-height: 140%;

  ${({ length, textLength }) =>
    length &&
    length > textLength &&
    css`
      color: #ee2f2e;
    `};

  ${({ length, textLength }) =>
    length &&
    length <= textLength &&
    css`
      color: #1f76f9;
    `};
`

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #d9d9d9;
  margin-bottom: 24px;
`

export const ToggleInfo = styled.div`
  color: ${theme.colors.gray[600]};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 12px;
`

export const EmptyText = styled.div`
  position: absolute;
  top: 12px;
  left: 12px;
  color: #c2c2c2;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 40px;
`

export const TextLengthInfo = styled.div`
  display: inline-flex;
  padding: 6px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  margin-top: 8px;
`

export const MobileButtonContainer = styled.div`
  width: 100%;
  margin-top: auto;
`

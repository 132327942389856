import { Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'
import theme from '../../styles/theme'

interface ColorChipProps {
  color: string
  setColor?: Dispatch<SetStateAction<string>> | null
  chipStyle?: React.CSSProperties
}

const ColorChip = ({ color, setColor, chipStyle }: ColorChipProps) => {
  const isWhite = color === theme.colors.white
  const handleClick = () => {
    setColor && setColor(color)
  }

  return (
    <Chip
      color={color}
      onClick={handleClick}
      style={chipStyle}
      $isWhite={isWhite}
    />
  )
}

export default ColorChip

const Chip = styled.button<{ color: string; $isWhite: boolean }>`
  width: 48px;
  height: 48px;
  position: relative;
  display: inline-block;
  background: transparent;
  border-radius: 4px;
  background-color: ${({ color }) => color};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  border: 2px solid ${({ theme }) => theme.colors.gray[100]};
`

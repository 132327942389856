/* eslint-disable react-hooks/rules-of-hooks */
import Checkbox from '../../components/Checkbox'
import { ModalProps } from '../../components/Modal'
import useFormatText from '../../hooks/useFormatText'
import { useEffect, useRef, useState } from 'react'
import Toggle from '../../components/Toggle'
import {
  ButtonContainer,
  Container,
  Flex,
  Line,
  MobileButtonContainer,
  Subtitle,
  ToggleInfo
} from './_stylesTextModal'
import FontListDropdown from './FontListDropdown'
import EditText from './EditText'
import EditModalLayout from './EditModalLayout'
import MobileButtons from './MobileButtons'
import useBreakpoint from '../../hooks/useBreakpoint'
import DefaultButton from '../../components/DefaultButton'
import { textModalWrapperStyle } from './_stylesModal'
import { Fonts } from '../../types/fonts'

type TextModalProps = ModalProps & {
  value?: string
  textLength?: number
  onComplete?: (
    value: string,
    selectedFont: Fonts | null,
    isFontApplyGlobal: boolean
  ) => void
  defaultFont?: Fonts | null
}

const emptyValue = ' '

const TextModal = (props: TextModalProps) => {
  const {
    onClose,
    visible,
    value,
    onComplete,
    textLength = 8,
    defaultFont = null
  } = props
  const [text, setText] = useState('')
  const [prevText, setPrevText] = useState('')
  const [selectedFont, setSelectedFont] = useState<Fonts | null>(defaultFont)

  const [isToggled, setIsToggled] = useState(false)
  const [isFontApplyGlobal, setIsFontApplyGlobal] = useState(false)
  const inputRef = useRef<HTMLTextAreaElement>(null)

  const { isBreakpoint } = useBreakpoint()

  const handleSelectFont = (font: Fonts | null) => {
    if (font?._id === selectedFont?._id) return
    setSelectedFont(font)
    if (isFontApplyGlobal) {
      setIsFontApplyGlobal(false)
    }
  }

  const handleChangeText = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.currentTarget.value
    setText(newValue)
    setPrevText(newValue)
  }

  const handleToggleClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked
    setIsToggled(checked)

    if (checked) {
      setText(emptyValue)
      return
    }

    setText(prevText !== emptyValue ? prevText : '')

    setTimeout(() => {
      inputRef.current && inputRef.current.focus()
    }, 0)
  }

  const handleComplete = () => {
    onComplete && onComplete(text, selectedFont, isFontApplyGlobal)
  }

  useEffect(() => {
    if (!visible) return
    setSelectedFont(defaultFont)

    setText(value || '')
    setPrevText(value || '')
    setIsToggled(value === emptyValue)

    if (value === emptyValue) return
    setTimeout(() => {
      if (!inputRef.current) return
      inputRef.current.select()
    }, 0)
  }, [value, defaultFont, visible])

  return (
    <EditModalLayout
      visible={visible}
      onClose={onClose}
      title={useFormatText('TEXT_INPUT_TITLE')}
      wrapperStyle={textModalWrapperStyle}
    >
      <Container isToggled={isToggled} isChecked={isFontApplyGlobal}>
        <Subtitle>{useFormatText('TEXT_MODAL_SUBTITLE_SELECT_FONT')}</Subtitle>
        <Flex style={{ gap: 12, marginTop: 16 }}>
          <FontListDropdown
            selectedFont={selectedFont}
            handleSelectFont={handleSelectFont}
            isToggled={isToggled}
          />
        </Flex>
        <Checkbox
          checked={isFontApplyGlobal}
          onChange={(checked) => setIsFontApplyGlobal(checked)}
          label={useFormatText('TEXT_MODAL_APPLY_ALL_TEXT')}
          showDefaultCheckIcon
        />
        <Subtitle>{useFormatText('TEXT_MODAL_SUBTITLE_EDIT_TEXT')}</Subtitle>
        <EditText
          text={text}
          textLength={textLength}
          handleChangeText={handleChangeText}
          isToggled={isToggled}
          setIsToggled={setIsToggled}
          ref={inputRef}
          selectedFont={selectedFont}
        />
        <Line />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Subtitle>
              {useFormatText('TEXT_MODAL_SUBTITLE_BLANK_TEXT')}
            </Subtitle>
            <ToggleInfo>
              {useFormatText('TEXT_MODAL_SUBTITLE_BLANK_TEXT_INFO')}
            </ToggleInfo>
          </div>
          <Toggle toggleValue={isToggled} onToggle={handleToggleClick} />
        </div>

        {!isBreakpoint('medium') && (
          <ButtonContainer>
            <DefaultButton category='secondaryMonochrome' onClick={onClose}>
              {useFormatText('TEXT_MODAL_CANCEL_BUTTON')}
            </DefaultButton>
            <DefaultButton
              category='secondaryMulticolored'
              onClick={handleComplete}
            >
              {useFormatText('TEXT_MODAL_DONE_BUTTON')}
            </DefaultButton>
          </ButtonContainer>
        )}
      </Container>
      {isBreakpoint('medium') && (
        <MobileButtonContainer>
          <MobileButtons
            onClickCancel={onClose}
            onClickOk={handleComplete}
            cancelText={useFormatText('TEXT_MODAL_CANCEL_BUTTON')}
            okText={useFormatText('TEXT_MODAL_DONE_BUTTON')}
          />
        </MobileButtonContainer>
      )}
    </EditModalLayout>
  )
}

export default TextModal

import styled, { css } from 'styled-components'
import { useIntl } from 'react-intl'

import { IconArrowLeft, IconReset } from '../../assets/icons'
import Tabs from '../Tabs'
import DefaultButton from '../DefaultButton'
import useBreakpoint from '../../hooks/useBreakpoint'

interface PanelTitleProps {
  title: string
  handleReset?: (() => void) | null
  handleDrawerClose: () => void
  children: JSX.Element
  wrapperStyle?: React.CSSProperties
}

const PanelTitle = ({
  title,
  handleReset,
  handleDrawerClose,
  children,
  wrapperStyle
}: PanelTitleProps) => {
  const intl = useIntl()
  const formatTitle = intl.formatMessage({ id: title })
  const { isMobile } = useBreakpoint()

  return (
    <Wrapper isMobile={isMobile} style={wrapperStyle}>
      {!isMobile && <Tabs tabs={[{ title: formatTitle, children }]} />}
      {isMobile && (
        <MobileTitle>
          <IconArrowLeft onClick={handleDrawerClose} className='backIcon' />
          <span>{formatTitle}</span>
        </MobileTitle>
      )}
      {handleReset && (
        <ResetButton
          isMobile={isMobile}
          category='compactTransparent'
          onClick={() => handleReset && handleReset()}
        >
          <Reset width={18} height={18} />
          {intl.formatMessage({ id: 'SIDE_NAV_BGM_CONTENT_RESET' })}
        </ResetButton>
      )}
      {isMobile && children}
    </Wrapper>
  )
}

export default PanelTitle

const Wrapper = styled.div<{ isMobile: boolean }>`
  width: 100%;
  height: 100%;
  user-select: none;

  ${({ isMobile }) =>
    isMobile &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .backIcon {
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translateY(-50%);

        &:hover {
          cursor: pointer;
        }
      }
    `};
`

const MobileTitle = styled.div`
  position: relative;
  width: 100%;
  padding: 14px 0px;
  color: ${({ theme }) => theme.colors.text['#222']};
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
`

const ResetButton = styled(DefaultButton)<{ isMobile: boolean }>`
  position: absolute;
  top: 22px;
  right: 24px;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 6px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.text.link};
  border-color: ${({ theme }) => theme.colors.text['#888']};
  font-weight: 700;
  line-height: 100%;

  ${({ isMobile }) =>
    isMobile &&
    css`
      top: 6px;
      right: 11px;
      gap: 5px;
      font-weight: 500;
    `};
`

const Reset = styled(IconReset)`
  width: 16px;
  height: 16px;
  margin-right: 4px;
`

import { ArrowRight, ToggleButton } from './_stylesSidebar'

interface SidebarToggleProps {
  open: boolean
  handleToggleClick: () => void
}

const SidebarToggleButton = ({
  open,
  handleToggleClick
}: SidebarToggleProps) => {
  return (
    <ToggleButton
      onClick={handleToggleClick}
      open={open}
      className='sidebar-toggle-button'
    >
      <div style={{ position: 'relative' }}>
        <ArrowRight open={open} />
      </div>
    </ToggleButton>
  )
}

export default SidebarToggleButton

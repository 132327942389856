import React, { useState, useEffect, useCallback, useMemo } from 'react'
import Cropper from 'react-easy-crop'
import type { Area } from 'react-easy-crop/types'

import iconReset from '../../assets/icons/icon_reset_rotate.svg'
import cropImage from '../../utils/libs/cropImage'
import flipImage from '../../utils/libs/flipImage'
import { rotateImage } from '../../utils/libs/rotateImage'
import useLoading from '../../hooks/useLoading'
import ZoomButtons from './ZoomButtons'
import {
  CropButtonWrapper,
  CropWrapper,
  FunctionWrapper,
  CropFunctionButton,
  Wrapper,
  Container,
  CropInfo,
  ButtonContainer,
  RemoveBgButton
} from './_stylesCropImage'
import { getFunctions } from './constants'
import { useIntl } from 'react-intl'
import { IconReset } from '../../assets/icons'
import styled, { css } from 'styled-components'
import DefaultButton from '../../components/DefaultButton'
import { useRemoveBgMutation } from './_queries'
import theme from '../../styles/theme'
import LottieIcon from '../../components/Lottie'
import { threeDotsLoading } from '../../assets/lottie'
import useFormatText from '../../hooks/useFormatText'
import useBreakpoint from '../../hooks/useBreakpoint'
import MobileButtons from '../EditModals/MobileButtons'
import RemoveBgTooltip from './RemoveBgTooltip'
import aiCharacter from '../../assets/images/ai_character.png'
import aiCharacterGray from '../../assets/images/ai_character_gray.png'
import { lambdaResizeImage } from '../../utils/libs/lambdaResizeImage'

type SourceSizeType = {
  sourceWidth: number
  sourceHeight: number
}

type CropImageProps = {
  image?: string
  aspect?: number
  onCrop?(src: string): void
  restrictPosition?: boolean
  sourceSize: SourceSizeType
  handleClickPrev: () => void
}

type ZoomPoint = {
  x: number
  y: number
}

type CroppedAreaPixelsPoint = {
  width: number
  height: number
  x: number
  y: number
}

const CropImage: React.FC<CropImageProps> = ({
  image: imageSrc = '',
  onCrop: handleCrop = () => {},
  sourceSize,
  handleClickPrev
}) => {
  const imageUrl = useMemo(() => {
    return lambdaResizeImage(imageSrc, 1080)
  }, [imageSrc])

  const intl = useIntl()
  const { isBreakpoint } = useBreakpoint()
  const [image, setImage] = useState(imageUrl)
  const {
    data: bgUrls,
    isLoading: isRemoveBgLoading,
    isSuccess: isCanNotRemoveBg,
    mutate: removeBgMutate,
    handleSuccessState: handleRemoveBgState
  } = useRemoveBgMutation(imageUrl, setImage)
  const originalImageUrl = bgUrls?.originalImageUrl
  const removeBgImageUrl = bgUrls?.removedImageUrl
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [isCropping, setIsCropping] = useState(false)
  const { startLoading, endLoading, renderLoading, isLoading } = useLoading()
  const disabled = !image || isCropping || isLoading || isRemoveBgLoading

  const [croppedAreaPixels, setCroppedAreaPixels] =
    useState<CroppedAreaPixelsPoint | null>(null)
  const [aspect, setAspect] = useState(0)

  const minZoom = 0.1

  const tooltipContents = useFormatText(
    isCanNotRemoveBg
      ? 'IMAGE_BACKGROUND_REMOVAL_AFTER_DONE'
      : 'IMAGE_BACKGROUND_REMOVAL_INFO'
  )
  const resetText = useFormatText('IMAGE_BACKGROUND_REMOVAL_RESET')
  const IMAGE_BACKGROUND_REMOVAL_LOADING = useFormatText(
    'IMAGE_BACKGROUND_REMOVAL_LOADING'
  )

  const handleCropComplete = (_croppedArea: Area, _croppedAreaPixels: Area) => {
    setCroppedAreaPixels(_croppedAreaPixels)
  }

  const handleComplete = useCallback(async () => {
    setIsCropping(true)
    try {
      const croppedImage = await cropImage(image, croppedAreaPixels, 0)
      handleCrop(croppedImage)
    } catch (e) {
      console.error(e)
    }
    setTimeout(() => {
      setIsCropping(false)
    }, 255)
  }, [croppedAreaPixels, handleCrop, image])

  const handleReset = () => {
    setCrop({ x: 0, y: 0 })
    setImage(imageUrl)
    setZoom(1)
    handleRemoveBgState(false)
  }

  const handleCropChange = (item: ZoomPoint) => {
    const { x, y } = item
    setCrop({ x, y })
  }

  const handleZoomChange = (item: number) => {
    if (!minZoom) return
    if (item === zoom || (1 > item && zoom < minZoom)) return

    const plusZoom = Math.min(item, 3)
    const minusZoom = Math.max(item, minZoom)
    setZoom(item > zoom ? plusZoom : minusZoom)
  }

  const handleFlipX = async () => {
    const result = await flipImage(image, 'flipX')
    setImage(result)
    endLoading()
  }

  const handleFlipY = async () => {
    const result = await flipImage(image, 'flipY')
    setImage(result)
    endLoading()
  }

  const handleRotate = async () => {
    const result = await rotateImage(image, 90)
    setImage(result)
    endLoading()
  }

  const functions = getFunctions(handleFlipX, handleFlipY, handleRotate)

  const handleBgRemoverBtnClick = () => {
    if (isRemoveBgLoading) return
    if (!removeBgImageUrl) {
      removeBgMutate()
    } else {
      setImage(removeBgImageUrl)
      handleRemoveBgState(true)
    }
  }

  const handleResetBgRemoverBtnClick = async () => {
    if (!originalImageUrl) return
    setImage(originalImageUrl)
    handleRemoveBgState(false)
  }

  useEffect(() => {
    if (!sourceSize) return
    const { sourceWidth, sourceHeight } = sourceSize
    setAspect(sourceWidth / sourceHeight)
  }, [sourceSize])

  return (
    <Wrapper>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        {!isLoading && <LoadingWrapper>{renderLoading()}</LoadingWrapper>}
        <CropWrapper>
          <Container>
            {isRemoveBgLoading ? (
              <RemoveBgLoadingWrapper>
                <LottieIcon
                  width={100}
                  height={100}
                  options={{ animationData: threeDotsLoading }}
                />
                <p>{IMAGE_BACKGROUND_REMOVAL_LOADING}</p>
              </RemoveBgLoadingWrapper>
            ) : (
              <>
                <Cropper
                  {...{ image, crop, zoom, aspect }}
                  minZoom={minZoom || 1}
                  onCropChange={handleCropChange}
                  onZoomChange={handleZoomChange}
                  onCropComplete={handleCropComplete}
                  restrictPosition={false}
                />
                <ResetModifiedButton icon={iconReset} onClick={handleReset} />
                <ZoomButtons
                  zoom={zoom}
                  onClickZoomIn={() =>
                    setZoom((prev) => Math.min(prev + 0.2, 3))
                  }
                  onClickZoomOut={() =>
                    minZoom && setZoom((prev) => Math.max(prev - 0.2, minZoom))
                  }
                />
              </>
            )}
          </Container>
        </CropWrapper>
        <BottomContainer>
          <FunctionWrapper>
            {functions.map(({ icon, text, onClick }) => {
              return (
                <CropFunctionButton
                  key={text}
                  disabled={disabled}
                  icon={icon}
                  onClick={() => {
                    startLoading()
                    onClick()
                  }}
                >
                  {intl.formatMessage({ id: text })}
                </CropFunctionButton>
              )
            })}
          </FunctionWrapper>
          <CropInfo>
            {intl.formatMessage({ id: 'IMAGE_MODAL_CROP_IMAGE_INFO' })}
          </CropInfo>
        </BottomContainer>
        <CropButtonWrapper>
          <RemoveBgButtonWrapper>
            <RemoveBgTooltip
              isBreakpoint={isBreakpoint}
              tooltipContents={tooltipContents}
            >
              <RemoveBgButton
                category='compactTransparent'
                onClick={handleBgRemoverBtnClick}
                disabled={isCanNotRemoveBg}
                icon={isCanNotRemoveBg ? aiCharacterGray : aiCharacter}
              >
                {useFormatText('AI_REMOVE_BACKGROUND')}
              </RemoveBgButton>
            </RemoveBgTooltip>
            {isCanNotRemoveBg && (
              <DefaultButton
                category='compactTransparent'
                className='reset-button'
                onClick={handleResetBgRemoverBtnClick}
              >
                <Reset width={16} height={16} />
                {resetText}
              </DefaultButton>
            )}
          </RemoveBgButtonWrapper>
          {!isBreakpoint('medium') && (
            <ButtonContainer>
              <DefaultButton
                category='secondaryMonochrome'
                style={{ margin: 0 }}
                onClick={handleClickPrev}
              >
                {intl.formatMessage({ id: 'PREVIOUS' })}
              </DefaultButton>
              <DefaultButton
                category='secondaryMulticolored'
                style={{ margin: 0 }}
                onClick={() => {
                  startLoading()
                  handleComplete()
                }}
                disabled={disabled}
              >
                {intl.formatMessage({
                  id: disabled
                    ? 'IMAGE_INPUT_DONE_BUTTON_PROCESS'
                    : 'IMAGE_INPUT_EDIT_DONE'
                })}
              </DefaultButton>
            </ButtonContainer>
          )}
        </CropButtonWrapper>
      </div>
      {isBreakpoint('medium') && (
        <MobileButtons
          onClickOk={() => {
            startLoading()
            handleComplete()
          }}
          onClickCancel={handleClickPrev}
          okButtonDisabled={disabled}
          cancelText={intl.formatMessage({ id: 'PREVIOUS' })}
          okText={intl.formatMessage({ id: 'VIDEO_INPUT_EDIT_DONE' })}
        />
      )}
    </Wrapper>
  )
}

export default CropImage

const RemoveBgButtonWrapper = styled.div`
  display: flex;
  gap: 12px;

  .reset-button {
    border-radius: 24px;
    border: 1px solid ${({ theme }) => theme.colors.gray[100]};

    & > div {
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 9px 12px;
    }
  }
`

const RemoveBgLoadingWrapper = styled.div`
  z-index: 1;
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #aaaaaa;

  p {
    color: ${theme.colors.text['#666']};
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
  }
`

const LoadingWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
`

const Reset = styled(IconReset)`
  width: 18px;
  height: 18px;
`

const BottomContainer = styled.div`
  width: 100%;
  padding: 0 24px;

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      padding: 0 16px;
    `)};
`
const ResetModifiedButton = styled.button`
  position: absolute;
  left: 20px;
  bottom: 20px;
  width: 30px;
  height: 30px;
  background: ${(props: { icon: string }) =>
    `url(${props.icon}) no-repeat center`};
  border: none;
  cursor: pointer;
`

import styled from 'styled-components'
import { UploadContainerProps } from './types'
import theme from '../../styles/theme'
import useFormatText from '../../hooks/useFormatText'

const UploadContainer = ({
  subtitle,
  imageValue,
  videoValue
}: UploadContainerProps) => {
  return (
    <Wrapper>
      <span className='subtitle'>{subtitle}</span>
      <div className='upload-container'>
        <span className='info'>
          {useFormatText('COMPONENT_IMAGE')} : {imageValue}
        </span>
      </div>
      <div className='upload-container'>
        <span className='info'>
          {useFormatText('COMPONENT_VIDEO')} : {videoValue}
        </span>
      </div>
    </Wrapper>
  )
}

export default UploadContainer

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .subtitle {
    color: ${theme.colors.gray[800]};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    margin-top: 16px;
  }

  .upload-container {
    display: flex;
    align-items: center;
    gap: 8px;

    .info {
      color: ${theme.colors.gray[500]};
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }
  }
`

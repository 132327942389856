import styled, { CSSProperties } from 'styled-components'
import { IconAlertCircle } from '../../assets/icons'

interface EmptyTextProps {
  text: string
  style?: CSSProperties
}

const EmptyText = ({ text, style }: EmptyTextProps) => {
  return (
    <Wrapper style={style}>
      <IconAlertCircle />
      <Text>{text}</Text>
    </Wrapper>
  )
}

export default EmptyText

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;

  svg {
    opacity: 0.5;
  }
`

export const Text = styled.span`
  color: ${({ theme }) => theme.colors.gray[400]};
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`

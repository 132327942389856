import styled from 'styled-components'

import { iconModalClose, IconSignupModalVideo } from '../../assets/icons'
import theme from '../../styles/theme'

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 405px;
  height: 354px;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background-color: ${theme.colors.white};
  box-shadow: 0px 0px 8px 2px rgba(124, 124, 124, 0.08);
`

export const PinkContainer = styled.div`
  position: relative;
  width: 100%;
  height: 146px;
  background: linear-gradient(
    0deg,
    #ffdddd 5.77%,
    rgba(255, 221, 221, 0) 97.76%
  );
  border-radius: 12px 12px 0px 0px;
`

export const IconVideo = styled(IconSignupModalVideo)`
  position: absolute;
  top: 17px;
  left: 8px;
  width: 139px;
  height: 123px;
`

export const TitleContainer = styled.div`
  position: absolute;
  top: 36px;
  right: 59px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  white-space: pre-wrap;
`

export const IsNotMember = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: ${theme.colors.gray[800]};
`

export const Title1 = styled.span`
  font-size: 22px;
  font-weight: 600;
  line-height: 31px;
  color: ${theme.colors.gray[800]};
`

export const Title2 = styled.span`
  font-size: 22px;
  font-weight: 700;
  line-height: 31px;
  color: ${theme.colors.primary};
`

export const CloseButton = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 24px;
  height: 24px;
  background-image: url(${iconModalClose});
  cursor: pointer;
  z-index: 1;
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  padding-top: 24px;
`

export const Content = styled.div`
  color: ${theme.colors.gray[800]};
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  text-align: center;
`
export const ButtonContainer = styled.div`
  display: flex;
  gap: 12px;
`

export const FooterContent = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 30px;
`

export const FooterText = styled.span`
  color: ${theme.colors.gray[800]};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`

export const LoginLink = styled.span`
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  color: ${theme.colors.primary};
`

import {
  IconScenePhoto,
  IconSceneVideo,
  IconSceneText
} from '../../assets/icons'
import { Source } from '../../types/template'

export const screenSourceCounts = [
  {
    icon: <IconScenePhoto />,
    type: 'I'
  },
  {
    icon: <IconSceneVideo />,
    type: 'V'
  },
  {
    icon: <IconSceneText />,
    type: 'T'
  }
]

export const getCount = (
  list: (Source | undefined)[] | undefined,
  type: string
) => {
  return list && list.filter((item) => item && item.sourceType === type).length
}

export const getUserSourceCount = (
  list: { value: string; sourceType?: string }[] | undefined,
  type: string
) => {
  return (
    list && list.filter((item) => item.value && item.sourceType === type).length
  )
}

import { ChangeEvent } from 'react'
import styled from 'styled-components'

interface ToggleProps {
  toggleValue: boolean
  onToggle: (e: ChangeEvent<HTMLInputElement>) => void
}

const Toggle = ({ toggleValue, onToggle }: ToggleProps) => {
  return (
    <ToggleContainer>
      <ToggleInput type='checkbox' checked={toggleValue} onChange={onToggle} />
      <ToggleSlider />
    </ToggleContainer>
  )
}

export default Toggle

const ToggleContainer = styled.label`
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;
`

const ToggleInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + span {
    background-color: #333333;
  }

  &:focus + span {
    box-shadow: 0 0 1px #333333;
  }

  &:checked + span:before {
    transform: translateX(24px);
  }
`

const ToggleSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 50px;
  height: 26px;
  background-color: ${({ theme }) => theme.colors.gray[100]};
  transition: 0.2s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 3px;
    bottom: 3px;
    background-color: ${({ theme }) => theme.colors.white};
    transition: 0.2s;
    border-radius: 50%;
  }
`

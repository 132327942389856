import { Fonts } from '../../types/fonts'
import FontItem from './FontItem'

interface BookMarkFontList {
  selectedFont: Fonts | null
  handleSelectFont: (font: Fonts | null) => void
  bookmarkedFonts: Fonts[]
  handleClickBookMark: (value: Fonts | null) => void
}

const BookMarkFontList = ({
  selectedFont,
  handleSelectFont,
  bookmarkedFonts,
  handleClickBookMark
}: BookMarkFontList) => {
  const isKr = localStorage.getItem('locale')?.includes('ko') ?? false
  return (
    <div style={{ maxHeight: 240, overflowY: 'auto' }}>
      {bookmarkedFonts.map((item) => {
        return (
          <FontItem
            key={item?._id || 0}
            isSelected={selectedFont?._id === item?._id}
            isBookMark={
              !!item && !!bookmarkedFonts.find((font) => font._id === item._id)
            }
            handleSelectedFont={() => handleSelectFont(item)}
            handleClickBookMark={() => handleClickBookMark(item)}
            showBookMark={!!item}
          >
            {
              <img
                src={
                  process.env.REACT_APP_FRONT_FILE_API_URL +
                  item.nameAssets[isKr ? 'ko' : 'en']
                }
                style={{ objectFit: 'contain' }}
              />
            }
          </FontItem>
        )
      })}
    </div>
  )
}

export default BookMarkFontList

import { ModalBackground } from '../../components/DefaultModal/styles'
import useFormatText from '../../hooks/useFormatText'

import {
  ButtonContainer,
  CloseButton,
  Content,
  ContentContainer,
  FooterContent,
  FooterText,
  IconVideo,
  IsNotMember,
  LoginLink,
  PinkContainer,
  Title1,
  Title2,
  TitleContainer,
  Wrapper
} from './styles'
import DefaultButton from '../../components/DefaultButton'
import { useProjectContext } from '../../hooks/useProject'
import {
  redirectVplateLoginPage,
  redirectVplateSignupPage
} from '../../utils/vplate'

interface SignupModalProps {
  projectId: string
  handleCloseBtnClick: () => void
  isFromIframe: boolean
}

const SignupModal = ({ handleCloseBtnClick }: SignupModalProps) => {
  const { project } = useProjectContext()
  const renderLimit = project?.renderLimit || 3

  const handleConfirmBtnClick = () => {
    handleCloseBtnClick()
    redirectVplateSignupPage()
  }

  const handleLoginBtnClick = () => {
    redirectVplateLoginPage()
  }

  return (
    <ModalBackground>
      <Wrapper>
        <CloseButton onClick={handleCloseBtnClick} />
        <PinkContainer>
          <IconVideo />
          <TitleContainer>
            <IsNotMember>{useFormatText('GPT_CONTENTS_TITLE_A')}</IsNotMember>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Title1>{useFormatText('GPT_CONTENTS_TITLE_B')}</Title1>
              <Title2>{useFormatText('GPT_CONTENTS_TITLE_C')}</Title2>
            </div>
          </TitleContainer>
        </PinkContainer>
        <ContentContainer>
          <Content>
            <div>
              {useFormatText('GPT_INTRODUCTION_DESCRIPTION1', {
                n: renderLimit
              })}
            </div>
            <div>{useFormatText('GPT_INTRODUCTION_DESCRIPTION2')}</div>
          </Content>
          <ButtonContainer>
            <DefaultButton
              category='secondaryMonochrome'
              onClick={handleCloseBtnClick}
            >
              {useFormatText('GPT_INTRODUCTION_BACK')}
            </DefaultButton>
            <DefaultButton category='primary' onClick={handleConfirmBtnClick}>
              {useFormatText('GPT_INTRODUCTION_CTA')}
            </DefaultButton>
          </ButtonContainer>
        </ContentContainer>
        <FooterContent>
          <FooterText>{useFormatText('GPT_INTRODUCTION_FORUSER')}</FooterText>
          <LoginLink onClick={handleLoginBtnClick} as={'a'}>
            {useFormatText('GPT_INTRODUCTION_LOGIN')}
            {` ＞`}
          </LoginLink>
        </FooterContent>
      </Wrapper>
    </ModalBackground>
  )
}

export default SignupModal

import styled, { css } from 'styled-components'

import theme from '../../styles/theme'

const SidebarMenu = ({
  span,
  isActive,
  title,
  icons,
  onClick
}: {
  span: number
  isActive: boolean
  title: string
  icons: Icons
  touch?: boolean
  onClick(): void
}) => {
  return (
    <Col span={span}>
      <MenuButton type='button' isActive={isActive} onClick={onClick}>
        <Icon icons={icons} isActive={isActive} />
        {title}
      </MenuButton>
    </Col>
  )
}

export default SidebarMenu

const Col = styled.div<{ span: number }>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme, span }) =>
    theme.breakpoints.medium(css`
      width: ${Math.floor(100 / span)}%;
    `)}
`

// const Wrapper = styled.div<{ isActive: boolean; icons: Icons }>`
//   position: relative;
//   width: fit-content;
//   height: fit-content;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   background: ${({ isActive }) =>
//     isActive ? theme.colors.background.active : theme.colors.white};
//   z-index: 240;
//   transition: all 0.15s;
//   cursor: pointer;
//   padding: 9px;

//   div {
//     position: relative;
//     color: ${({ isActive }) =>
//       isActive ? theme.colors.primary : theme.colors.text['#666']};
//     text-align: center;
//     line-height: 100%;
//     font-size: 10px;
//     margin-top: 3px;
//   }
//   ${({ theme }) =>
//     theme.breakpoints.medium(css`
//       min-height: 65px;
//     `)}
// `
type Icons = {
  default: string
  hover?: string
  active?: string
  disabled?: string
}

const Icon = styled.div<{ icons: Icons; isActive: boolean }>`
  width: 24px;
  height: 24px;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: ${({ isActive, icons }) => css`
    url('${isActive ? icons.active : icons.default}');
  `};
`

export const MenuButton = styled.button<{ isActive: boolean }>`
  position: relative;
  width: 64px;
  height: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 0;
  background: transparent;
  border: none;
  background: ${({ isActive }) =>
    isActive ? theme.colors.background.active : theme.colors.white};
  z-index: 240;
  transition: all 0.15s;
  color: ${({ isActive }) =>
    isActive ? theme.colors.brand.primary[400] : theme.colors.gray[700]};
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 10px */
  cursor: pointer;
`

import styled from 'styled-components'
import theme from '../../styles/theme'
import DefaultButton from '../../components/DefaultButton'

interface MobileButtonsProps {
  onClickCancel?: () => void
  onClickOk?: () => void
  cancelText: string
  okText: string
  okButtonDisabled?: boolean
}

const MobileButtons = ({
  onClickCancel = () => {},
  onClickOk = () => {},
  cancelText,
  okText,
  okButtonDisabled = false
}: MobileButtonsProps) => {
  return (
    <ButtonWrapper>
      <DefaultButton
        category='secondaryMonochrome'
        onClick={onClickCancel}
        className='cancel-button'
      >
        {cancelText}
      </DefaultButton>
      <DefaultButton
        category='primary'
        onClick={onClickOk}
        className='ok-button'
        disabled={okButtonDisabled}
      >
        {okText}
      </DefaultButton>
    </ButtonWrapper>
  )
}

export default MobileButtons

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0 24px 24px;

  button {
    display: flex;
    width: 100%;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    gap: 6px;
  }

  .ok-button {
    background-color: ${theme.colors.white};
    color: ${theme.colors.primary};
    border: 1px solid ${theme.colors.primary};
  }
`
